import { RootState } from "../reducers/rootReducer";
import { createSelector } from "@reduxjs/toolkit";
import { toRecord } from "../utils/menu";
import { renderTableCollectionText } from "../utils/menu";
import {
    Restaurant,
    RestaurantGroup,
    UserRoleAttachment,
    UserProfile,
    RestaurantGroupWithUsers,
    RolePermissions,
} from "../generated-interfaces/graphql";
import { getUserAccessLevel } from "../utils/restaurants";

export const usersSelector = (state: RootState) => state.userManagement.users;
export const restaurantsSelector = (state: RootState) =>
    state.userManagement.restaurants;
export const restaurantGroupsSelector = (state: RootState) =>
    state.userManagement.restaurantGroups;
export const restaurantGroupsWithUsersSelector = (state: RootState) =>
    state.userManagement.restaurantGroupsWithUsers;
export const rolesSelector = (state: RootState) => state.userManagement.roles;

export type TreeRestaurant = Restaurant & {
    restaurantGroupsMap: Record<string, RestaurantGroup>;
};
export type TreeRestaurantGroup = RestaurantGroup & {
    attachedUsers: UserRoleAttachment[];
};
export type TreeUser = UserProfile & {
    restaurantGroupsMap: Record<string, RestaurantGroupWithUsers>;
    restaurantGroupNames: string;
};

export const restaurantTreeSelector = createSelector(
    restaurantsSelector,
    restaurantGroupsSelector,
    (restaurants, restaurantGroups) => {
        return Object.values(restaurants).reduce((acc, restaurant) => {
            const restaurantGroupsMap = toRecord(
                Object.values(restaurantGroups).filter((restaurantGroup) => {
                    return (
                        restaurantGroup.restaurants.findIndex(
                            (r) =>
                                r.restaurantCode === restaurant.restaurantCode
                        ) > -1
                    );
                })
            );
            acc[restaurant.id] = {
                ...restaurant,
                restaurantGroupsMap,
            };
            return acc;
        }, {} as Record<string, TreeRestaurant>);
    }
);

export const restaurantGroupTableSelector = createSelector(
    restaurantGroupsSelector,
    restaurantGroupsWithUsersSelector,
    (restaurantGroups, restaurantGroupsWithUsers) => {
        return Object.values(restaurantGroups).reduce(
            (acc, restaurantGroup) => {
                acc[restaurantGroup.id] = {
                    ...restaurantGroup,
                    attachedUsers:
                        restaurantGroupsWithUsers[restaurantGroup.groupName]
                            .attachedUsers,
                };
                return acc;
            },
            {} as Record<string, TreeRestaurantGroup>
        );
    }
);

export const userTableSelector = createSelector(
    usersSelector,
    restaurantGroupsWithUsersSelector,
    (users, restaurantGroupsWithUsers) => {
        return Object.values(users).reduce((acc, user) => {
            const groupWithUser = toRecord(
                Object.values(restaurantGroupsWithUsers)
                    .filter((group) => {
                        return (
                            group.attachedUsers.findIndex((attachedUser) => {
                                return attachedUser.user.id === user.id;
                            }) > -1
                        );
                    })
                    .map((group) => ({
                        ...group,
                        id: group.groupName,
                    }))
            );
            acc[user.id] = {
                ...user,
                restaurantGroupsMap: groupWithUser,
                restaurantGroupNames: renderTableCollectionText(
                    groupWithUser,
                    3,
                    "groupName"
                ),
            };
            return acc;
        }, {} as Record<string, TreeUser>);
    }
);
