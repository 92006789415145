import { PayloadAction } from "@reduxjs/toolkit";
import { SagaIterator } from "redux-saga";
import { call, CallEffect, select } from "redux-saga/effects";
import {
    Category,
    MenuOverrideInput,
    ModifierGroup,
    SortOrderInput,
    TimePeriod,
} from "../generated-interfaces/graphql";
import { selectedRestaurantCodeSelector } from "../selectors/restaurant";
import { currentUserNameSelector } from "../selectors/user";
import { CategoryType, MenuItemType, ModifierGroupType } from "../types/menu";
import {
    addNameToItemUsingId,
    omitKeysFromObject,
} from "../utils/helper-functions";
import {
    categoriesSelector,
    generatePropertyList,
    menuItemsSelector,
    modifierGroupWithMenuItemsSelector,
    timePeriodsSelector,
    transformVoiceProperties,
} from "../utils/menu";
import { DeleteItemType } from "../utils/types";
import {
    createPRPIntCall,
    deletePRPIntCall,
    getPRPIntCall,
    updatePRPIntCall,
} from "../utils/webserviceAPI";

function getMeniItemPayload(props: {
    payload: Omit<MenuItemType, "restaurantCode" | "id">;
    modifyGroupMap: Record<string, ModifierGroup>;
    categoriesMap: Record<string, Category>;
    restaurantCode: string;
    updatedUsername: string;
}) {
    const {
        payload,
        modifyGroupMap,
        categoriesMap,
        restaurantCode,
        updatedUsername,
    } = props;
    const available = payload.available === null ? true : payload.available;
    const propsToOmit = ["id"];

    const updatedPayload = omitKeysFromObject(payload, propsToOmit);

    const {
        modifierGroupIds,
        parentCategoryIds,
        modifierGroupsSortOrder,
        voiceProperties,
        ...restProps
    } = updatedPayload;

    const menuPayload = {
        ...restProps,
        available,
        modifierGroupIds: addNameToItemUsingId(
            modifierGroupIds,
            modifyGroupMap
        ),
        modifierGroupsSortOrder: addNameToItemUsingId(
            modifierGroupsSortOrder,
            modifyGroupMap,
            "name",
            "sortOrder"
        ),
        parentCategoryIds: addNameToItemUsingId(
            parentCategoryIds,
            categoriesMap
        ),
        sortOrder: addNameToItemUsingId(
            modifierGroupsSortOrder as SortOrderInput[],
            modifyGroupMap,
            "name",
            "sortOrder"
        ),
        voiceProperties: transformVoiceProperties(voiceProperties),
    };
    return {
        restaurant_code: restaurantCode,
        unique_identifier: payload.name,
        property_type: "MENU_ITEM",
        properties: generatePropertyList(menuPayload),
        updated_by: updatedUsername,
    };
}

function getModifierGroupPayload(props: {
    payload: ModifierGroupType;
    menuItemsMap: Record<string, MenuItemType>;
    restaurantCode: string;
    updatedUsername: string;
}) {
    const { payload, menuItemsMap, restaurantCode, updatedUsername } = props;
    const propsToOmit = ["id"];

    const updatedPayload = omitKeysFromObject(payload, propsToOmit);

    const {
        menuItems,
        sortOrder,
        voiceProperties,
        ...restProps
    } = updatedPayload;

    const menuItemOverrides = ((payload as any)
        .menuOverrides as MenuOverrideInput[]).map((item: any) => {
        const menuItem = menuItemsMap[item.objectPrimaryKey];
        return {
            ...item,
            name: menuItem.name,
        };
    });
    const defaultSelectedItemIds = payload.defaultSelectedItemIds?.map(
        (item) => {
            const menuItem = menuItemsMap[item];
            return menuItem.name;
        }
    );
    const menuPayload = {
        ...restProps,
        menuItemIds: addNameToItemUsingId(menuItems, menuItemsMap),
        childMenuItemIds: addNameToItemUsingId(menuItems, menuItemsMap),
        sortOrder: addNameToItemUsingId(
            sortOrder as SortOrderInput[],
            menuItemsMap,
            "name",
            "sortOrder"
        ),
        menuItemOverrides,
        voiceProperties: transformVoiceProperties(voiceProperties),
        defaultSelectedItemIds: defaultSelectedItemIds,
    };

    return {
        restaurant_code: restaurantCode,
        unique_identifier: payload.name,
        property_type: "MODIFIER_GROUP",
        properties: generatePropertyList(menuPayload),
        updated_by: updatedUsername,
    };
}

function getCategoryPayload(props: {
    payload: CategoryType;
    menuItemsMap: Record<string, MenuItemType>;
    timePeriodsMap: Record<string, TimePeriod>;
    restaurantCode: string;
    updatedUsername: string;
}) {
    const {
        payload,
        menuItemsMap,
        timePeriodsMap,
        restaurantCode,
        updatedUsername,
    } = props;
    const propsToOmit = ["id"];

    const updatedPayload = omitKeysFromObject(payload, propsToOmit);

    const {
        menuItems,
        sortOrder,
        timePeriods,
        voiceProperties,
        ...restProps
    } = updatedPayload;

    const menuItemOverrides = ((payload as any)
        .menuOverrides as MenuOverrideInput[]).map((item: any) => {
        const menuItem = menuItemsMap[item.objectPrimaryKey];
        return {
            ...item,
            name: menuItem.name,
        };
    });
    const menuPayload = {
        ...restProps,
        childMenuItemIds: addNameToItemUsingId(menuItems, menuItemsMap),
        menuItems: addNameToItemUsingId(menuItems, menuItemsMap),
        defaultSelectedItemIds: addNameToItemUsingId(menuItems, menuItemsMap),
        sortOrder: addNameToItemUsingId(
            sortOrder as SortOrderInput[],
            menuItemsMap,
            "name",
            "sortOrder"
        ),
        menuItemOverrides,
        timePeriodIds: addNameToItemUsingId(
            timePeriods,
            timePeriodsMap,
            "description"
        ),
        timePeriods: addNameToItemUsingId(
            timePeriods,
            timePeriodsMap,
            "description"
        ),
        voiceProperties: transformVoiceProperties(voiceProperties),
    };

    return {
        restaurant_code: restaurantCode,
        unique_identifier: payload.name,
        property_type: "CATEGORY",
        properties: generatePropertyList(menuPayload),
        updated_by: updatedUsername,
    };
}

export function createIntMenuItemSaga(
    action: PayloadAction<Omit<MenuItemType, "restaurantCode" | "id">>
): CallEffect<void> {
    return call(function* (): SagaIterator<void> {
        const { successCallback, errorCallback, ...payload } = action.payload;
        const restaurantCode = yield select(selectedRestaurantCodeSelector);
        const updatedUsername = yield select(currentUserNameSelector);
        const categoriesMap = yield select(categoriesSelector);
        const modifyGroupMap = yield select(modifierGroupWithMenuItemsSelector);
        const updatedPaylaod = getMeniItemPayload({
            payload,
            restaurantCode,
            categoriesMap,
            modifyGroupMap,
            updatedUsername,
        });
        yield call(
            createPRPIntCall,
            updatedPaylaod,
            updatedPaylaod.restaurant_code
        );
    });
}

export function updateIntMenuItemSaga(
    action: PayloadAction<Omit<MenuItemType, "restaurantCode" | "id">>
): CallEffect<void> {
    return call(function* (): SagaIterator<void> {
        const { successCallback, errorCallback, ...payload } = action.payload;
        const restaurantCode = yield select(selectedRestaurantCodeSelector);
        const updatedUsername = yield select(currentUserNameSelector);
        const categoriesMap = yield select(categoriesSelector);
        const modifyGroupMap = yield select(modifierGroupWithMenuItemsSelector);
        const updatedPaylaod = getMeniItemPayload({
            payload,
            restaurantCode,
            categoriesMap,
            modifyGroupMap,
            updatedUsername,
        });
        yield call(
            updatePRPIntCall,
            updatedPaylaod,
            updatedPaylaod.restaurant_code
        );
    });
}
export function deleteIntMenuItemSaga(
    action: PayloadAction<DeleteItemType>
): CallEffect<void> {
    return call(function* (): SagaIterator<void> {
        const restaurantCode = yield select(selectedRestaurantCodeSelector);
        const updatedUsername = yield select(currentUserNameSelector);
        let intMenuPayload: any = {
            restaurant_code: restaurantCode,
            unique_identifier: action.payload?.name || "",
            property_type: "MENU_ITEM",
            updated_by: updatedUsername,
        };

        let errorCode = null;
        try {
            yield call(getPRPIntCall, intMenuPayload, restaurantCode);
        } catch (error) {
            errorCode = error?.response?.status;
        }

        if (errorCode === 404) {
            intMenuPayload["property_type"] = "DELETE_MENU_ITEM";
            intMenuPayload["properties"] = [
                {
                    property_key: "name",
                    property_value: action.payload?.name || "",
                },
            ];
            yield call(createPRPIntCall, intMenuPayload, restaurantCode);
        } else {
            yield call(deletePRPIntCall, intMenuPayload, restaurantCode);
        }
    });
}
export function addIntModifierGroupSaga(
    action: PayloadAction<ModifierGroupType>
): CallEffect<void> {
    return call(function* (): SagaIterator<void> {
        const { successCallback, errorCallback, ...payload } = action.payload;
        const restaurantCode = yield select(selectedRestaurantCodeSelector);
        const menuItemsMap = yield select(menuItemsSelector);
        const updatedUsername = yield select(currentUserNameSelector);

        const updatedPaylaod = getModifierGroupPayload({
            payload,
            restaurantCode,
            menuItemsMap,
            updatedUsername,
        });
        yield call(createPRPIntCall, updatedPaylaod, restaurantCode);
    });
}
export function updateIntModifierGroupSaga(
    action: PayloadAction<ModifierGroupType>
): CallEffect<void> {
    return call(function* (): SagaIterator<void> {
        const { successCallback, errorCallback, ...payload } = action.payload;
        const restaurantCode = yield select(selectedRestaurantCodeSelector);
        const updatedUsername = yield select(currentUserNameSelector);
        const menuItemsMap = yield select(menuItemsSelector);
        const updatedPaylaod = getModifierGroupPayload({
            payload,
            restaurantCode,
            menuItemsMap,
            updatedUsername,
        });
        yield call(updatePRPIntCall, updatedPaylaod, restaurantCode);
    });
}
export function deleteIntModifierGroupSaga(
    action: PayloadAction<DeleteItemType>
): CallEffect<void> {
    return call(function* (): SagaIterator<void> {
        const restaurantCode = yield select(selectedRestaurantCodeSelector);
        const updatedUsername = yield select(currentUserNameSelector);

        let intMenuPayload: any = {
            restaurant_code: restaurantCode,
            unique_identifier: action.payload?.name || "",
            property_type: "MODIFIER_GROUP",
            updated_by: updatedUsername,
        };
        let errorCode = null;
        try {
            yield call(getPRPIntCall, intMenuPayload, restaurantCode);
        } catch (error) {
            errorCode = error?.response?.status;
        }

        if (errorCode === 404) {
            intMenuPayload["property_type"] = "DELETE_MODIFIER_GROUP";
            intMenuPayload["properties"] = [
                {
                    property_key: "name",
                    property_value: action.payload?.name || "",
                },
            ];
            yield call(createPRPIntCall, intMenuPayload, restaurantCode);
        } else {
            yield call(deletePRPIntCall, intMenuPayload, restaurantCode);
        }
    });
}
export function addIntCategorySaga(
    action: PayloadAction<CategoryType>
): CallEffect<void> {
    return call(function* (): SagaIterator<void> {
        const { successCallback, errorCallback, ...payload } = action.payload;
        const restaurantCode = yield select(selectedRestaurantCodeSelector);
        const updatedUsername = yield select(currentUserNameSelector);
        const menuItemsMap = yield select(menuItemsSelector);
        const timePeriodsMap = yield select(timePeriodsSelector);
        const updatedPaylaod = getCategoryPayload({
            payload,
            restaurantCode,
            menuItemsMap,
            timePeriodsMap,
            updatedUsername,
        });
        yield call(createPRPIntCall, updatedPaylaod, restaurantCode);
    });
}
export function updateIntCategorySaga(
    action: PayloadAction<CategoryType>
): CallEffect<void> {
    return call(function* (): SagaIterator<void> {
        const { successCallback, errorCallback, ...payload } = action.payload;
        const restaurantCode = yield select(selectedRestaurantCodeSelector);
        const updatedUsername = yield select(currentUserNameSelector);
        const menuItemsMap = yield select(menuItemsSelector);
        const timePeriodsMap = yield select(timePeriodsSelector);
        const updatedPaylaod = getCategoryPayload({
            payload,
            restaurantCode,
            menuItemsMap,
            timePeriodsMap,
            updatedUsername,
        });
        yield call(updatePRPIntCall, updatedPaylaod, restaurantCode);
    });
}
export function deleteIntCategorySaga(
    action: PayloadAction<DeleteItemType>
): CallEffect<void> {
    return call(function* (): SagaIterator<void> {
        const restaurantCode = yield select(selectedRestaurantCodeSelector);
        const updatedUsername = yield select(currentUserNameSelector);

        let intMenuPayload: any = {
            restaurant_code: restaurantCode,
            unique_identifier: action.payload?.name || "",
            property_type: "CATEGORY",
            updated_by: updatedUsername,
        };

        let errorCode = null;
        try {
            yield call(getPRPIntCall, intMenuPayload, restaurantCode);
        } catch (error) {
            errorCode = error?.response?.status;
        }

        if (errorCode === 404) {
            intMenuPayload["property_type"] = "DELETE_CATEGORY";
            intMenuPayload["properties"] = [
                {
                    property_key: "name",
                    property_value: action.payload?.name || "",
                },
            ];
            yield call(createPRPIntCall, intMenuPayload, restaurantCode);
        } else {
            yield call(deletePRPIntCall, intMenuPayload, restaurantCode);
        }
    });
}
