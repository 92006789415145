import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { call, put, takeEvery } from "redux-saga/effects";

import {
    EVENTS_ACTIONS,
    getEventsAction,
    eventsAcknowledgementAction,
} from "../reducers/eventsReducer";
import {
    EventsAcknowledgementPayload,
    GetEventsPayload,
    UpdatesResponse,
} from "../types/events";
import logger from "../utils/logger";
import {
    getEventsCall,
    eventsAcknowledgementCall,
} from "../utils/webserviceAPI";

// function* registerDeviceSaga(action: PayloadAction<Omit<RegisterDevicePayload, "requestId">>) {
//     logger.debug(
//         "Attempting registerDevice",
//         action.payload
//     );
//     try {
//         const registerDeviceResponse: AxiosResponse<RegisterDeviceResponse> = yield call(registerDeviceCall, action.payload);
//         yield put(updatesSubscribeAction({
//             deviceId: registerDeviceResponse.data.deviceId,
//             deviceType: action.payload.deviceType,
//             getUpdatesFor: {
//                 events: [EventType.RETURNING_GUESTS]
//             }
//         }));
//         yield put(EVENTS_ACTIONS.setDeviceId(registerDeviceResponse.data.deviceId));
//     } catch (error) {
//         logger.error( "Failed registerDevice", error );
//     }
// }

// function* updatesSubscribeSaga(action: PayloadAction<Omit<UpdatesSubscribePayload, "requestId">>) {
//     logger.debug(
//         "Attempting updatesSubscribe",
//         action.payload
//     );
//     try {
//         yield call(updatesSubscribeCall, action.payload);
//     } catch (error) {
//         logger.error( "Failed updatesSubscribe", error );
//     }
// }

// function* updatesUnsubscribeSaga(action: PayloadAction<Omit<UpdatesUnsubscribePayload, "requestId">>) {
//     logger.debug(
//         "Attempting updatesUnsubscribe",
//         action.payload
//     );
//     try {
//         yield call(updatesUnsubscribeCall, action.payload);
//         yield put(EVENTS_ACTIONS.unsetDeviceId());
//     } catch (error) {
//         logger.error( "Failed updatesUnsubscribe", error );
//     }
// }

function* getEventsSaga(
    action: PayloadAction<Omit<GetEventsPayload, "requestId">>
) {
    logger.debug("Attempting getEvents", action.payload);
    try {
        yield put(EVENTS_ACTIONS.eventsLoading());
        const updatesResponse: AxiosResponse<UpdatesResponse> = yield call(
            getEventsCall,
            action.payload
        );
        yield put(EVENTS_ACTIONS.eventsLoaded(updatesResponse.data.alerts));
    } catch (error) {
        logger.error("Failed getEvents", error);
        yield put(EVENTS_ACTIONS.eventsFailed());
    }
}

function* eventsAcknowledegmentSaga(
    action: PayloadAction<Omit<EventsAcknowledgementPayload, "requestId">>
) {
    logger.debug("Attempting eventsAcknowledegment", action.payload);
    try {
        yield call(eventsAcknowledgementCall, action.payload);
        yield put(
            EVENTS_ACTIONS.removeEventsAcknowledges(
                action.payload.acknowledgements.map((a) => a.id)
            )
        );
    } catch (error) {
        logger.error("Failed eventsAcknowledegment", error);
    }
}

export default function* eventsSaga() {
    // yield takeEvery(
    //     registerDeviceAction.toString(),
    //     registerDeviceSaga
    // );
    // yield takeEvery(
    //     updatesSubscribeAction.toString(),
    //     updatesSubscribeSaga
    // );
    // yield takeEvery(
    //     updatesUnsubscribeAction.toString(),
    //     updatesUnsubscribeSaga
    // );
    yield takeEvery(getEventsAction.toString(), getEventsSaga);
    yield takeEvery(
        eventsAcknowledgementAction.toString(),
        eventsAcknowledegmentSaga
    );
}
