import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";
import { TextField } from "mui-rff";
import React from "react";
import { useAlert } from "react-alert";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { createMenuVersion } from "../../reducers/menuReducer";
import { CreateMenuVersionResponseType } from "../../types/menuVersion";
import { TIME_OUT } from "../../utils/constants";
import ReadOnlyWrapper from "../ReadOnlyWrapper";
export default function CommitDialog({
    open,
    setIsDialogDisabled,
    toggle,
    onCommitSuccess,
}: {
    open: boolean;
    setIsDialogDisabled: (o: boolean) => void;
    toggle: () => void;
    onCommitSuccess?: () => void;
}) {
    const alert = useAlert();
    const dispatch = useDispatch();
    const validate = async ({ comment }: { comment: string }) =>
        !comment && { comment: "Menu version message is required" };
    const saveMenuVersion = ({ comment }: { comment: string }) => {
        setIsDialogDisabled(true);
        dispatch(
            createMenuVersion({
                comment,
                successCallback: (response: CreateMenuVersionResponseType) => {
                    alert.success(
                        <div>
                            New version of Menu will be created soon for
                            request_id: {response?.data?.request_id}
                        </div>,
                        {
                            timeout: TIME_OUT,
                        }
                    );
                    toggle();
                    onCommitSuccess?.();
                    setIsDialogDisabled(false);
                },
                errorCallback: (error: any) => {
                    alert.error(
                        <div>
                            <span>Error creating Menu Version</span>
                            <div>{error?.message}</div>
                        </div>,
                        {
                            timeout: TIME_OUT,
                        }
                    );
                    setIsDialogDisabled(false);
                },
            })
        );
    };

    return (
        <>
            <Dialog open={open} onClose={toggle}>
                <DialogTitle>Save Menu Version</DialogTitle>
                <DialogContent>
                    <Form
                        onSubmit={saveMenuVersion}
                        validate={validate}
                        id="saveMenuVersion"
                        render={({ handleSubmit }) => (
                            <form
                                onSubmit={handleSubmit}
                                name="saveMenuVersion"
                                id="saveMenuVersion"
                            >
                                <ReadOnlyWrapper
                                    element={TextField}
                                    autoComplete="off"
                                    label="Message"
                                    name="comment"
                                    type="text"
                                    helperText="Please write save menu version message"
                                />
                            </form>
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <ReadOnlyWrapper
                        element={Button}
                        type="submit"
                        form="saveMenuVersion"
                        color="primary"
                    >
                        Save
                    </ReadOnlyWrapper>
                    <ReadOnlyWrapper
                        element={Button}
                        onClick={toggle}
                        color="primary"
                    >
                        Cancel
                    </ReadOnlyWrapper>
                </DialogActions>
            </Dialog>
        </>
    );
}
