import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { DELAY } from "../constants";
import { deleteUserSessionAction, USER_ACTIONS } from "../reducers/userReducer";
import { getSessionID } from "../utils/local-storage";

export default function useLogout() {
    const dispatch = useDispatch();
    const alert = useAlert();
    const clearSession = () => {
        const sessionId = getSessionID();
        dispatch({
            type: deleteUserSessionAction.toString(),
            payload: sessionId,
        });
        dispatch({ type: USER_ACTIONS.logout.toString() });
    };

    const logout = (message?: string) => {
        if (message) {
            alert.info(message, { timeout: DELAY, onClose: clearSession });
        } else {
            clearSession();
        }
    };

    return {
        logout,
    };
}
