import { Divider, List, makeStyles, Paper, Theme } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import QueryString from "query-string";
import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useLogout } from "../../../hooks";
import prestoLogo from "../../../images/presto_logo.svg";
import smallPrestoLogo from "../../../images/small_presto_logo.svg";
import { SIDEBAR_LINKS } from "../../../routes";
import {
    selectedRestaurantAccessSelector,
    selectedRestaurantCodeSelector,
} from "../../../selectors/restaurant";
import { hasRoleAccess } from "../../../utils/restaurants";
import ActiveUserBadge from "../../ActiveUserBadge";
import ConfirmDialog from "../../ConfirmDialog";
import RestaurantPicker from "../../RestaurantPicker";
import { Image } from "../Image";
import { MouseHoverElementProps } from "../Wrappers/AppWrapper";
import { NavItem } from "./NavItem";
const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
    desktopSidebar: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: palette.primary.main,
        // paddingTop: spacing(2),
        position: "relative",
    },
    listWrapper: {
        width: "100%",
    },
    logo: {
        display: "flex",
        alignItems: "center",
        minHeight: "64px", // same as AppBar
        margin: spacing(0, 0, 3, 4),
    },
    smallLogo: {
        display: "flex",
        alignItems: "center",
        minHeight: "104px", // same as AppBar
        margin: "0 auto",
    },
}));

export function Sidebar(props: MouseHoverElementProps) {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);
    const selectedRestaurantAccessLevel = useSelector(
        selectedRestaurantAccessSelector
    );
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const queryParams = QueryString.parse(history.location.search);
    const isStaffTablet = queryParams["isStaffTablet"] === "true";
    // To align max-with with mui theme md =1279.95
    const isTablet = useMediaQuery({ query: "(max-width: 1279.95px)" });
    const { restaurantId } = useParams<{ restaurantId: string }>();
    const { logout } = useLogout();
    return (
        <>
            {showConfirmModal && (
                <ConfirmDialog
                    content="Do you want to log out from Presto Restaurant Portal?"
                    open={showConfirmModal}
                    onSuccess={() => {
                        logout();
                    }}
                    onCancel={() => setShowConfirmModal(false)}
                    confirmText="Yes, logout"
                    cancelText="Cancel"
                />
            )}
            <Paper className={classes.desktopSidebar} elevation={0} square>
                {!isTablet || props.isMouseHovered ? (
                    <div className={classes.logo}>
                        <Image url={prestoLogo} alt="Presto Logo" />
                    </div>
                ) : (
                    <div className={classes.smallLogo}>
                        <Image url={smallPrestoLogo} alt="Presto Logo" />
                    </div>
                )}
                <RestaurantPicker
                    isMouseHovered={props.isMouseHovered}
                    pathName={location.pathname}
                    restaurantId={restaurantId}
                />
                {restaurantCode && (
                    <>
                        <List className={classes.listWrapper}>
                            {SIDEBAR_LINKS(restaurantCode || "")
                                .filter((item) =>
                                    hasRoleAccess(
                                        item.accessLevelNeeded || null,
                                        selectedRestaurantAccessLevel
                                    )
                                )
                                .map((item) => (
                                    <NavItem
                                        href={item.href}
                                        key={item.title}
                                        title={item.title}
                                        icon={item.icon}
                                        isMouseHovered={props.isMouseHovered}
                                    />
                                ))}
                        </List>
                        <Divider />
                    </>
                )}
                {!isStaffTablet && (
                    <List className={classes.listWrapper}>
                        <NavItem
                            title="Log Out"
                            icon={ExitToAppIcon}
                            onClick={() => {
                                setShowConfirmModal(true);
                            }}
                            isMouseHovered={props.isMouseHovered}
                        />
                    </List>
                )}
                {restaurantCode && <ActiveUserBadge />}
            </Paper>
        </>
    );
}
