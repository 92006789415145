import {
    Button,
    Chip,
    Grid,
    IconButton,
    makeStyles,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    Tooltip,
    Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MyPhoneNumber from "material-ui-phone-number";
import { Autocomplete } from "mui-rff";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Form } from "react-final-form";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Dispatch } from "redux";
import ConfirmDialog from "../../components/ConfirmDialog";
import FullWidthInput from "../../components/forms/FullWidthInput";
import { EntitySaveButton } from "../../components/menu/EntitySaveButton";
import {
    CreateUserAdminInput,
    RolePermissions,
    UpdateUserAdminInput,
} from "../../generated-interfaces/graphql";
import { createUser, updateUser } from "../../reducers/userManagementReducer";
import { startPasswordreset } from "../../reducers/userReducer";
import { selectedRestaurantCodeSelector } from "../../selectors/restaurant";
import {
    restaurantGroupTableSelector,
    rolesSelector,
    TreeRestaurantGroup,
    userTableSelector,
} from "../../selectors/userManagement";
import { ROLES_DISPLAY_VALUES, TIME_OUT } from "../../utils/constants";
import { createUuid, getGSuiteName } from "../../utils/helper-functions";
import { getUserAccessLevel } from "../../utils/restaurants";
import { CallbackFunction, UserRestaurantGroupRole } from "../../utils/types";

const useStyles = makeStyles((theme: Theme) => ({
    saveBtn: {
        float: "right",
        marginLeft: "20px",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginBottom: "20px",
        },
    },
    removeBtn: {
        float: "right",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    resetPassword: {
        marginTop: theme.spacing(3),
    },
}));

function UserDetail(props: Props) {
    const { id } = useParams();
    const history = useHistory();
    const classes = useStyles();
    const alert = useAlert();

    const [formDirty, setFormDirty] = useState(false);
    const [showBackConfirmModal, setShowBackConfirmModal] = useState(false);
    const [resetConfirmModal, setResetConfirmModal] = useState(false);
    const [anchorRow, setAnchorRow] = React.useState<null | {
        el: HTMLElement;
        row: UserRestaurantGroupRole;
    }>(null);
    const [restaurantGroupSelection, setRestaurantGroupSelection] = useState<
        TreeRestaurantGroup[]
    >([]);
    const [groupRoles, setGroupRoles] = useState<UserRestaurantGroupRole[]>([]);
    const [phoneNumber, setPhoneNumber] = useState("");
    const usersMap = useSelector(userTableSelector);
    const restaurantGroupsMap = useSelector(restaurantGroupTableSelector);
    const restaurantGroups = Object.values(restaurantGroupsMap);
    const rolesMap = useSelector(rolesSelector);
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);

    const initialValues = usersMap[id];

    useEffect(() => {
        if (id !== "new" && initialValues) {
            const newRestaurantGroupSelection = restaurantGroups.filter(
                (group) => {
                    return (
                        Object.keys(
                            initialValues.restaurantGroupsMap
                        ).findIndex((name) => name === group.groupName) > -1
                    );
                }
            );
            setRestaurantGroupSelection([...newRestaurantGroupSelection]);
            const gRoles = newRestaurantGroupSelection.map(
                (restaurantGroup) => {
                    const selectedUser = restaurantGroup.attachedUsers.find(
                        (user) => user.user.id === id
                    );
                    return {
                        groupId: parseFloat(restaurantGroup.id),
                        groupName: restaurantGroup.groupName,
                        permission: (selectedUser?.permission ||
                            "RestaurantManager") as RolePermissions,
                    };
                }
            );
            setGroupRoles([...gRoles]);
            setPhoneNumber(initialValues.phoneNumber as string);
            setFormDirty(false);
        }
    }, [initialValues]);

    const onSubmit = (data: any) => {
        setFormDirty(false);
        if (id !== "new") {
            props.updateUser({
                id: parseInt(id),
                firstName: data.firstName?.trim(),
                lastName: data.lastName?.trim(),
                email: data.email?.trim(),
                phoneNumber,
                restaurantGroupRoles: groupRoles.map((groupSelection) => {
                    const { groupName, ...data } = groupSelection;
                    return data;
                }),
                successCallback: () => {
                    alert.success("User Detail Information Saved", {
                        timeout: TIME_OUT,
                    });
                },
                errorCallback: () => {
                    alert.error("Error Saving User Detail Information", {
                        timeout: TIME_OUT,
                    });
                },
            });
        } else {
            props.createUser({
                firstName: data.firstName?.trim(),
                lastName: data.lastName?.trim(),
                username: data.email?.trim(),
                email: data.email,
                password: createUuid(),
                phoneNumber,
                restaurantGroupRoles: groupRoles.map((groupSelection) => {
                    const { groupName, ...data } = groupSelection;
                    return data;
                }),
                successCallback: () => {
                    alert.success("User Detail Information Saved", {
                        timeout: TIME_OUT,
                    });
                },
                errorCallback: () => {
                    alert.error("Error Saving User Detail Information", {
                        timeout: TIME_OUT,
                    });
                },
            });
        }
    };

    const handleGoBack = (isFormDirty: boolean) => {
        if (isFormDirty) setShowBackConfirmModal(true);
        else history.push(`/${restaurantCode}/user-management/users`);
    };

    const handleRoleContextClick = (
        event: React.MouseEvent<HTMLButtonElement>,
        row: UserRestaurantGroupRole
    ) => {
        setAnchorRow({ el: event.currentTarget, row });
    };

    const handleCloseContext = () => {
        setAnchorRow(null);
    };

    const handleRoleClick = (role: RolePermissions) => {
        const newGroupRoles = [...groupRoles];
        const groupRoleIndex = groupRoles.findIndex(
            (groupRole) => groupRole.groupId === anchorRow?.row.groupId
        );
        newGroupRoles.splice(groupRoleIndex, 1, {
            groupId: groupRoles[groupRoleIndex].groupId,
            groupName: groupRoles[groupRoleIndex].groupName,
            permission: role,
        });
        setFormDirty(true);
        setGroupRoles([...newGroupRoles]);
        setAnchorRow(null);
    };

    async function validate(values: any) {
        if (values) {
            if (!values.email) {
                return { email: "Email is required" };
            }
            if (!phoneNumber || phoneNumber.length < 10) {
                return { phoneNumber: "Phone number is invalid" };
            }
            const userAccessLevel = getUserAccessLevel(groupRoles);
            if (
                userAccessLevel === RolePermissions.PrestoCustomerSupport ||
                userAccessLevel === RolePermissions.PrestoAdmin
            ) {
                const gSuiteName = getGSuiteName(values.email);
                if (gSuiteName !== "presto" && gSuiteName !== "elacarte") {
                    return {
                        email: "Email address must be Presto or E La Carte",
                    };
                }
            }
        }
        return;
    }

    return (
        <React.Fragment>
            <Form
                onSubmit={onSubmit}
                validate={validate}
                initialValues={initialValues}
                render={({ handleSubmit, values, valid, dirty }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        {showBackConfirmModal && (
                            <ConfirmDialog
                                title="Leave without saving?"
                                content="The changes you've made will be lost. Are you sure you want to discard the changes?"
                                open={showBackConfirmModal}
                                onSuccess={() => {
                                    history.push(
                                        `/${restaurantCode}/user-management/users`
                                    );
                                }}
                                onCancel={() => setShowBackConfirmModal(false)}
                            />
                        )}
                        {resetConfirmModal && (
                            <ConfirmDialog
                                content="Are you sure you want to reset the password?"
                                confirmText="Yes, Reset Password"
                                cancelText="No, Go Back"
                                open={resetConfirmModal}
                                onSuccess={() => {
                                    props.startPasswordreset({
                                        email: values.email,
                                    });
                                    setResetConfirmModal(false);
                                }}
                                onCancel={() => setResetConfirmModal(false)}
                            />
                        )}
                        <IconButton onClick={() => handleGoBack(dirty)}>
                            <ArrowBackIcon />
                        </IconButton>
                        <EntitySaveButton
                            disabled={!valid || (!dirty && !formDirty)}
                            className={classes.saveBtn}
                        />
                        <Typography
                            variant="h6"
                            color="primary"
                            style={{ marginTop: "24px", marginBottom: "16px" }}
                        >
                            User Information
                        </Typography>
                        <Grid container style={{ maxWidth: "600px" }}>
                            <Grid item xs={6} style={{ paddingRight: "10px" }}>
                                <FullWidthInput
                                    name="firstName"
                                    placeholder="First Name"
                                    label="First Name"
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingLeft: "10px" }}>
                                <FullWidthInput
                                    name="lastName"
                                    placeholder="Last Name"
                                    label="Last Name"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FullWidthInput
                                    name="email"
                                    placeholder="Email"
                                    label="Email"
                                    type="email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MyPhoneNumber
                                    label="Phone Number"
                                    defaultCountry="us"
                                    value={phoneNumber || ""}
                                    onChange={(phone: any) => {
                                        setPhoneNumber(phone as string);
                                        setFormDirty(
                                            !(phone === "+" || phone === "+1")
                                        );
                                    }}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Typography
                                style={{
                                    marginTop: "32px",
                                    marginBottom: "16px",
                                }}
                            >
                                Assigned to
                            </Typography>
                        </Grid>

                        <Grid container style={{ maxWidth: "800px" }}>
                            <Autocomplete
                                multiple
                                autoHighlight
                                fullWidth
                                id="restaurantGroups"
                                name="restaurantGroups"
                                label="Add Restaurant Group"
                                options={restaurantGroups}
                                value={restaurantGroupSelection}
                                onChange={(event, value) => {
                                    setRestaurantGroupSelection(
                                        value as TreeRestaurantGroup[]
                                    );
                                    setFormDirty(true);
                                    const gRoles = (value as TreeRestaurantGroup[]).map(
                                        (restaurantGroup) => {
                                            const selectedUser = restaurantGroup.attachedUsers.find(
                                                (user) => user.user.id === id
                                            );
                                            return {
                                                groupId: parseFloat(
                                                    restaurantGroup.id
                                                ),
                                                groupName:
                                                    restaurantGroup.groupName,
                                                permission: (selectedUser?.permission ||
                                                    "RestaurantManager") as RolePermissions,
                                            };
                                        }
                                    );
                                    setGroupRoles([...gRoles]);
                                }}
                                getOptionLabel={(option) => option.groupName}
                                getOptionValue={(option) => option}
                                renderTags={(value, getTagProps) => {
                                    return value.map((option, index) => (
                                        <Chip
                                            variant="default"
                                            label={option.groupName}
                                            {...getTagProps({ index })}
                                        />
                                    ));
                                }}
                            />
                        </Grid>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell width="80%">
                                        Restaurant Group
                                    </TableCell>
                                    <TableCell>User Type</TableCell>
                                    <TableCell width="30px"> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {groupRoles.map((groupRole) => {
                                    return (
                                        <TableRow key={groupRole.groupId}>
                                            <TableCell>
                                                {groupRole.groupName}
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    ROLES_DISPLAY_VALUES[
                                                        groupRole.permission
                                                    ]
                                                }
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={(e) =>
                                                        handleRoleContextClick(
                                                            e,
                                                            groupRole
                                                        )
                                                    }
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                    anchorEl={anchorRow?.el}
                                                    open={Boolean(
                                                        anchorRow?.el
                                                    )}
                                                    onClose={handleCloseContext}
                                                >
                                                    {Object.values(
                                                        rolesMap
                                                    ).map((role) => (
                                                        <Tooltip
                                                            title={
                                                                role.description
                                                            }
                                                            key={
                                                                role.permission
                                                            }
                                                        >
                                                            <MenuItem
                                                                onClick={() =>
                                                                    handleRoleClick(
                                                                        role.permission
                                                                    )
                                                                }
                                                            >
                                                                {
                                                                    ROLES_DISPLAY_VALUES[
                                                                        role
                                                                            .permission
                                                                    ]
                                                                }
                                                            </MenuItem>
                                                        </Tooltip>
                                                    ))}
                                                </Menu>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        {id !== "new" && (
                            <div>
                                <Typography className={classes.resetPassword}>
                                    Reset User Password
                                </Typography>
                                <Tooltip title="This will reset this user’s password and send a password change email">
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        onClick={() =>
                                            setResetConfirmModal(true)
                                        }
                                        disableElevation
                                    >
                                        Reset User Password
                                    </Button>
                                </Tooltip>
                            </div>
                        )}
                    </form>
                )}
            />
        </React.Fragment>
    );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        createUser: (data: CreateUserAdminInput & CallbackFunction) =>
            dispatch(createUser(data)),
        updateUser: (data: UpdateUserAdminInput & CallbackFunction) =>
            dispatch(updateUser(data)),
        startPasswordreset: (credentials: { email: string }) =>
            dispatch(startPasswordreset(credentials)),
    };
};

const connected = connect(null, mapDispatchToProps);
type Props = ConnectedProps<typeof connected>;

export default connected(UserDetail);
