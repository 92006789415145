import {
    Button,
    Grid,
    makeStyles,
    TextField,
    Theme,
    Typography,
} from "@material-ui/core";
import { CheckCircle, Mail, Message } from "@material-ui/icons";
import * as React from "react";
import { ChangeEvent } from "react";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { Image } from "../../components/layout/Image";
import ReadOnlyWrapper from "../../components/ReadOnlyWrapper";
import prestoLogo from "../../images/presto_logo_white.png";
import { startPasswordreset } from "../../reducers/userReducer";
import { isResetCodeSentSelector } from "../../selectors/user";

const useStyles = makeStyles((theme: Theme) => ({
    background: {
        width: "100vw",
        height: "100vh",
        backgroundColor: theme.palette.primary.main,
        position: "absolute",
        zIndex: -999,
    },
    prestoLogo: {
        marginBottom: theme.spacing(4),
    },
    gridContainer: {
        height: "100vh",
        backgroundColor: theme.palette.primary.main,
        minHeight: "480px",
        paddingBottom: "100px",
        "@media (max-height: 600px)": {
            paddingBottom: 0,
        },
    },
    mainContainer: {
        flex: 1,
    },
    form: {
        color: "white",
        margin: theme.spacing(2),
        "& .MuiTextField-root": {
            marginBottom: theme.spacing(2),
        },
    },
    loginFormInputs: {
        color: "#fff",
        borderBottom: "2px solid white",
        backgroundColor: "#18394e !important",
    },
    loginFormLabel: {
        color: "#fff",
        "&.Mui-focused": {
            color: "#fff",
        },
        "& .MuiInputLabel-asterisk": {
            display: "none",
        },
    },
    contactContainer: {
        marginTop: theme.spacing(2),
    },
    contact: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1, 0),
        "& a": {
            textDecoration: "none",
            color: theme.palette.primary.dark,
        },
    },
    emailInput: {
        color: "#fff",
        borderBottom: "2px solid white",
        backgroundColor: "#18394e !important",
        marginBottom: "36px",
    },
    inputLabel: {
        color: "#fff",
        borderBottom: "2px solid white",
        backgroundColor: "#18394e !important",
        "&.Mui-focused": {
            color: "#fff",
        },
        "& .MuiInputLabel-asterisk": {
            display: "none",
        },
    },
}));

function ForgotPasswordEmail(props: StateProps) {
    const classes = useStyles();
    const isResetCodeSent = useSelector(isResetCodeSentSelector);
    console.log("isResetCodeSent", isResetCodeSent);

    const [email, setEmail] = React.useState("");

    const handleSubmit = (e: any) => {
        e.preventDefault();
        props.startPasswordreset({ email });
    };

    return (
        <React.Fragment>
            <div className={classes.background} />
            <Grid
                className={classes.gridContainer}
                container
                direction="column"
                alignItems="center"
            >
                <Grid
                    container
                    className={classes.mainContainer}
                    direction="column"
                    alignItems="center"
                    justify="space-around"
                >
                    <Grid item>
                        <Image
                            className={classes.prestoLogo}
                            url={prestoLogo}
                            alt="Presto Logo"
                        />
                    </Grid>
                    <Grid item>
                        <div className={classes.form}>
                            <form onSubmit={handleSubmit}>
                                <Typography
                                    variant="h5"
                                    color="primary"
                                    style={{ marginBottom: "16px" }}
                                >
                                    Forgotten Password
                                </Typography>
                                <Typography style={{ marginBottom: "16px" }}>
                                    {!isResetCodeSent
                                        ? "Enter the email address associated with your account and we will email you a link to reset your password."
                                        : "An email has been sent to your email. If an account exists with that email address, it should arrive within minutes."}
                                </Typography>
                                {!isResetCodeSent ? (
                                    <>
                                        <ReadOnlyWrapper
                                            element={TextField}
                                            name="forgotEmail"
                                            type="email"
                                            placeholder="Enter your email"
                                            label="Email"
                                            color="primary"
                                            fullWidth
                                            value={email}
                                            variant="filled"
                                            required
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>
                                            ) => setEmail(e.target.value)}
                                            className={classes.emailInput}
                                            InputProps={{
                                                className: classes.inputLabel,
                                            }}
                                            InputLabelProps={{
                                                style: { color: "white" },
                                            }}
                                        />
                                        <Button
                                            type="submit"
                                            color="secondary"
                                            variant="contained"
                                            style={{ float: "right" }}
                                            disableElevation
                                        >
                                            Send Email
                                        </Button>
                                    </>
                                ) : (
                                    <div
                                        style={{
                                            textAlign: "center",
                                            marginTop: "16px",
                                        }}
                                    >
                                        <CheckCircle
                                            color="secondary"
                                            style={{ fontSize: "48px" }}
                                        />
                                    </div>
                                )}
                            </form>
                        </div>
                    </Grid>
                    <Grid item />
                </Grid>
                <Grid item className={classes.contactContainer}>
                    <Typography className={classes.contact}>
                        <Mail
                            color="secondary"
                            style={{ marginRight: "5px" }}
                        />
                        <a href="mailto:contactless.support@presto.com">
                            contactless.support@presto.com
                        </a>
                    </Typography>
                    <Typography className={classes.contact}>
                        <Message
                            color="secondary"
                            style={{ marginRight: "5px" }}
                        />
                        <a href="tel: +14155489347">+1 (415) 548 - 9347</a>
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        startPasswordreset: (credentials: { email: string }) =>
            dispatch(startPasswordreset(credentials)),
    };
};

const connected = connect(null, mapDispatchToProps);

type StateProps = ConnectedProps<typeof connected>;

export default connected(ForgotPasswordEmail);
