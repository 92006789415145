import { Button, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
    MenuItem,
    MenuItem as MenuItemType,
    ModalityType,
    OverrideKey,
} from "../../generated-interfaces/graphql";
import { menuItemsSelector } from "../../utils/menu";
import ReadOnlyWrapper from "../ReadOnlyWrapper";
import DragItemList, { DragItemType, OverrideInput } from "./DragItemList";

interface Props {
    label: string;
    listItemType: DragItemType;
    secondaryId?: string;
    itemList: MenuItemType[];
    setItemList: (itemList: MenuItemType[]) => void;
    setOverride: (
        objPrimaryKey: string,
        modalityType: ModalityType,
        override: OverrideInput
    ) => void;
    defaultSelectedItemIds?: number[];
    setDefaultSelectedItemIds?: (dds: number[]) => void;
}

export default function DragContainer(props: Props) {
    const [selectedItem, setSelectedItem] = useState<MenuItemType | null>(null);
    const {
        itemList,
        setItemList,
        defaultSelectedItemIds,
        setDefaultSelectedItemIds,
    } = props;
    const menuItemMap = useSelector(menuItemsSelector);
    const menuItems = Object.values(menuItemMap);

    const allowedItemsFilter = (menuItem: MenuItem) => {
        if (
            props.listItemType === "menuItemForCategory" &&
            menuItem.isModifierOnly
        ) {
            return false;
        }
        return !itemList.find((item) => item?.id === menuItem?.id);
    };
    const allowedMenuItems = menuItems.filter(allowedItemsFilter);

    const handleRemoveFromList = (id: string) => {
        const newItemList = [...itemList];
        const index = newItemList.findIndex((item) => item?.id === id);
        newItemList.splice(index, 1);
        setItemList([...newItemList]);

        // resetting override values
        props.setOverride(id, ModalityType.Dinein, {
            objectPrimaryKey: id,
            overrideKey: OverrideKey.Price,
            overrideValue: null,
            modalityType: ModalityType.Dinein,
        });
        props.setOverride(id, ModalityType.Togo, {
            objectPrimaryKey: id,
            overrideKey: OverrideKey.Price,
            overrideValue: null,
            modalityType: ModalityType.Togo,
        });
        props.setOverride(id, ModalityType.Delivery, {
            objectPrimaryKey: id,
            overrideKey: OverrideKey.Price,
            overrideValue: null,
            modalityType: ModalityType.Delivery,
        });
    };

    return (
        <div style={{ marginBottom: 60 }}>
            <div
                style={{
                    display: "flex",
                    marginBottom: "20px",
                    alignItems: "center",
                }}
            >
                <ReadOnlyWrapper
                    element={Autocomplete}
                    autoSelect
                    autoHighlight
                    value={selectedItem}
                    inputValue={selectedItem?.name}
                    id="selectedItem"
                    options={allowedMenuItems}
                    getOptionLabel={(option: any) => option.name}
                    onChange={(event: any, value: any, reason: string) => {
                        setSelectedItem(value as MenuItemType);
                    }}
                    renderInput={(params: any) => (
                        <ReadOnlyWrapper
                            element={TextField}
                            {...params}
                            label={props.label}
                            variant="outlined"
                            required={itemList.length === 0}
                        />
                    )}
                    style={{
                        marginRight: "20px",
                        width: "300px",
                    }}
                />
                <ReadOnlyWrapper
                    element={Button}
                    disableElevation
                    color="secondary"
                    variant="contained"
                    disabled={!selectedItem}
                    onClick={() => {
                        if (selectedItem) {
                            const index = itemList.findIndex(
                                (item) => item?.id === selectedItem?.id
                            );
                            if (index === -1) {
                                setItemList([
                                    ...itemList,
                                    selectedItem as MenuItemType,
                                ]);
                                setSelectedItem(null);
                            }
                        }
                    }}
                    style={{
                        height: "42px",
                    }}
                >
                    Add
                </ReadOnlyWrapper>
            </div>
            <DragItemList
                listItemType={props.listItemType}
                secondaryId={props.secondaryId}
                setOverride={props.setOverride}
                itemList={itemList}
                setItemList={setItemList}
                removeItem={handleRemoveFromList}
                defaultSelectedItemIds={defaultSelectedItemIds}
                updateDefaultSelectedItemId={(ids: number[]) =>
                    setDefaultSelectedItemIds?.(ids)
                }
            />
        </div>
    );
}
