import { useMutation } from "@apollo/react-hooks";
import {
    Box,
    Button,
    Chip,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
    Menu,
    MenuItem,
    Radio,
    RadioGroup,
    Tooltip,
    Typography,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
    Autocomplete,
    Checkboxes,
    Radios,
    TextField,
    TextFieldProps,
} from "mui-rff";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Form, FormSpy } from "react-final-form";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { Prompt, useLocation, useParams } from "react-router-dom";
import { Dispatch } from "redux";
import ConfirmDialog from "../../components/ConfirmDialog";
import NumericInput from "../../components/forms/NumericInput";
import ImageUpload from "../../components/ImageUpload";
import DragModifierGroup from "../../components/menu/DragModifierGroup";
import { NewInputProperties } from "../../components/menu/InputProperties";
import ReadOnlyWrapper from "../../components/ReadOnlyWrapper";
import { MenuType } from "../../constants/menu";
import {
    Category,
    InputProperties,
    MenuItemSettingKey,
    MenuOverrideInput,
    ModalityType,
    NewMenuItemSettingForMenuItemInput,
    OverrideKey,
    OverrideType,
    SortOrderInput,
} from "../../generated-interfaces/graphql";
import {
    useCustomHistory,
    useDuplicatePayload,
    useVoiceProperties,
} from "../../hooks";
import { UploadMutation } from "../../queries/imageUpload";
import {
    createMenuItem,
    deleteMenuItem,
    duplicateMenuItem,
    getVoiceProperties,
    updateMenuItem,
} from "../../reducers/menuReducer";
import { RootState } from "../../reducers/rootReducer";
import {
    currencySelector,
    selectedRestaurantCodeSelector,
} from "../../selectors/restaurant";
import {
    ComponentType,
    IDuplicatePayload,
    MenuItemType,
} from "../../types/menu";
import { TIME_OUT } from "../../utils/constants";
import { formatCurrency } from "../../utils/currency";
import {
    compareExtraValues,
    getStartOfTomorrow,
} from "../../utils/helper-functions";
import {
    categoriesSelector,
    getValidKeyValuePair,
    menuItemsWithCategoriesSelector,
    MenuItemWithCategories,
    ModifierGroupWithMenuItems,
    modifierGroupWithMenuItemsSelector,
    posPropertiesSelector,
    renderTableCollectionText,
    timePeriodTableSelector,
} from "../../utils/menu";
import {
    DeleteItemType,
    ExtraValues,
    GetIngestedDataType,
    ModalityObject,
    StringOrNull,
} from "../../utils/types";
import ActionItems from "./ActionItems";

type AvailabilityType =
    | "available"
    | "unavailable_today"
    | "unavailable_indefinitely"
    | "limited_available";

export type FormValue = MenuItemWithCategories &
    ExtraValues & {
        posId?: string;
        availabilityType: AvailabilityType;
        selectedModifierGroup?: any;
    };

const useStyles = makeStyles((theme) => ({
    form: {
        paddingBottom: "60px",
    },
    priceInput: {
        width: "80px",
        flexDirection: "row",
        "&.override": {
            backgroundColor: theme.palette.primary.light,
            "& .MuiInputBase-input": {
                color: theme.palette.primary.dark,
            },
        },
    },
    priceInputWrapper: {
        display: "flex",
        alignItems: "center",
        "& > :nth-child(1)": {
            marginRight: "10px",
        },
    },
    itemsContainer: {
        margin: theme.spacing(3, 0),
    },
    modalityWrapper: {
        marginBottom: "28px",
        "& .MuiBox-root": {
            width: "100%",
            padding: "10px",
        },
    },
    checkbox: {
        marginBottom: "20px",
        "& .MuiFormLabel-root": {
            color: "#9e9e9e",
        },
    },
    limitedQuantityOption: {
        display: "flex",
        alignItems: "center",
        "& .custom-input-gray": {
            marginLeft: "16px",
            width: "55px",
        },
    },
}));

const formKeys = (classes: ReturnType<typeof useStyles>) => (
    key: string,
    isDefaultTax: boolean = false
): TextFieldProps => {
    const keys: { [key: string]: TextFieldProps } = {
        displayName: {
            fullWidth: true,
            id: "name",
            label: "Item Name",
            name: "name",
            margin: "normal",
            style: {
                marginBottom: "40px",
            },
            required: true,
        },
        description: {
            id: "description",
            label: "Description (optional)",
            placeholder: "Enter description here",
            name: "description",
            fullWidth: true,
            multiline: true,
            rows: 4,
            margin: "normal",
            variant: "filled",
            style: {
                marginBottom: 40,
            },
            InputProps: {
                disableUnderline: true,
            },
        },
        search: {
            id: "search",
            name: "search",
            placeholder: "Add Category",
            margin: "normal",
        },
    };
    return keys[key];
};

function NewMenuItem(props: Props) {
    const classes = useStyles();
    const { id } = useParams<{ id: string }>();
    const alert = useAlert();
    const modify = useLocation().pathname.endsWith("modify");

    const [uploadFile, uploadedResult] = useMutation(UploadMutation);
    const [imageLoadingStatus, setImageLoadingStatus] = useState("");
    const [blockNavigation, setBlockNavigation] = useState(false);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [showBackConfirmModal, setShowBackConfirmModal] = useState(false);
    const [showModifierOnlyModal, setShowModifierOnlyModal] = useState(false);
    const [isModifierOnly, setIsModifierOnly] = useState("false");
    const [modifierGroupList, setModifierGroupList] = useState<
        ModifierGroupWithMenuItems[]
    >([]);
    const [originModifierIdList, setOriginModifierIdList] = useState("");
    const [parentCategorySelections, updateParentCategorySelections] = useState<
        Category[]
    >([]);
    const [imageUrl, setImageUrl] = useState<StringOrNull>(null);
    const categoriesMap = useSelector(categoriesSelector);
    const menuItemsMap = useSelector(menuItemsWithCategoriesSelector);
    const modifyGroupMap = useSelector(modifierGroupWithMenuItemsSelector);
    const restaurantSettings = useSelector(
        (state: RootState) =>
            state.restaurant.selectedRestaurant?.restaurantSettings
    );
    const formattedTimePeriodsMap = useSelector(timePeriodTableSelector);
    const categories = Object.values(categoriesMap);
    const modifyGroups = Object.values(modifyGroupMap);
    const formTextFields = formKeys(classes);
    const initialValues = menuItemsMap[id];
    const [anchorEls, setAnchorEls] = React.useState<
        Record<string, HTMLElement>
    >({});
    const currency = useSelector(currencySelector);
    const {
        voiceProperties,
        setVoiceProperties,
        synonymProperties,
        setSynonymProperties,
        fillVoiceProps,
    } = useVoiceProperties([{ key: "", value: "" }], initialValues?.name);
    const [posProperties, setPosProperties] = useState<InputProperties[]>([]);
    const posPropertiesFromStore = useSelector(posPropertiesSelector);
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);
    const { generateDuplicatePayload } = useDuplicatePayload();
    const { pushToHistory } = useCustomHistory();

    const populateExtraValues = useCallback((): ExtraValues => {
        const settings = Object.values(initialValues?.settings || []);
        const overrides = Object.values(initialValues?.overrides || []);
        const toGoPriceOverride = overrides.find(
            (item) =>
                item.modalityType === ModalityType.Togo &&
                item.overrideType === OverrideType.MenuItem &&
                !item.secondaryType &&
                item.overrideKey === OverrideKey.Price
        );
        const toGoTaxOverride = overrides.find(
            (item) =>
                item.modalityType === ModalityType.Togo &&
                item.overrideType === OverrideType.MenuItem &&
                !item.secondaryType &&
                item.overrideKey === OverrideKey.Tax
        );
        const deliveryPriceOverride = overrides.find(
            (item) =>
                item.modalityType === ModalityType.Delivery &&
                item.overrideType === OverrideType.MenuItem &&
                !item.secondaryType &&
                item.overrideKey === OverrideKey.Price
        );
        const deliveryTaxOverride = overrides.find(
            (item) =>
                item.modalityType === ModalityType.Delivery &&
                item.overrideType === OverrideType.MenuItem &&
                !item.secondaryType &&
                item.overrideKey === OverrideKey.Tax
        );
        return {
            dineIn: {
                enabled:
                    !settings.find(
                        (mis) => mis.key === MenuItemSettingKey.IsDineInEnabled
                    ) ||
                    !!settings.find(
                        (mis) =>
                            mis.key === MenuItemSettingKey.IsDineInEnabled &&
                            mis.value === "true"
                    ),
                price: String(initialValues?.price || 0),
                tax: String(
                    initialValues?.tax !== null
                        ? initialValues?.tax
                        : restaurantSettings?.dineInModalityTaxRate
                ),
                defaultTax: String(restaurantSettings?.dineInModalityTaxRate),
                priceOverridden: () => false,
                taxOverridden: (tax) =>
                    String(tax) !==
                    String(restaurantSettings?.dineInModalityTaxRate),
            },
            toGo: {
                enabled: !!settings.find(
                    (mis) =>
                        mis.key === MenuItemSettingKey.IsToGoEnabled &&
                        mis.value === "true"
                ),
                price: String(
                    toGoPriceOverride
                        ? Number(toGoPriceOverride.overrideValue)
                        : initialValues?.price || 0
                ),
                tax: String(
                    toGoTaxOverride
                        ? Number(toGoTaxOverride.overrideValue)
                        : restaurantSettings?.toGoModalityTaxRate
                ),
                defaultTax: String(restaurantSettings?.toGoModalityTaxRate),
                priceOverridden: (price, dineInPrice) =>
                    parseFloat(String(price)) !==
                    parseFloat(String(dineInPrice)),
                taxOverridden: (tax) =>
                    String(tax) !==
                    String(restaurantSettings?.toGoModalityTaxRate),
            },
            delivery: {
                enabled: !!settings.find(
                    (mis) =>
                        mis.key === MenuItemSettingKey.IsDeliveryEnabled &&
                        mis.value === "true"
                ),
                price: String(
                    deliveryPriceOverride
                        ? Number(deliveryPriceOverride.overrideValue)
                        : initialValues?.price || 0
                ),
                tax: String(
                    deliveryTaxOverride
                        ? Number(deliveryTaxOverride.overrideValue)
                        : restaurantSettings?.deliveryModalityTaxRate
                ),
                defaultTax: String(restaurantSettings?.deliveryModalityTaxRate),
                priceOverridden: (price, dineInPrice) =>
                    parseFloat(String(price)) !==
                    parseFloat(String(dineInPrice)),
                taxOverridden: (tax) =>
                    String(tax) !==
                    String(restaurantSettings?.deliveryModalityTaxRate),
            },
        };
    }, [initialValues, restaurantSettings]);

    const initialExtraValues = populateExtraValues();

    const [extraValues, setExtraValues] = useState<ExtraValues | FormValue>(
        populateExtraValues()
    );

    useEffect(() => {
        if (id !== "new" && initialValues) {
            let newModifierGroupSort = [...initialValues.sortOrder];
            newModifierGroupSort.sort((a, b) => {
                if (a.sortOrder === null || b.sortOrder === null) return 1;
                return a.sortOrder - b.sortOrder;
            });
            const modifierList = newModifierGroupSort.map(
                (mg) => modifyGroupMap[mg.id]
            );

            setModifierGroupList(modifierList);
            setOriginModifierIdList(modifierList.map((mg) => mg.id).join(""));

            let availabilityType: AvailabilityType = "available";
            if (initialValues.available) {
                if (initialValues.availableLimitedQuantity !== null) {
                    availabilityType = "limited_available";
                } else {
                    availabilityType = "available";
                }
            } else if (initialValues.unavailableUntil !== null) {
                availabilityType = "unavailable_today";
            } else {
                availabilityType = "unavailable_indefinitely";
            }
            (initialValues as any).availabilityType = availabilityType;
            const posIdSetting = Object.values(
                initialValues.posPropertiesRecord
            ).find((setting) => setting.key === "pos_id");
            (initialValues as any).posId = posIdSetting?.value || "";
            setImageUrl(initialValues.imageUrl);
            setIsModifierOnly(String(initialValues.isModifierOnly));
            // setPrice(Number.isInteger(initialValues.price) ? initialValues.price + '.00' : initialValues.price.toString());
            // setTaxRate((initialValues.tax !== null) ? initialValues.tax : (restaurantSettings?.dineInModalityTaxRate || null));
            updateParentCategorySelections(
                Object.values(initialValues.parentCategories)
            );
        } else {
            resetStateToDefault();
        }
    }, [initialValues, id, modifyGroupMap]);

    useEffect(() => {
        setExtraValues(populateExtraValues());
    }, [initialValues, populateExtraValues, restaurantSettings]);

    useEffect(() => {
        window.onbeforeunload = null;
    }, []);

    useEffect(() => {
        if (initialValues?.posProperties?.length > 0) {
            const { posProperties: menuItemPosProerties } = initialValues;
            const posPropsMap: InputProperties[] = [];
            menuItemPosProerties.forEach((item) => {
                if (posPropertiesFromStore[item]) {
                    const { key, value } = posPropertiesFromStore[item];
                    posPropsMap.push({
                        key,
                        value,
                    });
                }
            });
            setPosProperties(posPropsMap);
        }
    }, [posPropertiesFromStore, initialValues?.posProperties]);

    async function validate(values: FormValue) {
        if (uploadedResult.loading) {
            return { imageUpload: "Image has not finished uploading" };
        }
        if (!values.name) {
            return { name: "Display name is required" };
        }
        if (
            values.dineIn.enabled &&
            (values.dineIn.price === null || values.dineIn.price === undefined)
        ) {
            return { required: "Dine-in price is required" };
        }
        if (
            values.dineIn.enabled &&
            values.dineIn.tax &&
            Number(values.dineIn.tax) > 100
        ) {
            return { required: "Dine-in tax can not be greater than 100" };
        }
        if (
            values.delivery.enabled &&
            (values.delivery.price === null ||
                values.delivery.price === undefined)
        ) {
            return { required: "Delivery price is required" };
        }
        if (
            values.delivery.enabled &&
            values.delivery.tax &&
            Number(values.delivery.tax) > 100
        ) {
            return { required: "Delivery tax can not be greater than 100" };
        }
        if (
            values.toGo.enabled &&
            (values.toGo.price === null || values.toGo.price === undefined)
        ) {
            return { required: "To-go price is required" };
        }
        if (
            values.toGo.enabled &&
            values.toGo.tax &&
            Number(values.toGo.tax) > 100
        ) {
            return { required: "To-go tax can not be greater than 100" };
        }
        if (
            !values.dineIn.enabled &&
            !values.delivery.enabled &&
            !values.toGo.enabled
        ) {
            return { required: "Atleast 1 modality is required" };
        }
        return;
    }

    const handleRemoveItem = () => {
        setShowDeleteConfirmModal(true);
    };

    const handleRemoveFromModifierGroupList = (id: string) => {
        const newModifierList = [...modifierGroupList];
        const index = modifierGroupList.findIndex(
            (modifier) => modifier.id === id
        );
        newModifierList.splice(index, 1);
        setModifierGroupList([...newModifierList]);
    };

    const handleUploadMenuItemImage = (file: any) => {
        uploadFile({
            variables: { file, restaurantCode: props.restaurantCode },
        });
    };

    const resetStateToDefault = () => {
        setBlockNavigation(false);
        setShowBackConfirmModal(false);
        setImageLoadingStatus("");
        setShowDeleteConfirmModal(false);
        setShowBackConfirmModal(false);
        setShowModifierOnlyModal(false);
        setIsModifierOnly("false");
        setModifierGroupList([]);
        setOriginModifierIdList("");
        updateParentCategorySelections([]);
        setImageUrl(null);
        setAnchorEls({});
        setVoiceProperties([{ key: "", value: "" }]);
        setSynonymProperties([{ key: "", value: "" }]);
    };

    const onSubmit = (data: FormValue) => {
        if (imageLoadingStatus === "done" && uploadedResult.called === false) {
            alert.show("Select Replace Image Button to save image", {
                timeout: TIME_OUT,
            });
            return;
        }
        const parentCategoryIds: number[] = Object.values(
            parentCategorySelections
        ).map((cat: any) => parseFloat(cat.id));
        const modifierGroupIds: number[] = [];
        const modifierGroupsSortOrder: SortOrderInput[] = [];

        modifierGroupList.forEach(
            (mg: ModifierGroupWithMenuItems, index: number) => {
                modifierGroupIds.push(parseFloat(mg.id));
                modifierGroupsSortOrder.push({
                    id: parseFloat(mg.id),
                    sortOrder: index,
                });
            }
        );

        const menuItemId = parseFloat(data.id ? data.id : id);

        const menuOverrides: MenuOverrideInput[] =
            id !== "new"
                ? [
                      {
                          objectPrimaryKey: String(menuItemId),
                          secondaryType: null,
                          secondaryId: null,
                          overrideKey: OverrideKey.Price,
                          modalityType: ModalityType.Togo,
                          overrideValue: data.toGo.priceOverridden(
                              data.toGo.price,
                              data.dineIn.price
                          )
                              ? String(data.toGo.price)
                              : null,
                      },
                      {
                          objectPrimaryKey: String(menuItemId),
                          secondaryType: null,
                          secondaryId: null,
                          overrideKey: OverrideKey.Tax,
                          modalityType: ModalityType.Togo,
                          overrideValue: data.toGo.taxOverridden(data.toGo.tax)
                              ? String(data.toGo.tax)
                              : null,
                      },
                      {
                          objectPrimaryKey: String(menuItemId),
                          secondaryType: null,
                          secondaryId: null,
                          overrideKey: OverrideKey.Price,
                          modalityType: ModalityType.Delivery,
                          overrideValue: data.delivery.priceOverridden(
                              data.delivery.price,
                              data.dineIn.price
                          )
                              ? String(data.delivery.price)
                              : null,
                      },
                      {
                          objectPrimaryKey: String(menuItemId),
                          secondaryType: null,
                          secondaryId: null,
                          overrideKey: OverrideKey.Tax,
                          modalityType: ModalityType.Delivery,
                          overrideValue: data.delivery.taxOverridden(
                              data.delivery.tax
                          )
                              ? String(data.delivery.tax)
                              : null,
                      },
                  ]
                : [];

        const menuItemSettings: NewMenuItemSettingForMenuItemInput[] = [
            {
                key: MenuItemSettingKey.IsDineInEnabled,
                value: data.dineIn.enabled ? "true" : "false",
            },
            {
                key: MenuItemSettingKey.IsToGoEnabled,
                value: data.toGo.enabled ? "true" : "false",
            },
            {
                key: MenuItemSettingKey.IsDeliveryEnabled,
                value: data.delivery.enabled ? "true" : "false",
            },
        ];

        let available = ["available", "limited_available"].includes(
            data.availabilityType
        );
        if (
            data.availabilityType === "limited_available" &&
            typeof data.availableLimitedQuantity === "string" &&
            parseInt(data.availableLimitedQuantity) === 0
        )
            available = false;

        const menuItem = {
            name: data.name?.trim(),
            description: data.description?.trim() || "",
            price: parseFloat(String(data.dineIn.price)),
            tax: data.dineIn.taxOverridden(data.dineIn.tax)
                ? Number(data.dineIn.tax)
                : null,
            isModifierOnly: isModifierOnly === "true",
            available: available,
            unavailableUntil:
                !available && data.availabilityType === "unavailable_today"
                    ? getStartOfTomorrow()
                    : null,
            availableLimitedQuantity:
                data.availabilityType === "limited_available" &&
                typeof data.availableLimitedQuantity === "string" &&
                parseInt(data.availableLimitedQuantity) > 0
                    ? parseInt(data.availableLimitedQuantity)
                    : null,
            parentCategoryIds,
            modifierGroupIds,
            modifierGroupsSortOrder,
            imageUrl: uploadedResult?.data?.uploadImage || imageUrl || null,
            menuItemSettings,
            menuOverrides,
            successCallback: () => {
                alert.success("Menu Item Saved", {
                    timeout: TIME_OUT,
                });
            },
            errorCallback: () => {
                alert.error("Error Saving Menu Item", {
                    timeout: TIME_OUT,
                });
            },
        } as any;

        const updatedVoiceProps = getValidKeyValuePair(voiceProperties);
        fillVoiceProps(updatedVoiceProps);

        const updatedPosProps = getValidKeyValuePair(posProperties);

        menuItem["voiceProperties"] =
            updatedVoiceProps?.length > 0 ? updatedVoiceProps : [];

        menuItem["posProperties"] =
            updatedPosProps?.length > 0 ? updatedPosProps : [];

        if (menuItemId) {
            props.updateMenuItem({
                ...menuItem,
                id: menuItemId,
            } as any);
        } else {
            props.createMenuItem(menuItem as any);
        }
        setBlockNavigation(false);
    };

    const handleGoBack = (formIsDirty: boolean, data: FormValue) => () => {
        if (formIsDirty) {
            setShowBackConfirmModal(true);
        } else {
            if (!compareExtraValues(initialExtraValues, data)) {
                setShowBackConfirmModal(true);
            } else pushToHistory(`/${restaurantCode}/menu-editor/items`);
        }
    };

    const checkModifierListChanged = () => {
        const newIdList = modifierGroupList.map((mg) => mg.id).join("");
        return !(newIdList === originModifierIdList);
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (String(event.currentTarget.getAttribute("aria-controls"))) {
            setAnchorEls({
                [String(
                    event.currentTarget.getAttribute("aria-controls")
                )]: event.currentTarget,
            });
        }
    };

    const handleMenuClose = () => {
        setAnchorEls({});
    };

    const renderModality = (
        name: keyof ExtraValues,
        title: string,
        values: FormValue
    ) => {
        const { dineIn, delivery, toGo, ...restValues } = values;
        const value = values[name] as ModalityObject;
        const extraFormValues = { dineIn, toGo, delivery };

        return (
            <Grid container item>
                <Grid item xs={6}>
                    <ReadOnlyWrapper
                        element={Checkboxes}
                        color="primary"
                        name={`${name}.enabled`}
                        data={{ label: title, value: true }}
                    />
                </Grid>
                <Grid item xs={3} className={classes.priceInputWrapper}>
                    <div>Price ($) *</div>
                    <Tooltip
                        title="Override the default price here"
                        aria-label="Override the default price here"
                    >
                        <ReadOnlyWrapper
                            element={NumericInput}
                            className={`hide-input-arrows ${
                                classes.priceInput
                            }${
                                value.priceOverridden(
                                    value.price,
                                    values.dineIn.price
                                )
                                    ? " override"
                                    : ""
                            }`}
                            value={value.price ? value.price.toString() : "0"}
                            prefixText={formatCurrency(currency)}
                            handleUpdate={(newPrice: string) => {
                                if (name === "dineIn") {
                                    setExtraValues({
                                        dineIn: {
                                            ...values.dineIn,
                                            price: newPrice,
                                        },
                                        delivery: {
                                            ...values.delivery,
                                            price: values.delivery.priceOverridden(
                                                values.delivery.price,
                                                values.dineIn.price
                                            )
                                                ? values.delivery.price
                                                : newPrice,
                                        },
                                        toGo: {
                                            ...values.toGo,
                                            price: values.toGo.priceOverridden(
                                                values.toGo.price,
                                                values.dineIn.price
                                            )
                                                ? values.toGo.price
                                                : newPrice,
                                        },
                                        ...restValues,
                                    });
                                } else {
                                    setExtraValues({
                                        ...extraFormValues,
                                        [name]: {
                                            ...values[name],
                                            price: newPrice,
                                        },
                                        ...restValues,
                                    });
                                }
                            }}
                            InputProps={{
                                inputProps: {
                                    step: 0.01,
                                },
                                disableUnderline: true,
                            }}
                            disabled={!value.enabled}
                        />
                    </Tooltip>
                    <IconButton
                        style={{
                            width: "40px",
                            padding: 0,
                            visibility:
                                name === "dineIn" ? "hidden" : "visible",
                        }}
                        onClick={handleMenuClick}
                        aria-controls={`price-menu-${name}`}
                    >
                        <MoreVertIcon style={{ width: "40px" }} />
                    </IconButton>
                    <Menu
                        id={`price-menu-${name}`}
                        anchorEl={anchorEls[`price-menu-${name}`]}
                        open={Boolean(anchorEls[`price-menu-${name}`])}
                        onClose={handleMenuClose}
                    >
                        <ReadOnlyWrapper
                            element={MenuItem}
                            disabled={Boolean(
                                name === "dineIn" ||
                                    !value.priceOverridden(
                                        value.price,
                                        values.dineIn.price
                                    )
                            )}
                            onClick={() => {
                                setExtraValues({
                                    ...extraFormValues,
                                    [name]: {
                                        ...value,
                                        price: values.dineIn.price,
                                    },
                                    ...restValues,
                                });
                                handleMenuClose();
                            }}
                        >
                            Use Default Price
                        </ReadOnlyWrapper>
                    </Menu>
                </Grid>
                <Grid item xs={3} className={classes.priceInputWrapper}>
                    <div>Tax (%) *</div>
                    <Tooltip
                        title="Override the default tax here"
                        aria-label="Override the default tax here"
                    >
                        <ReadOnlyWrapper
                            element={NumericInput}
                            className={`${classes.priceInput}${
                                value.taxOverridden(value.tax)
                                    ? " override"
                                    : ""
                            }`}
                            value={value.tax ? value.tax.toString() : "0"}
                            handleUpdate={(newTax: string) => {
                                setExtraValues({
                                    ...extraFormValues,
                                    [name]: {
                                        ...values[name],
                                        tax: newTax,
                                    },
                                    ...restValues,
                                });
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        %
                                    </InputAdornment>
                                ),
                                inputProps: {
                                    step: 0.01,
                                },
                                disableUnderline: true,
                            }}
                            disabled={!value.enabled}
                        />
                    </Tooltip>

                    <IconButton
                        style={{
                            width: "40px",
                            padding: 0,
                            visibility:
                                name === "dineIn" ? "hidden" : "visible",
                        }}
                        onClick={handleMenuClick}
                        aria-controls={`tax-menu-${name}`}
                    >
                        <MoreVertIcon style={{ width: "40px" }} />
                    </IconButton>
                    <Menu
                        id={`tax-menu-${name}`}
                        anchorEl={anchorEls[`tax-menu-${name}`]}
                        open={Boolean(anchorEls[`tax-menu-${name}`])}
                        onClose={handleMenuClose}
                    >
                        <ReadOnlyWrapper
                            element={MenuItem}
                            disabled={!value.taxOverridden(value.tax)}
                            onClick={() => {
                                setExtraValues({
                                    dineIn,
                                    delivery,
                                    toGo,
                                    [name]: {
                                        ...value,
                                        tax: Number(value.defaultTax),
                                    },
                                    ...restValues,
                                });
                                handleMenuClose();
                            }}
                        >
                            Use Default Tax
                        </ReadOnlyWrapper>
                    </Menu>
                </Grid>
            </Grid>
        );
    };

    return (
        <React.Fragment>
            <Prompt
                when={blockNavigation}
                message={(location, action) => {
                    if (action === "POP") {
                        return "The changes you've made will be lost. Are you sure you want to discard the changes?";
                    }

                    return true;
                }}
            />
            {showDeleteConfirmModal && (
                <ConfirmDialog
                    content="Are you sure to delete this menu item?"
                    open={showDeleteConfirmModal}
                    onSuccess={() => {
                        props.deleteMenuItem({
                            id: parseFloat(id),
                            name: initialValues?.name || "",
                            successCallback: () => {
                                alert.success("Menu Item Deleted", {
                                    timeout: TIME_OUT,
                                });
                                pushToHistory(
                                    `/${restaurantCode}/menu-editor/items`
                                );
                            },
                            errorCallback: () => {
                                alert.error("Error Deleting Menu Item", {
                                    timeout: TIME_OUT,
                                });
                            },
                        });
                    }}
                    onCancel={() => setShowDeleteConfirmModal(false)}
                />
            )}
            {showBackConfirmModal && (
                <ConfirmDialog
                    title="Leave without saving?"
                    content="The changes you've made will be lost. Are you sure you want to discard the changes?"
                    open={showBackConfirmModal}
                    onSuccess={() => {
                        pushToHistory(`/${restaurantCode}/menu-editor/items`);
                    }}
                    onCancel={() => setShowBackConfirmModal(false)}
                />
            )}
            {showModifierOnlyModal && (
                <ConfirmDialog
                    content="By selecting Is Modifier Only, you will remove all categories from this item. Are you sure you would like to remove this item from categories?"
                    confirmText="Yes, remove categories"
                    open={showModifierOnlyModal}
                    onSuccess={() => {
                        updateParentCategorySelections([]);
                        setShowModifierOnlyModal(false);
                        setIsModifierOnly("true");
                    }}
                    onCancel={() => {
                        setIsModifierOnly("false");
                        setShowModifierOnlyModal(false);
                    }}
                />
            )}
            <Form<FormValue>
                onSubmit={onSubmit}
                // @ts-ignore
                initialValues={{
                    ...initialValues,
                    ...extraValues,
                }}
                validate={validate}
                render={({ handleSubmit, values, valid, dirty, form }) => (
                    <form
                        onSubmit={handleSubmit}
                        noValidate
                        className={classes.form}
                    >
                        <FormSpy
                            onChange={(props) => {
                                setBlockNavigation(
                                    props.dirty ||
                                        checkModifierListChanged() ||
                                        !compareExtraValues(
                                            initialExtraValues,
                                            props.values as any
                                        )
                                );
                            }}
                        />

                        <ActionItems
                            handleRemove={handleRemoveItem}
                            handleGoBack={handleGoBack(
                                dirty || checkModifierListChanged(),
                                values
                            )}
                            handleDuplicate={() => {
                                props.duplicateMenuItem(
                                    generateDuplicatePayload(
                                        initialValues?.id,
                                        ComponentType.menuItem
                                    )
                                );
                            }}
                            disableSave={!valid || uploadedResult.loading}
                            id={id}
                            menuType={MenuType.menuItem}
                        />
                        <ReadOnlyWrapper
                            element={TextField}
                            {...formTextFields("displayName")}
                            disabled={id !== "new" && !modify}
                        />
                        <ImageUpload
                            isImageUploading={uploadedResult.loading}
                            header="Picture"
                            title="Add pictures to help your guests order their food."
                            imageUrl={imageUrl}
                            uploadImage={handleUploadMenuItemImage}
                            deleteOriginalImage={() => setImageUrl(null)}
                            setImageLoadingStatus={setImageLoadingStatus}
                        />
                        <ReadOnlyWrapper
                            element={TextField}
                            {...formTextFields("description")}
                        />
                        <Grid container className={classes.modalityWrapper}>
                            <Box boxShadow={1}>
                                {renderModality("dineIn", "Dine In", values)}
                                {renderModality("toGo", "To-go", values)}
                                {renderModality("delivery", "Delivery", values)}
                            </Box>
                        </Grid>
                        <NewInputProperties
                            inputProps={posProperties}
                            setInputProps={setPosProperties}
                            isInputValue
                            heading="Set POS Properties"
                        />
                        <NewInputProperties
                            inputProps={voiceProperties}
                            setInputProps={setVoiceProperties}
                            heading="Set Voice Properties"
                        />
                        <NewInputProperties
                            inputProps={synonymProperties}
                            setInputProps={setSynonymProperties}
                            heading="Set Synonyms/Aliases"
                            hideKeyField
                            isInputValue
                        />
                        <div className={classes.checkbox}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">
                                    Is this a modifier only?
                                </FormLabel>
                                <RadioGroup
                                    name="isModifierOnly"
                                    value={isModifierOnly}
                                    onChange={(e) => {
                                        setIsModifierOnly(e.target.value);
                                        if (
                                            e.target.value === "true" &&
                                            parentCategorySelections.length > 0
                                        ) {
                                            setShowModifierOnlyModal(true);
                                        }
                                    }}
                                    row
                                >
                                    <FormControlLabel
                                        value="true"
                                        control={
                                            <ReadOnlyWrapper
                                                element={Radio}
                                                color="primary"
                                            />
                                        }
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="false"
                                        control={
                                            <ReadOnlyWrapper
                                                element={Radio}
                                                color="primary"
                                            />
                                        }
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className={classes.checkbox}>
                            <Typography
                                gutterBottom
                                style={{
                                    color: "#9e9e9e",
                                }}
                            >
                                Set item availability
                            </Typography>
                            <div
                                style={{
                                    marginBottom: "20px",
                                    marginLeft: "20px",
                                }}
                            >
                                <ReadOnlyWrapper
                                    element={Radios}
                                    name="availabilityType"
                                    required
                                    defaultValue={"available"}
                                    color="primary"
                                    data={[
                                        {
                                            label: "Available",
                                            value: "available",
                                        },
                                        {
                                            label:
                                                "Sold out today, available tomorrow",
                                            value: "unavailable_today",
                                        },
                                        {
                                            label: "Sold out indefinitely",
                                            value: "unavailable_indefinitely",
                                        },
                                        {
                                            label: (
                                                <div
                                                    className={
                                                        classes.limitedQuantityOption
                                                    }
                                                >
                                                    Limited quantity
                                                    <ReadOnlyWrapper
                                                        element={TextField}
                                                        className="custom-input-gray custom-input-gray-numeric"
                                                        type="number"
                                                        name="availableLimitedQuantity"
                                                        disabled={
                                                            values.availabilityType !==
                                                            "limited_available"
                                                        }
                                                        InputProps={{
                                                            inputProps: {
                                                                step: 1,
                                                                min: 1,
                                                            },
                                                            disableUnderline: true,
                                                        }}
                                                    />
                                                </div>
                                            ),
                                            value: "limited_available",
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                        <Typography
                            gutterBottom
                            style={{
                                color:
                                    isModifierOnly === "true"
                                        ? "rgba(0, 0, 0, 0.38)"
                                        : "#9e9e9e",
                            }}
                        >
                            Where can you find this item?
                        </Typography>
                        <ReadOnlyWrapper
                            element={Autocomplete}
                            multiple
                            autoSelect
                            autoHighlight
                            id="parentCategories"
                            name="categories"
                            label="Add Category"
                            options={categories}
                            getOptionLabel={(option: any) => {
                                const optionTimes = option.timePeriods.map(
                                    (id: string) => formattedTimePeriodsMap[id]
                                );

                                let optionTimeStr = "";
                                if (optionTimes.length > 0) {
                                    optionTimes.find(
                                        (optionTime: any) =>
                                            optionTime.availableDays.length
                                    );
                                    optionTimeStr =
                                        "(" +
                                        optionTimes[0].availableDays?.join(
                                            " - "
                                        ) +
                                        ")";
                                } else if (optionTimes.length > 1)
                                    optionTimeStr = `(${optionTimes[0].availableDays?.join(
                                        " - "
                                    )}) +${optionTimes.length - 1}`;
                                return `${option.name}  ${optionTimeStr}`;
                            }}
                            getOptionValue={(option: any) => option}
                            value={parentCategorySelections}
                            onChange={(
                                event: ChangeEvent<HTMLAllCollection>,
                                value: Category[]
                            ) => updateParentCategorySelections(value)}
                            renderTags={(
                                value: any,
                                getTagProps: (a1: any) => {}
                            ) => {
                                return value.map(
                                    (option: any, index: number) => (
                                        <Chip
                                            variant="default"
                                            label={option.name}
                                            {...getTagProps({ index })}
                                        />
                                    )
                                );
                            }}
                            style={{
                                marginBottom: "40px",
                            }}
                            disabled={isModifierOnly === "true"}
                        />
                        <Typography variant="h6" color="primary" gutterBottom>
                            Modifiers
                        </Typography>
                        <Typography>
                            Add customization options like sides, sizes,
                            temperature, etc
                        </Typography>
                        <Grid
                            container
                            alignItems="center"
                            className={classes.itemsContainer}
                        >
                            <ReadOnlyWrapper
                                element={Autocomplete}
                                autoSelect
                                autoHighlight
                                id="selectedModifierGroup"
                                name="selectedModifierGroup"
                                label="Add a modifier group to item"
                                options={modifyGroups}
                                getOptionLabel={(option: any) => {
                                    if (
                                        Object.keys(option.childMenuItems)
                                            .length > 0
                                    ) {
                                        return `${
                                            option.name
                                        } (${renderTableCollectionText(
                                            option.childMenuItems,
                                            3
                                        )})`;
                                    }
                                    return option.name;
                                }}
                                getOptionValue={(option: any) => option}
                                renderTags={(
                                    value: any,
                                    getTagProps: (a1: any) => {}
                                ) => {
                                    return value.map(
                                        (option: any, index: number) => (
                                            <Chip
                                                variant="default"
                                                label={option.name}
                                                {...getTagProps({ index })}
                                            />
                                        )
                                    );
                                }}
                                style={{
                                    marginRight: "20px",
                                    width: "300px",
                                }}
                            />
                            <ReadOnlyWrapper
                                element={Button}
                                disableElevation
                                color="secondary"
                                variant="contained"
                                disabled={!values.selectedModifierGroup}
                                onClick={() => {
                                    const index = modifierGroupList.findIndex(
                                        (modifierGroup) =>
                                            modifierGroup.id ===
                                            values.selectedModifierGroup.id
                                    );
                                    if (index === -1) {
                                        setModifierGroupList([
                                            ...modifierGroupList,
                                            values.selectedModifierGroup,
                                        ]);
                                    }
                                }}
                            >
                                Add modifier group
                            </ReadOnlyWrapper>
                        </Grid>
                        <DragModifierGroup
                            itemList={modifierGroupList}
                            setItemList={setModifierGroupList}
                            removeItem={handleRemoveFromModifierGroupList}
                        />
                    </form>
                )}
            />
        </React.Fragment>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        restaurantCode: state.restaurant.selectedRestaurant?.restaurantCode,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        createMenuItem: (data: Omit<MenuItemType, "restaurantCode" | "id">) =>
            dispatch(createMenuItem(data)),
        updateMenuItem: (data: Omit<MenuItemType, "restaurantCode">) =>
            dispatch(updateMenuItem(data)),
        deleteMenuItem: (data: DeleteItemType) =>
            dispatch(deleteMenuItem(data)),
        duplicateMenuItem: (data: IDuplicatePayload) =>
            dispatch(duplicateMenuItem(data)),
        getVoiceProperties: (data: GetIngestedDataType) =>
            dispatch(getVoiceProperties(data)),
    };
};

type Props = ConnectedProps<typeof connected>;

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(NewMenuItem);
