import Axios from "axios";
import dayjs from "dayjs";
import { SOURCE } from "../constants";
import {
    EventsAcknowledgementPayload,
    GetEventsPayload,
    UpdatesResponse,
} from "../types/events";
import { UserSession } from "../types/menu";
import {
    createMenuVersionType,
    IPromoteMenuVersionCallType,
    IRestoreDBCallType,
    MenuStages,
} from "../types/menuVersion";
import { Order, OrderCheckStatus, OrdersResponse } from "../types/order";
import { RestaurantInfo } from "../types/restaurant";
import { createUuid, readEnvVariable } from "./helper-functions";
import { getAuthToken, getSessionID } from "./local-storage";
import { GetIngestedDataType, StringOrNull } from "./types";

const webserviceAxiosInstance = Axios.create({
    baseURL: readEnvVariable("WEBSERVICE_API"),
    headers: {
        "Content-Type": "application/json",
    },
});

export const fetchNewOrdersCall = async (restaurantCode: string) => {
    return webserviceAxiosInstance.get<OrdersResponse>(
        `/get-open-checks/${restaurantCode}`,
        {
            params: {
                requestId: createUuid(),
            },
            headers: {
                Authorization: getAuthToken(),
            },
        }
    );
};

export const fetchPastOrdersCall = async (restaurantCode: string) => {
    return webserviceAxiosInstance.get<OrdersResponse>(
        `/get-checks/${restaurantCode}`,
        {
            params: {
                requestId: createUuid(),
            },
            headers: {
                Authorization: getAuthToken(),
            },
        }
    );
};

export const bumpOrderCall = async (restaurantCode: string, order: Order) => {
    return webserviceAxiosInstance.post<OrdersResponse>(
        `/update-check-status/${restaurantCode}`,
        {
            requestId: createUuid(),
            checkIds_to_check_status: [
                {
                    checkId: order.posId,
                    check_status: OrderCheckStatus.POS_ENTERED,
                    last_updated: dayjs().format("YYYY-MM-DD HH:mm:ss.SSSZ"),
                },
            ],
        },
        {
            headers: {
                Authorization: getAuthToken(),
            },
        }
    );
};

export const closeOrderCall = async (restaurantCode: string, order: Order) => {
    return webserviceAxiosInstance.post<OrdersResponse>(
        `/close-tab/${restaurantCode}`,
        {
            requestId: createUuid(),
            sessionId: order.sessionId,
            tableNumber: order.tableId,
        },
        {
            headers: {
                Authorization: getAuthToken(),
            },
        }
    );
};

export const closeOrderFailurePaymentCall = async (
    restaurantCode: string,
    order: Order,
    paymentMode: "CASH" | "CARD" | "OTHER"
) => {
    return webserviceAxiosInstance.post<OrdersResponse>(
        `/close-tab-failure-payment/${restaurantCode}`,
        {
            requestId: createUuid(),
            sessionId: order.sessionId,
            tableNumber: order.tableId,
            paymentMode,
        },
        {
            headers: {
                Authorization: getAuthToken(),
            },
        }
    );
};

export const restaurantInfoCall = async (restaurantCode: string) => {
    return webserviceAxiosInstance.get<RestaurantInfo>(
        `/restaurant-info/PREVIEW_${restaurantCode}`,
        {
            params: {
                requestId: createUuid(),
            },
            headers: {
                Authorization: getAuthToken(),
            },
        }
    );
};

export const getEventsCall = async (
    payload: Omit<GetEventsPayload, "requestId">
) => {
    return webserviceAxiosInstance.get<UpdatesResponse>(
        `/alert/${payload.restaurantCode}`,
        {
            params: {
                alertSince: payload.alertSince,
                acknowledged: payload.acknowledged,
                eventType: payload.eventType,
                tableNumber: payload.tableNumber,
            },
            headers: {
                Authorization: getAuthToken(),
            },
        }
    );
};

export const eventsAcknowledgementCall = async (
    payload: Omit<EventsAcknowledgementPayload, "requestId">
) => {
    return webserviceAxiosInstance.post(
        `/alert-acknowledgement/${payload.restaurantCode}`,
        {
            acknowledgements: payload.acknowledgements,
        },
        {
            headers: {
                Authorization: getAuthToken(),
            },
        }
    );
};

//PRP internal db calls

export const getPRPIntCall = async (
    payload: Partial<GetIngestedDataType> | undefined,
    restaurantCode: string
): Promise<{
    data?: GetIngestedDataType;
}> => {
    return webserviceAxiosInstance.get(
        `${readEnvVariable("PRP_PERSISTENT_API")}/${restaurantCode}`,
        {
            params: { ...payload },
            headers: {
                Authorization: getAuthToken(),
            },
        }
    );
};

export const createPRPIntCall = (payload: any, restaurantCode: string) => {
    return webserviceAxiosInstance.post(
        `${readEnvVariable("PRP_PERSISTENT_API")}/${restaurantCode}`,
        payload,
        {
            headers: {
                Authorization: getAuthToken(),
            },
        }
    );
};

export const updatePRPIntCall = (payload: any, restaurantCode: string) => {
    return webserviceAxiosInstance.put(
        `${readEnvVariable("PRP_PERSISTENT_API")}/${restaurantCode}`,
        payload,
        {
            headers: {
                Authorization: getAuthToken(),
            },
        }
    );
};

export const deletePRPIntCall = (payload: any, restaurantCode: string) => {
    return webserviceAxiosInstance.delete(
        `${readEnvVariable("PRP_PERSISTENT_API")}/${restaurantCode}`,
        {
            data: payload,
            headers: {
                Authorization: getAuthToken(),
            },
        }
    );
};

export const CreateMenuVersionCall = async ({
    comment,
    restaurantCode: restaurant_code,
    user,
    stage,
}: createMenuVersionType) =>
    webserviceAxiosInstance.post(
        `${readEnvVariable("MENU_API")}/${stage}/menu/commit`,
        {
            restaurant_code,
            request_id: createUuid(),
            user,
            comment,
        },
        {
            headers: {
                Authorization: getAuthToken(),
            },
        }
    );
export const promoteMenuVersionCall = async ({
    restaurantCode: restaurant_code,
    commitId: commit_id,
    stage,
    user,
}: {
    restaurantCode: StringOrNull;
} & IPromoteMenuVersionCallType) =>
    webserviceAxiosInstance.post(
        `${readEnvVariable("MENU_API")}/${stage?.toLowerCase()}/menu/promote`,
        {
            restaurant_code,
            commit_id,
            request_id: createUuid(),
            user,
        },
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: getAuthToken(),
            },
        }
    );

export const createUserSession = async (request: UserSession) => {
    return webserviceAxiosInstance.post(
        readEnvVariable("FORCE_LOGOUT_API"),
        request,
        {
            headers: {
                Authorization: getAuthToken(),
            },
        }
    );
};

export const updateUserSession = async (
    sessionid: string,
    request: UserSession
) => {
    return webserviceAxiosInstance.put(
        `${readEnvVariable("FORCE_LOGOUT_API")}/${sessionid}`,
        request,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: getAuthToken(),
            },
        }
    );
};

export const getUserSessions = async (restaurantCodes: string) => {
    return webserviceAxiosInstance.get(
        `${readEnvVariable(
            "FORCE_LOGOUT_API"
        )}?source_module=${SOURCE}&restaurant_code=${restaurantCodes}&force_reload=0&active=1`,
        {
            headers: {
                Authorization: getAuthToken(),
            },
        }
    );
};

export const getCurrentUserSessions = async (restaurantCodes: string) => {
    const sessionId = getSessionID();
    return webserviceAxiosInstance.get(
        `${readEnvVariable(
            "FORCE_LOGOUT_API"
        )}?source_module=${SOURCE}&restaurant_code=${restaurantCodes}&active=1&user_session_id=${sessionId}`,
        {
            headers: {
                Authorization: getAuthToken(),
            },
        }
    );
};

export const deleteUserSession = async (sessionid: string) => {
    return webserviceAxiosInstance.delete(
        `${readEnvVariable("FORCE_LOGOUT_API")}/${sessionid}`,
        {
            headers: {
                Authorization: getAuthToken(),
            },
        }
    );
};

export const fetchMenuVersionRequestStatusCall = async ({
    restaurantCode: restaurant_code,
    stage,
}: {
    restaurantCode: StringOrNull;
    stage: string;
}) =>
    webserviceAxiosInstance.get(
        `${readEnvVariable("MENU_API")}/${stage}/request-status`,
        {
            params: { restaurant_code },
            headers: {
                Authorization: getAuthToken(),
            },
        }
    );

export const fetchMenuVersionPreSignedUrlCall = async ({
    restaurantCode: restaurant_code,
    commitId: commit_id,
    stage,
}: {
    restaurantCode: StringOrNull;
    commitId?: string;
    stage: string;
}) =>
    webserviceAxiosInstance.get(
        `${readEnvVariable("MENU_API")}/${stage}/menu`,
        {
            params: { restaurant_code, commit_id },
            headers: {
                Authorization: getAuthToken(),
            },
        }
    );

export const fetchCommittedMenuVersionCall = async ({
    menuUrl,
}: {
    menuUrl: string;
}) => webserviceAxiosInstance.get(menuUrl);

export const fetchMenuVersionActivityCall = async ({
    restaurantCode: restaurant_code,
    stage,
}: {
    restaurantCode: StringOrNull;
    stage: string;
}) =>
    webserviceAxiosInstance.get(
        `${readEnvVariable("MENU_API")}/${stage}/menu-commit-stage-activity`,
        {
            params: { restaurant_code },
            headers: {
                Authorization: getAuthToken(),
            },
        }
    );
export const restoreDBCall = async ({
    restaurantCode: restaurant_code,
    commitId: commit_id,
    stage,
    user,
}: {
    restaurantCode: StringOrNull;
} & IRestoreDBCallType) =>
    webserviceAxiosInstance.post(
        `${readEnvVariable("MENU_API")}/${stage?.toLowerCase()}/reset-db`,
        {
            restaurant_code,
            commit_id,
            request_id: createUuid(),
            user,
        },
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: getAuthToken(),
            },
        }
    );
export const fetchRestoreDBStatusCall = async ({
    restaurantCode: restaurant_code,
    stage,
}: {
    restaurantCode: StringOrNull;
    stage: string;
}) =>
    webserviceAxiosInstance.get(
        `${readEnvVariable("MENU_API")}/${stage}/reset-db/incomplete`,
        {
            params: { restaurant_code },
            headers: {
                Authorization: getAuthToken(),
            },
        }
    );
export const fetchMenuCommitStageCall = async ({
    restaurantCode: restaurant_code,
    stage,
}: {
    restaurantCode: StringOrNull;
    stage: string;
}) =>
    webserviceAxiosInstance.get(
        `${readEnvVariable("MENU_API")}/${stage}/menu-commit-stage`,
        {
            params: { restaurant_code },
            headers: {
                Authorization: getAuthToken(),
            },
        }
    );
export const fetchActiveMenuCommitStageCall = async ({
    restaurantCode: restaurant_code,
    stage,
}: {
    restaurantCode: StringOrNull;
    stage: string;
}) =>
    webserviceAxiosInstance.get(
        `${readEnvVariable("MENU_API")}/${stage}/menu/commit/active`,
        {
            params: { restaurant_code },
            headers: {
                Authorization: getAuthToken(),
            },
        }
    );
export const fetchLatestVersionOnDBCall = async ({
    restaurantCode: restaurant_code,
}: {
    restaurantCode: StringOrNull;
}) =>
    webserviceAxiosInstance.get(
        `${readEnvVariable(
            "MENU_API"
        )}/${MenuStages.PLAYGROUND.toLowerCase()}/menu/commit/latest-on-db`,
        {
            params: { restaurant_code },
            headers: {
                Authorization: getAuthToken(),
            },
        }
    );
