import { MaterialTableProps } from "material-table";
import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomChip from "../../components/menuVersion/CustomChip";
import { fetchActiveMenuCommitStage } from "../../reducers/menuReducer";
import { selectStagesOverview } from "../../selectors/menu";
import { selectedRestaurantCodeSelector } from "../../selectors/restaurant";
import { IMenuHistory } from "../../types/menuVersion";
import { BannerColorMapping, DATE_FORMATS } from "../../utils/constants";
import { CustomizedMaterialTable } from "../../utils/data-tables";
import { formatDate } from "../../utils/helper-functions";

const MenuStagesOverview = () => {
    const stagesOverview = useSelector(selectStagesOverview);
    const dispatch = useDispatch();
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);

    useEffect(() => {
        if (restaurantCode) {
            dispatch(fetchActiveMenuCommitStage());
        }
    }, [dispatch, restaurantCode]);

    const menuHistoryActiveTableConfig: MaterialTableProps<IMenuHistory> = {
        columns: [
            {
                title: "Stage",
                field: "stage",
                render: ({ stage }) => (
                    <CustomChip
                        label={stage}
                        customColor={
                            BannerColorMapping[stage?.toUpperCase() || ""]
                        }
                    />
                ),
            },
            {
                title: "Menu version",
                field: "commitId",
            },
            {
                title: "Menu title",
                field: "comment",
            },
            {
                title: "Published by",
                field: "publisherName",
            },
            {
                title: "Published on",
                field: "updatedAt",
                render: ({ updatedAt }) =>
                    formatDate(updatedAt, DATE_FORMATS.dateAndTime),
            },
            {
                title: "Created on",
                field: "createdAt",
                render: ({ createdAt }) =>
                    formatDate(createdAt, DATE_FORMATS.dateAndTime),
            },
            {
                title: "Created by",
                field: "creatorName",
            },
        ],
        data: stagesOverview.map((o) => ({ ...o })), // Shallow copy objects b/c they are immutable
        options: { toolbar: false, paging: false },
    };

    return (
        <div>
            <CustomizedMaterialTable {...menuHistoryActiveTableConfig} />
        </div>
    );
};
export default memo(MenuStagesOverview);
