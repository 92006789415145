import { IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { memo, MouseEvent, useCallback, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import CommitDialog from "../../components/menuVersion/CommitDialog";
import { useCommitDialog, useCustomHistory } from "../../hooks";
import {
    MENU_ACTIONS,
    promoteMenuVersion,
    restoreDB,
} from "../../reducers/menuReducer";
import { selectedRestaurantCodeSelector } from "../../selectors/restaurant";
import { IMenuHistory, MenuStages } from "../../types/menuVersion";
import { TIME_OUT } from "../../utils/constants";
import { readEnvVariable } from "../../utils/helper-functions";
import { MENU_EDITOR_ROUTES } from "../menu-editor/menu-editor-routes";

const MenuHistoryItemRowActions = ({ data }: { data: IMenuHistory }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const { commitId, stage, isActive } = data;
    const dispatch = useDispatch();
    const alert = useAlert();
    const currentStage = readEnvVariable("MENU_VERSION_STAGE").toUpperCase();
    const upperCaseStage = stage?.toUpperCase();
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);

    const handleMenuClick = useCallback(
        (event: MouseEvent<HTMLButtonElement>) =>
            setAnchorEl(event.currentTarget),
        []
    );

    const handleMenuClose = useCallback(() => setAnchorEl(null), []);

    const menuEditorRoutes = MENU_EDITOR_ROUTES(restaurantCode || "");
    const { open, setIsDialogDisabled, toggle } = useCommitDialog();
    const { pushToHistory } = useCustomHistory();

    const onCommitSuccess = useCallback(() => {
        dispatch(MENU_ACTIONS.setIsLoadingEntity(true));
        setTimeout(() => {
            dispatch(
                restoreDB({
                    commitId,
                    successCallback: () => {
                        alert.success(<div>Menu restoration in progress</div>, {
                            timeout: TIME_OUT,
                        });
                        pushToHistory(Object.values(menuEditorRoutes)[0].path);
                    },
                    errorCallback: (error: any) => {
                        alert.error(
                            <div>
                                <span>Error restoring menu version</span>
                                <div>{error?.message}</div>
                            </div>,
                            {
                                timeout: TIME_OUT,
                            }
                        );
                    },
                })
            );
        }, TIME_OUT);
    }, [alert, commitId, dispatch, menuEditorRoutes, pushToHistory]);

    const handlePromote = useCallback(() => {
        handleMenuClose();
        dispatch(
            promoteMenuVersion({
                commitId,
                successCallback: () => {
                    alert.success(
                        <div>Menu version is successfully promoted</div>,
                        {
                            timeout: TIME_OUT,
                        }
                    );
                },
                errorCallback: (error: any) => {
                    alert.error(
                        <div>
                            <span>Error promoting menu version</span>
                            <div>{error?.message}</div>
                        </div>,
                        {
                            timeout: TIME_OUT,
                        }
                    );
                },
            })
        );
    }, [dispatch, alert, commitId, handleMenuClose]);

    const handleView = useCallback(() => {
        handleMenuClose();
        pushToHistory(
            Object.values(menuEditorRoutes)[0].path,
            `?version=${commitId}`
        );
    }, [commitId, handleMenuClose, pushToHistory, menuEditorRoutes]);

    const handleRestore = useCallback(() => {
        handleMenuClose();
        toggle();
    }, [handleMenuClose, toggle]);

    return (
        data && (
            <div>
                <>
                    <IconButton
                        onClick={handleMenuClick}
                        aria-controls={`more-options-${data.id}`}
                    >
                        <Tooltip title="More options" aria-label="More options">
                            <MoreVertIcon />
                        </Tooltip>
                    </IconButton>
                    <CommitDialog
                        toggle={toggle}
                        open={open}
                        setIsDialogDisabled={setIsDialogDisabled}
                        onCommitSuccess={onCommitSuccess}
                    />
                    <Menu
                        id={`more-options-${data.id}`}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        {upperCaseStage === currentStage &&
                            upperCaseStage !== MenuStages.LIVE && (
                                <MenuItem onClick={handlePromote}>
                                    Promote
                                </MenuItem>
                            )}

                        {(currentStage === MenuStages.PLAYGROUND ||
                            !isActive) && (
                            <MenuItem onClick={handleView}>View</MenuItem>
                        )}
                        {upperCaseStage === MenuStages.PLAYGROUND && (
                            <MenuItem onClick={handleRestore}>Restore</MenuItem>
                        )}
                    </Menu>
                </>
            </div>
        )
    );
};

export default memo(MenuHistoryItemRowActions);
