import { PayloadAction } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import { SOURCE } from "../constants";
import {
    ChangePaswordInput,
    CompletePasswordResetMutationVariables,
    LoginInput,
} from "../generated-interfaces/graphql";
import { ERROR_ACTIONS } from "../reducers/errorReducer";
import { MENU_ACTIONS } from "../reducers/menuReducer";
import {
    getRestaurantRolesAction,
    RESTAURANT_ACTIONS,
} from "../reducers/restaurantReducer";
import { RootState } from "../reducers/rootReducer";
import {
    changePassword,
    checkAuthStatusAction,
    completePasswordReset,
    createUserSessionAction,
    deleteUserSessionAction,
    forceLogOutAction,
    loadUserState,
    loginAction,
    mfLoginAction,
    retrieveUserSessionAction,
    startPasswordreset,
    updateHeartBeatAction,
    updateUserSessionAction,
    USER_ACTIONS,
    verifyGAuthTokenAction,
} from "../reducers/userReducer";
import { selectedRestaurantCodeSelector } from "../selectors/restaurant";
import { currentUserSelector } from "../selectors/user";
import { Android } from "../types/android";
import { IActiveUserReqType, UserSession } from "../types/menu";
import {
    getAuthToken,
    getGoogleId,
    getSessionID,
    saveGoogleId,
    saveSessionID,
} from "../utils/local-storage";
import logger from "../utils/logger";
import { getSdk, setAuthToken } from "../utils/network";
import { IRequestType } from "../utils/types";
import {
    createUserSession,
    deleteUserSession,
    getUserSessions,
    updateUserSession,
} from "../utils/webserviceAPI";

function* loadSavedUserState() {
    logger.debug("Attempting to load previous user state");
    const authToken = getAuthToken();
    if (authToken) {
        setAuthToken(authToken);
        const sdk = getSdk();
        try {
            const response = yield sdk.authStatus();
            yield put(USER_ACTIONS.loginSuccess(response.authStatus));
            yield getRestaurantAccess();
        } catch (error) {
            logger.error("Invalid saved auth token", error);
        }
    }
    yield put(USER_ACTIONS.loadUserStateSuccess());
}

function* getRestaurantAccess() {
    logger.debug("Attempting to get restaurantsByUserRole");
    const isLoggedIn = yield select(
        (state: RootState) => state.user.isLoggedIn
    );
    if (!isLoggedIn) {
        logger.info("Not Logged In For getRestaurantAccess");
        return;
    }
    const sdk = getSdk();
    try {
        const {
            restaurantsByUserRole,
        } = yield sdk.restaurantsByUserRoleQuery();
        yield put(
            RESTAURANT_ACTIONS.userRolesLoadSuccess(restaurantsByUserRole)
        );
        yield put(MENU_ACTIONS.updateDidInitialLoad(false));
    } catch (error) {
        logger.error("Failed Getting Restaurant Access", error);
        yield put(RESTAURANT_ACTIONS.userRolesLoadFailure());
    }
}

function* login(action: PayloadAction<LoginInput>) {
    logger.debug("Attempting to log the user in");
    let sdk = getSdk();
    const { email, password } = action.payload;
    try {
        const { login } = yield sdk.loginQuery({
            email: email.trim(),
            password,
        });
        yield put(USER_ACTIONS.loginSuccess(login));
        yield put(createUserSessionAction());
        sdk = getSdk(); // Get new SDK with user token attached
        logger.debug("Attempting to get restaurantsByUserRole after login");
        const {
            restaurantsByUserRole,
        } = yield sdk.restaurantsByUserRoleQuery();
        yield put(
            RESTAURANT_ACTIONS.userRolesLoadSuccess(restaurantsByUserRole)
        );

        yield put(ERROR_ACTIONS.clearErrors());
        const restaurantCode = yield select(selectedRestaurantCodeSelector);
        logger.debug("Successfully logged in user", login);
        if (restaurantCode) yield put(push(`/${restaurantCode}/menu-editor`));
        else yield put(push(`/home`));
    } catch (errorResponse) {
        logger.error("Login Failed", errorResponse);
        yield put(
            ERROR_ACTIONS.loginFailure(
                errorResponse.response?.errors || errorResponse
            )
        );
        yield put(RESTAURANT_ACTIONS.userRolesLoadFailure());
    }
}

function* checkAuthStatus() {
    logger.debug("Attempting to check auth status");
    const sdk = getSdk();
    try {
        const isLoggedIn = yield select(
            (state: RootState) => state.user.isLoggedIn
        );
        if (!isLoggedIn) {
            logger.info("Not Logged In For checkAuthStatus");
            return;
        }
        const response = yield sdk.authStatus();
        yield put(USER_ACTIONS.authCheckSuccess(response.authStatus));
    } catch (error) {
        logger.error("Auth Status Check Failed", error);
        // Don't redirect if it's a network error. GraphQL will always return 200
        if (error && error.response && error.response.status === 200) {
            // @ts-ignore
            const AndroidFns: Android = window.Android;
            if (AndroidFns && AndroidFns.sessionTokenInvalid) {
                // Android will take care of logging out
                AndroidFns.sessionTokenInvalid();
            } else {
                yield put(USER_ACTIONS.logout());
                yield put(push("/login"));
            }
        }
    }
}

function* startPasswordResetSaga(action: PayloadAction<{ email: string }>) {
    logger.debug("Attempting to reset password");
    const sdk = getSdk();
    try {
        const startingResetPassword = yield sdk.startPasswordReset(
            action.payload
        );
        if (startingResetPassword.startPasswordReset)
            yield put(USER_ACTIONS.passwordResetCodeSuccess());
    } catch (error) {
        logger.error("Start Password Reset Failed", error);
    }
}

function* changePassowrdSaga(action: PayloadAction<ChangePaswordInput>) {
    logger.debug("Attempting to change password");
    const sdk = getSdk();
    try {
        const changingPassword = yield sdk.changePassword(action.payload);
        console.log("changePassowrdSaga", changingPassword);
    } catch (error) {
        logger.error("Change Password Failed", error);
    }
}

function* completePasswordResetSaga(
    action: PayloadAction<CompletePasswordResetMutationVariables>
) {
    logger.debug("Attempting to complete password reset");
    const sdk = getSdk();
    try {
        yield sdk.completePasswordReset(action.payload);
        yield put(USER_ACTIONS.resetCompleteSuccess());
    } catch (error) {
        logger.error("Complete Password Reset Failed", error);
        yield put(USER_ACTIONS.resetCompleteFail());
    }
}

function* createUserSessionSaga() {
    const authToken = getAuthToken();
    if (authToken) {
        const userProfile = yield select(currentUserSelector);
        const sessionId = uuidv4();
        const { email, firstName, lastName, username } = userProfile;
        const request = {
            user_session_id: sessionId,
            source_module: SOURCE,
            email_id: email,
            first_name: firstName,
            last_name: lastName,
            user_name: username,
            unique_user_id: username,
            active: 1,
        };

        const response: string = yield call(createUserSession, request);
        saveSessionID(sessionId);
        logger.info(`Complete user session  ${response}`);
    }
}

function* updateUserSessionSaga(action: PayloadAction<string>) {
    try {
        const sessionid = getSessionID();
        if (sessionid) {
            const userProfile = yield select(currentUserSelector);
            const restaurantCode = action.payload;
            const { email, firstName, lastName, username } = userProfile;
            const request = {
                user_session_id: sessionid,
                source_module: SOURCE,
                email_id: email,
                first_name: firstName,
                last_name: lastName,
                user_name: username,
                unique_user_id: username,
                restaurant_code: restaurantCode,
                active: 1,
            };

            const response: string = yield call(
                updateUserSession,
                sessionid,
                request
            );
            logger.info(`Complete user session Update  ${response}`);
            yield put(retrieveUserSessionAction({ restaurantCode }));
        }
    } catch (error) {
        logger.error("User session update error", error);
    }
}

function* retrieveUserSessionSaga(action: PayloadAction<IActiveUserReqType>) {
    try {
        const sessionid = getSessionID();
        if (sessionid) {
            const { restaurantCode, successCallback } = action.payload;
            const { data } = yield call(getUserSessions, restaurantCode);
            yield put(USER_ACTIONS.setSelectedRestaurantUserSession(data));
            logger.info(`Complete user session retrieve  ${data}`);
            successCallback?.(data);
        }
    } catch (error) {
        logger.error("User session retrieve error", error);
    }
}

function* forceLogOutUserSessionSaga(
    action: PayloadAction<{ activeSessions: UserSession[] }>
) {
    try {
        for (let session of action.payload.activeSessions) {
            const request = {
                user_session_id: session.user_session_id,
                force_reload: 1,
                source_module: SOURCE,
                restaurant_code: session.restaurant_code,
                unique_user_id: session.unique_user_id,
                email_id: session.email_id,
                first_name: session.first_name,
                last_name: session.last_name,
                user_name: session.user_name,
                active: 1,
            };

            yield call(updateUserSession, session.user_session_id, request);
        }
    } catch (error) {
        logger.error("Force log out error", error);
    }
}

function* deleteUserSessionSaga(action: PayloadAction<string>) {
    try {
        yield call(deleteUserSession, action.payload);
    } catch (error) {
        logger.error("Delete user session", error);
    }
}

function* updateHeartBeatSessionSaga(action: PayloadAction<string>) {
    try {
        const sessionid = getSessionID();
        if (sessionid) {
            const userProfile = yield select(currentUserSelector);

            const request = {
                user_session_id: sessionid,
                source_module: SOURCE,
                email_id: userProfile.email,
                first_name: userProfile.firstName,
                last_name: userProfile.lastName,
                user_name: userProfile.username,
                unique_user_id: userProfile.username,
                restaurant_code: action.payload,
                active: 1,
            };

            const response: string = yield call(
                updateUserSession,
                sessionid,
                request
            );
            logger.info(`Complete heart beat session Update  ${response}`);
        }
    } catch (error) {
        logger.error("User heart beat session update error", error);
    }
}

function* verifyGAuthTokenSaga(action: PayloadAction<IRequestType>) {
    const { payload, successCallback, errorCallback } = action.payload;
    try {
        logger.debug("Attempting to verify user");
        let sdk = getSdk();
        const { verifyGAuthToken } = yield sdk.verifyGoogleAuthToken({
            source: SOURCE,
            token: payload,
        });
        saveGoogleId(payload);
        yield put(ERROR_ACTIONS.clearErrors());
        yield put(USER_ACTIONS.setMFLoginState(verifyGAuthToken));
        successCallback?.();
        return verifyGAuthToken;
    } catch (error) {
        logger.error("error in verifying g auth token | Login Failed", error);
        errorCallback?.();
        yield put(
            ERROR_ACTIONS.loginFailure(
                error?.response?.errors || [{ message: "An error has occured" }]
            )
        );
    }
}

function* mfLoginSaga(action: PayloadAction<IRequestType>) {
    const { payload, successCallback, errorCallback } = action.payload;
    try {
        logger.debug("Attempting to verify user");
        let sdk = getSdk();
        const token = getGoogleId();
        if (token) {
            const { mfLogin } = yield sdk.mfLoginQuery({
                source: SOURCE,
                otp: payload,
                token,
            });
            yield put(USER_ACTIONS.loginSuccess(mfLogin));
            yield put(createUserSessionAction());
            sdk = getSdk(); // Get new SDK with user token attached
            logger.debug("Attempting to get restaurantsByUserRole after login");
            const {
                restaurantsByUserRole,
            } = yield sdk.restaurantsByUserRoleQuery();
            yield put(
                RESTAURANT_ACTIONS.userRolesLoadSuccess(restaurantsByUserRole)
            );

            yield put(ERROR_ACTIONS.clearErrors());
            const restaurantCode = yield select(selectedRestaurantCodeSelector);
            logger.debug("Successfully logged in user", login);
            if (restaurantCode)
                yield put(push(`/${restaurantCode}/menu-editor`));
            else yield put(push(`/home`));
        }
        successCallback?.();
    } catch (error) {
        logger.error("error in verifying g auth token", error);
        logger.error("Login Failed", error);

        let navigateToLogin = false;

        if (error.response?.errors?.length) {
            const { extensions } = error.response.errors[0];
            if (extensions?.exception?.extraFields?.length) {
                extensions.exception.extraFields.find(
                    (o: { name: string; value: string }) => {
                        if (o.name === "navigateToLogin") {
                            if (o.value === "YES") {
                                navigateToLogin = true;
                            }
                            return true;
                        }
                    }
                );
            }
        }

        if (navigateToLogin) {
            errorCallback?.(true);
            yield put(ERROR_ACTIONS.loginFailure(error.response?.errors));
        } else {
            errorCallback?.();
            yield put(
                ERROR_ACTIONS.loginFailure(
                    error.response?.errors || [
                        { message: "An error has occured" },
                    ]
                )
            );
        }
    }
}

export default function* userSaga() {
    yield takeEvery(loginAction.toString(), login);
    yield takeEvery(checkAuthStatusAction.toString(), checkAuthStatus);
    yield takeEvery(getRestaurantRolesAction.toString(), getRestaurantAccess);
    yield takeEvery(loadUserState.toString(), loadSavedUserState);
    yield takeEvery(startPasswordreset.toString(), startPasswordResetSaga);
    yield takeEvery(changePassword.toString(), changePassowrdSaga);
    yield takeEvery(
        completePasswordReset.toString(),
        completePasswordResetSaga
    );
    yield takeEvery(createUserSessionAction.toString(), createUserSessionSaga);
    yield takeEvery(updateUserSessionAction.toString(), updateUserSessionSaga);
    yield takeEvery(
        retrieveUserSessionAction.toString(),
        retrieveUserSessionSaga
    );
    yield takeEvery(forceLogOutAction.toString(), forceLogOutUserSessionSaga);

    yield takeEvery(deleteUserSessionAction.toString(), deleteUserSessionSaga);
    yield takeEvery(
        updateHeartBeatAction.toString(),
        updateHeartBeatSessionSaga
    );

    yield takeEvery(verifyGAuthTokenAction.toString(), verifyGAuthTokenSaga);
    yield takeEvery(mfLoginAction.toString(), mfLoginSaga);
}
