import { IconButton, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useReadOnly } from "../hooks";

export default function ItemNavigation({
    url,
    title,
}: {
    url: string;
    title: string;
}) {
    const { isReadOnly } = useReadOnly();
    const updatedTitle = `${isReadOnly ? "View" : "Edit"} ${title}`;
    const { search } = useLocation();
    return (
        <Link to={{ pathname: url, search }}>
            <Tooltip title={updatedTitle} aria-label={updatedTitle}>
                <IconButton>
                    {isReadOnly ? <InfoIcon /> : <EditIcon />}
                </IconButton>
            </Tooltip>
        </Link>
    );
}
