import { readEnvVariable } from "../utils/helper-functions";

const SOURCE = `PRP-${readEnvVariable("MENU_VERSION_STAGE")}`;
const BACK_DROP_CLICK = "backdropClick";
const DELAY = 2000;
const DEFAULT_GOOGLE_CLIENT_ID =
    "1025625822491-t9ulehu8ijuke7f9b8tl7utfu2dv7c5b.apps.googleusercontent.com";
const SYNONYM_KEY = "__synonym__";

export {
    SOURCE,
    BACK_DROP_CLICK,
    DELAY,
    DEFAULT_GOOGLE_CLIENT_ID,
    SYNONYM_KEY,
};
