import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../reducers/rootReducer";

export const isResetCodeSentSelector = (state: RootState) =>
    state.user.isResetCodeSent;
export const resetPasswordCompleteCodeSelector = (state: RootState) =>
    state.user.resetPasswordSuccessCode;

export const usetSelector = (state: RootState) => state.user;
export const currentUserSelector = (state: RootState) => state.user.userProfile;
export const tabActiveSelector = (state: RootState) => state.user.tabActive;

export const activeUserSessionSelector = createSelector(
    usetSelector,
    (user) => user?.userSessions
);
export const currentUserNameSelector = createSelector(
    usetSelector,
    (user) => user?.userProfile?.username
);
export const qrCodeURLSelector = createSelector(
    usetSelector,
    (user) => user?.qrCodeURL
);
export const isProfileLoadedSelector = createSelector(
    usetSelector,
    (user) => !!user?.userProfile
);
