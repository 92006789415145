import {
    AppBar,
    Container,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import React, { PropsWithChildren } from "react";
import { ErrorBoundary } from "react-error-boundary";
import prestoLogo from "../../images/presto_logo_white.png";
import { Image } from "../layout/Image";

const useStyles = makeStyles((theme: Theme) => ({
    prestoLogo: {
        marginBottom: theme.spacing(4),
    },
    wrapper: {
        width: "100vw",
        height: "100vh",
        position: "relative",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "#02263D",
        color: "#fff",
    },
    anchorLink: {
        color: "#0672CB",
        textDecoration: "none",
    },
}));

export function ErrorFallback() {
    const classes = useStyles();
    return (
        <>
            <AppBar />
            <div className={classes.wrapper}>
                <Container maxWidth="lg">
                    <Image
                        className={classes.prestoLogo}
                        url={prestoLogo}
                        alt="Presto Logo"
                    />
                    <Typography variant="h4" gutterBottom>
                        Something went wrong.
                    </Typography>
                    <Typography>
                        {"Please contact "}
                        <a
                            href="mailto:support@presto.com"
                            className={classes.anchorLink}
                        >
                            support
                        </a>
                    </Typography>
                </Container>
            </div>
        </>
    );
}

export default function ErrorBoundaryProvider({
    children,
}: PropsWithChildren<{}>) {
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {children}
        </ErrorBoundary>
    );
}
