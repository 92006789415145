import {
    createStyles,
    Grid,
    Theme,
    Typography,
    WithStyles,
    withStyles,
} from "@material-ui/core";
import { Mail, Message } from "@material-ui/icons";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { CircularLoading } from "../../components/CircularLoading";
import { ErrorMessage } from "../../components/ErrorMessage";
import { LoginFormData } from "../../components/forms/LoginForm";
import { Image } from "../../components/layout/Image";
import { MFALogin } from "../../components/MFALogin";
import prestoLogo from "../../images/presto_logo_white.png";
import { RootState } from "../../reducers/rootReducer";
import {
    loginAction,
    mfLoginAction,
    USER_ACTIONS,
    verifyGAuthTokenAction,
} from "../../reducers/userReducer";
import { FORM_NAMES } from "../../utils/forms";
import { IRequestType } from "../../utils/types";
const styles = ({ palette, spacing }: Theme) => {
    return createStyles({
        background: {
            width: "100vw",
            height: "100vh",
            backgroundColor: palette.primary.main,
            position: "absolute",
            zIndex: -999,
        },
        prestoLogo: {
            marginBottom: spacing(4),
            "@media (max-height: 600px)": {
                marginBottom: 0,
            },
        },
        gridContainer: {
            height: "100vh",
            backgroundColor: palette.primary.main,
            minHeight: "480px",
            paddingBottom: "100px",
            "@media (max-height: 600px)": {
                paddingBottom: 0,
            },
        },
        mainContainer: {
            flex: 1,
        },
        loginForm: {
            margin: spacing(2),
            "& .MuiTextField-root": {
                marginBottom: spacing(2),
            },
        },
        loginFormInputs: {
            color: "#fff",
            borderBottom: "2px solid white",
            backgroundColor: "#18394e !important",
        },
        loginFormLabel: {
            color: "#fff",
            "&.Mui-focused": {
                color: "#fff",
            },
            "& .MuiInputLabel-asterisk": {
                display: "none",
            },
        },
        contactContainer: {
            marginTop: spacing(2),
        },
        contact: {
            display: "flex",
            alignItems: "center",
            padding: spacing(1, 0),
            "& a": {
                textDecoration: "none",
                color: palette.primary.dark,
            },
        },
    });
};

class Login extends React.Component<StateProps, IUserState> {
    private passwordRefInput: React.RefObject<HTMLInputElement>;
    constructor(props: StateProps) {
        super(props);
        this.passwordRefInput = React.createRef();
        this.state = { isLoading: false };
    }
    public render() {
        const {
            classes,
            login,
            formErrors,
            mfLogin,
            verifyToken,
            resetMFLoginState,
        } = this.props;
        const onSubmit = (formData: LoginFormData) => {
            login(formData);
            this.passwordRefInput.current?.blur();
        };
        const mfFormSubmit = ({ accessToken }: { accessToken: string }) => {
            const request = {
                payload: accessToken,
                successCallback: () => {
                    this.setState({ isLoading: false });
                },
                errorCallback: (navigateToLogin?: boolean) => {
                    this.setState({ isLoading: false });
                    if (navigateToLogin) {
                        resetMFLoginState();
                    }
                },
            };
            this.setState({ isLoading: true });
            this.passwordRefInput.current?.blur();
            mfLogin(request);
        };
        const authTokenVerify = (tokenId: string) => {
            const request = {
                payload: tokenId,
                successCallback: () => {
                    this.setState({ isLoading: false });
                },
                errorCallback: () => {
                    this.setState({ isLoading: false });
                },
            };
            this.setState({ isLoading: true });
            this.passwordRefInput.current?.blur();
            verifyToken(request);
        };

        return (
            <React.Fragment>
                {this.state.isLoading && <CircularLoading />}
                <div className={classes.background} />
                <Grid
                    className={classes.gridContainer}
                    container
                    direction="column"
                    alignItems="center"
                >
                    <Grid
                        container
                        className={classes.mainContainer}
                        direction="column"
                        alignItems="center"
                        justify="space-around"
                    >
                        <Grid item>
                            <Image
                                className={classes.prestoLogo}
                                url={prestoLogo}
                                alt="Presto Logo"
                            />
                        </Grid>
                        <Grid item>
                            <div className={classes.loginForm}>
                                {formErrors && formErrors.errorMessage ? (
                                    <ErrorMessage
                                        message={formErrors.errorMessage}
                                    />
                                ) : null}
                                <MFALogin
                                    onSubmit={onSubmit}
                                    inputClassName={classes.loginFormInputs}
                                    inputLabelClassName={classes.loginFormLabel}
                                    formErrors={formErrors}
                                    passwordInputRef={this.passwordRefInput}
                                    mfLogin={mfFormSubmit}
                                    verifyToken={authTokenVerify}
                                />
                            </div>
                        </Grid>
                        <Grid item />
                    </Grid>
                    <Grid item className={classes.contactContainer}>
                        <Typography className={classes.contact}>
                            <Mail
                                color="secondary"
                                style={{ marginRight: "5px" }}
                            />
                            <a href="mailto:contactless.support@presto.com">
                                contactless.support@presto.com
                            </a>
                        </Typography>
                        <Typography className={classes.contact}>
                            <Message
                                color="secondary"
                                style={{ marginRight: "5px" }}
                            />
                            <a href="tel: +14155489347">+1 (415) 548 - 9347</a>
                        </Typography>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        formErrors: state.error.formErrors[FORM_NAMES.loginForm],
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        login: (credentials: { email: string; password: string }) =>
            dispatch(loginAction(credentials)),
        mfLogin: (req: IRequestType) => dispatch(mfLoginAction(req)),
        resetMFLoginState: () => dispatch(USER_ACTIONS.resetMFLoginState()),
        verifyToken: (req: IRequestType) =>
            dispatch(verifyGAuthTokenAction(req)),
    };
};

type StateProps = ConnectedProps<typeof connected> & WithStyles<typeof styles>;

type IUserState = {
    isLoading: Boolean;
};
const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(withStyles(styles)(Login));
