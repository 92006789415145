import { PayloadAction } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { put, select, takeEvery } from "redux-saga/effects";
import { ModifierGroup } from "../generated-interfaces/graphql";
import { MENU_EDITOR_ROUTES } from "../pages/menu-editor/menu-editor-routes";
import { duplicateModifierGroup, getMenuItems } from "../reducers/menuReducer";
import { selectedRestaurantCodeSelector } from "../selectors/restaurant";
import { IDuplicatePayload } from "../types/menu";
import logger from "../utils/logger";
import { getSdk } from "../utils/network";

function* duplicateModifierGroupSaga(action: PayloadAction<IDuplicatePayload>) {
    logger.debug("Duplicating Modifier Group");
    const sdk = getSdk();
    const {
        id: sourceModifierGroupId,
        successCallback,
        errorCallback,
    } = action.payload;
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );

        const dupModifierGroupResponse: {
            duplicateModifierGroup: ModifierGroup;
        } = yield sdk.duplicateModifierGroup({
            restaurantCode,
            sourceModifierGroupId,
            version: 0,
        });

        yield put(
            push(
                MENU_EDITOR_ROUTES(restaurantCode)[
                    "duplicateModifierGroup"
                ].path.replace(
                    ":id",
                    dupModifierGroupResponse.duplicateModifierGroup.id
                )
            )
        );

        yield put(getMenuItems());
        successCallback?.();
        logger.debug("Duplicate Modifier Group successful");
    } catch (error) {
        errorCallback?.();
        logger.error("Duplicate Modifier Group failed", error);
    }
}

export default function* modifierGroupSaga() {
    yield takeEvery(
        duplicateModifierGroup.toString(),
        duplicateModifierGroupSaga
    );
}
