export enum OrderCheckStatus {
    OPEN = "OPEN",
    POS_ENTERED = "POS_ENTERED",
    CLOSED = "CLOSED",
    PAID = "PAID",
}

export interface OrderItemOption {
    customRequestPosId: string;
    customRequestText: string;
    discount: string; // number
    id: number;
    isModifier: number;
    isUpsell: boolean;
    memo: string;
    memoId: string;
    modcode: string;
    name: string;
    options: OrderItemOption[];
    posId: string;
    price: string; // number
    priceDescriptionId: string;
    quantity: number;
    salesId: null;
    screenGroupId: string;
    tax: string; // number
    taxList: {
        amount: string; // number
        label: string;
        timestamp: string; // datetime
    }[];
    timestamp: string; //datetime
}
export interface Order {
    bumpedTime: string; // datetime
    checkStatus: OrderCheckStatus;
    checkTotal: string; // number
    closedTime: string; // datetime
    discount: string; // number
    isPayOnly: boolean;
    isSurchargeEnabled: boolean;
    isTab: boolean;
    isTaxEnabled: boolean;
    isTipEnabled: boolean;
    items: OrderItemOption[];
    memo: string;
    modifyTimestamp: string; //datetime
    orderType: "Takeaway" | "Dine In" | "Pay Only";
    paymentStatus: "FAILED" | "SUCCESS" | "MANUALLY_CLOSED";
    payments: [
        {
            amount: string; // number
            cardHolderName: string;
            emailAddress: string;
            firstName: string;
            gatewayType: string;
            lastName: string;
            lastStoredTransactionOperationTimeStamp: string;
            paymentToItems: {
                declaredAmount: string; // number
                id: "check" | "tip" | "surcharge";
                paidAmount: string; // number
                timestamp: string;
            }[];
            phoneNumber: string;
            posTransactionId: string;
            storedTransactionStatus: string;
            tableId: string;
            timestamp: string;
            transactionId: string;
            transactionTimestamp: string;
        }
    ];
    posId: string;
    preAuthAmount: string;
    reasonForVoid: string;
    requestId: string;
    sessionId: string;
    subtotal: string; // number
    surcharge: string; // number
    surchargeTax: string; // number
    tabOpen: boolean;
    tableId: string;
    taxTotal: string; // number
    tipTotal: string; // number
    loyaltyInfo?: {
        guid: string; // GUID of the guest
        isLoyal: boolean;
    };
    toGoConfig?: {
        pickupTime: string; // datetime
        smsOptIn: boolean;
    };
}
export interface OrdersResponse {
    requestId: string;
    checks: Order[];
}

export interface CloseTabPaymentFailureResponse {
    request_id: string;
    session_id: string;
    status: "SUCCESS";
}
