import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
    Event,
    GetEventsPayload,
    EventsAcknowledgementPayload,
} from "../types/events";
import { CallbackFunction } from "../utils/types";
import { USER_ACTIONS } from "./userReducer";
import { saveEventState } from "../utils/local-storage";

export interface EventsState {
    events: Event[];
    eventsAcknowledged: Record<
        number,
        EventsAcknowledgementPayload["acknowledgements"][0]
    >;
    alertSince?: string;
}

const initialState: EventsState = {
    events: [],
    eventsAcknowledged: {},
};

// export const registerDeviceAction = createAction<Omit<RegisterDevicePayload, "requestId">>("REGISTER_DEVICE");
// export const updatesSubscribeAction = createAction<Omit<UpdatesSubscribePayload, "requestId">>("UPDATES_SUBSCRIBE");
// export const updatesUnsubscribeAction = createAction<Omit<UpdatesUnsubscribePayload, "requestId">>("UPDATES_UNSUBSCRIBE");
export const getEventsAction = createAction<
    Omit<GetEventsPayload, "requestId">
>("GET_EVENTS");
export const eventsAcknowledgementAction = createAction<
    Omit<EventsAcknowledgementPayload, "requestId"> & CallbackFunction
>("EVENTS_ACK");

export const eventsState = createSlice({
    name: "events",
    initialState: initialState,
    reducers: {
        eventsLoading: (state) => {},
        eventsFailed: (state) => {},
        eventsLoaded: (state, action: PayloadAction<Event[]>) => {
            action.payload.forEach((alert) => {
                state.events.push(alert);
            });
            dayjs.extend(utc);
            state.alertSince = dayjs.utc().format();
            saveEventState(state);
        },
        addEventToAcknowledge: (
            state,
            action: PayloadAction<
                EventsAcknowledgementPayload["acknowledgements"][0]
            >
        ) => {
            state.eventsAcknowledged[action.payload.id] = action.payload;
            state.events = state.events.filter((e) => {
                return action.payload.id !== e.id;
            });
        },
        removeEventsAcknowledges: (state, action: PayloadAction<number[]>) => {
            action.payload.forEach((v) => {
                if (v in state.eventsAcknowledged) {
                    delete state.eventsAcknowledged[v];
                }
            });
        },
    },
});

export const EVENTS_ACTIONS = eventsState.actions;
