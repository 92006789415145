import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import createRootReducer from "./reducers/rootReducer";
import { rootSaga } from "./sagas/rootSaga";
import { googleAnalyticsMiddleware } from "./utils/analytics";
import { createPreserveQueryHistory } from "./utils/router";
import { getEventsState } from "./utils/local-storage";

export const history = createPreserveQueryHistory(createBrowserHistory, [
    "isStaffTablet",
])();
const sagaMiddleware = createSagaMiddleware();
let middleware = [];
if (process.env.NODE_ENV === "development") {
    middleware.push(
        ...getDefaultMiddleware({ thunk: false, serializableCheck: false })
    );
}
middleware.push(
    routerMiddleware(history),
    googleAnalyticsMiddleware,
    sagaMiddleware
);

const preloadedEventsState = getEventsState();
const store = configureStore({
    reducer: createRootReducer(history),
    middleware,
    preloadedState: {
        eventsReducer: preloadedEventsState,
    },
});

sagaMiddleware.run(rootSaga);

if (process.env.NODE_ENV === "development" && module.hot) {
    module.hot.accept("./reducers/rootReducer", () => {
        const newRootReducer = require("./reducers/rootReducer").default;
        store.replaceReducer(newRootReducer);
    });
}

export type AppDispatch = typeof store.dispatch;

export default store;
