import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
    AvailabilityInput,
    HoursOfOperation,
    RestaurantBrandingInput,
    RestaurantContactInfoInput,
    RestaurantSettingsInput,
    UserRestaurantsRole,
} from "../generated-interfaces/graphql";
import { MENU_ACTIONS } from "../reducers/menuReducer";
import {
    createHoursOfOperation,
    getRestaurantInfoFromHoneyAdminAction,
    RESTAURANT_ACTIONS,
    updateHoursOfOperation,
    updatePrimaryRestaurant,
    updateRestaurantBranding,
    updateRestaurantContactInfo,
    updateRestaurantSettings,
} from "../reducers/restaurantReducer";
import { RootState } from "../reducers/rootReducer";
import {
    retrieveUserSessionAction,
    updateUserSessionAction,
} from "../reducers/userReducer";
import { selectedRestaurantCodeSelector } from "../selectors/restaurant";
import logger from "../utils/logger";
import { getSdk } from "../utils/network";
import { getHighestAccessRestaurant } from "../utils/restaurants";
import { CallbackFunction, RestaurantUpdateDetails } from "../utils/types";
import { restaurantInfoCall } from "../utils/webserviceAPI";

function* getRestaurantInfo(action: PayloadAction<string>) {
    logger.debug("Attempting to getRestaurantInfo");
    try {
        const isLoggedIn = yield select(
            (state: RootState) => state.user.isLoggedIn
        );
        if (!isLoggedIn) {
            logger.info("Not Logged In For getRestaurantInfo");
            return;
        }
        const restaurantCode = action.payload;
        const sdk = getSdk();
        const { restaurant } = yield sdk.restaurantInfo({
            restaurantCode,
        });
        yield put(updateUserSessionAction(restaurantCode));
        yield put({
            type: retrieveUserSessionAction.toString(),
            payload: restaurantCode,
        });
        yield put(RESTAURANT_ACTIONS.restaurantInfoLoadSuccess(restaurant));
        yield put(MENU_ACTIONS.updateDidInitialLoad(false));
    } catch (error) {
        logger.error("Failed To Get Restaurant Info", error);
    }
}

function* autoSelectRestaurant(action: PayloadAction<UserRestaurantsRole[]>) {
    logger.debug("Automatically selecting restaurant based on user access");
    const preSelectedRestaurant = getHighestAccessRestaurant(action.payload);
    if (preSelectedRestaurant) {
        yield put(
            RESTAURANT_ACTIONS.selectPrimaryRestaurant({
                restaurantCode: preSelectedRestaurant.restaurantCode,
                primaryRestaurantCode:
                    preSelectedRestaurant.primaryRestaurantCode || undefined,
            })
        );
    } else {
        logger.error("Failed To Select Restaurant Automatically");
    }
}

function* updateRestaurantSettingsSaga(
    action: PayloadAction<RestaurantSettingsInput & CallbackFunction>
) {
    logger.debug("Attempting to save restaurant setting");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const sdk = getSdk();
    const { successCallback, errorCallback, ...payload } = action.payload;
    try {
        const restaurantCode = yield select(selectedRestaurantCodeSelector);
        yield sdk.updateRestaurantSettings({
            dineInModalityTaxRate: payload.dineInModalityTaxRate,
            deliveryModalityTaxRate: payload.deliveryModalityTaxRate,
            toGoModalityTaxRate: payload.toGoModalityTaxRate,
            restaurantCode,
            toGoOrderConfirmationMessage: payload.toGoOrderConfirmationMessage,
            isAlertingEnabled: payload.isAlertingEnabled,
            toGoPickupMinutes: payload.toGoPickupMinutes,
            toGoDefaultSMSText: payload.toGoDefaultSMSText,
            // skipAdvancedPayments: false,
            // currency: null,
            // isAvailableForOrdering: null,
            // isTabEnabled: null,
            // tabSize: null,
            // isPayOnlyEnabled: null,
            // isTaxEnabled: null,
            // isTipEnabled: null,
            // defaultTipPercentages: null,
        } as any);
        if (successCallback) successCallback();
    } catch (error) {
        if (errorCallback) errorCallback();
        logger.error("Save Restaurant settings failed", error);
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* updateRestaurantContactInfoSaga(
    action: PayloadAction<RestaurantContactInfoInput & CallbackFunction>
) {
    logger.debug("Attempting to save restaurant setting");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const sdk = getSdk();
    const { successCallback, errorCallback, ...payload } = action.payload;
    try {
        const restaurantCode = yield select(selectedRestaurantCodeSelector);
        yield sdk.updateRestaurantContactInfo({
            email: payload.email as string,
            address: payload.address,
            city: payload.city,
            zipCode: payload.zipCode,
            state: payload.state,
            country: payload.country,
            phoneNumber: payload.phoneNumber as string,
            website: payload.website,
            restaurantCode,
        });
        if (successCallback) successCallback();
    } catch (error) {
        if (errorCallback) errorCallback();
        logger.error("Save Restaurant settings failed", error);
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* updateRestaurantBrandingSaga(
    action: PayloadAction<RestaurantBrandingInput & CallbackFunction>
) {
    logger.debug("Attempting to upload restaurant randing");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    const sdk = getSdk();
    try {
        const restaurantCode = yield select(selectedRestaurantCodeSelector);
        yield sdk.updateRestaurantBranding({
            ...payload,
            restaurantCode,
        });
        if (successCallback) successCallback();
    } catch (error) {
        if (errorCallback) errorCallback();
        logger.error("Update Restaurant Branding Failed", error);
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* updateHoursOfOperationSaga(
    action: PayloadAction<HoursOfOperation & CallbackFunction>
) {
    logger.debug("Attempting to update hours of operation");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    const sdk = getSdk();
    try {
        const restaurantCode = yield select(selectedRestaurantCodeSelector);

        yield sdk.updateHoursOfOperation({
            timezone: payload.timezone,
            availability: payload.availability as AvailabilityInput[],
            restaurantCode,
        });
        yield put(RESTAURANT_ACTIONS.selectRestaurant(restaurantCode));
        if (successCallback) successCallback();
    } catch (error) {
        if (errorCallback) errorCallback();
        logger.error("Update Hours of Operation Failed", error);
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* createHoursOfOperationSaga(
    action: PayloadAction<HoursOfOperation & CallbackFunction>
) {
    logger.debug("Attempting to create hours of operation");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    const sdk = getSdk();
    try {
        const restaurantCode = yield select(selectedRestaurantCodeSelector);
        yield sdk.createHoursOfOperation({
            timezone: payload.timezone,
            availability: payload.availability as AvailabilityInput[],
            restaurantCode,
        });
        yield put(RESTAURANT_ACTIONS.selectRestaurant(restaurantCode));
        if (successCallback) successCallback();
    } catch (error) {
        if (errorCallback) errorCallback();
        logger.error("Create Hours of Operation Failed", error);
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* resturantInfoFromHoneyAdminSaga(action: PayloadAction<string>) {
    logger.debug(
        "Attempting to fetch restaurant info from honey admin",
        action.payload
    );
    try {
        const response = yield call(restaurantInfoCall, action.payload);
        yield put(
            RESTAURANT_ACTIONS.restaurantInfoHoneyAdminLoadSuccess(
                response.data
            )
        );
    } catch (error) {
        logger.error(
            "Failed fetching resturant info from honey admin",
            error?.message
        );
    }
}

function* updatePrimaryRestaurantSaga(
    action: PayloadAction<RestaurantUpdateDetails & CallbackFunction>
) {
    logger.debug("Attempting to upload restaurant randing");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    const sdk = getSdk();
    try {
        const restaurantCode = yield select(selectedRestaurantCodeSelector);
        yield sdk.updatePrimaryRestaurant({
            restaurantCode,
            primaryRestaurantCode: payload.primaryRestaurantCode,
        });

        yield put(
            RESTAURANT_ACTIONS.selectPrimaryRestaurant({
                restaurantCode,
                primaryRestaurantCode: payload.primaryRestaurantCode,
            })
        );
        yield put(MENU_ACTIONS.updateDidInitialLoad(false));
        if (successCallback) successCallback();
    } catch (error) {
        if (errorCallback) errorCallback();
        logger.error("Update Restaurant Branding Failed", error);
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

export default function* restaurantSaga() {
    yield takeEvery(
        RESTAURANT_ACTIONS.selectRestaurant.toString(),
        getRestaurantInfo
    );
    yield takeEvery(
        RESTAURANT_ACTIONS.userRolesLoadSuccess.toString(),
        autoSelectRestaurant
    );
    yield takeEvery(
        updateRestaurantSettings.toString(),
        updateRestaurantSettingsSaga
    );
    yield takeEvery(
        updateRestaurantContactInfo.toString(),
        updateRestaurantContactInfoSaga
    );
    yield takeEvery(
        updateRestaurantBranding.toString(),
        updateRestaurantBrandingSaga
    );
    yield takeEvery(
        updateHoursOfOperation.toString(),
        updateHoursOfOperationSaga
    );
    yield takeEvery(
        createHoursOfOperation.toString(),
        createHoursOfOperationSaga
    );
    yield takeEvery(
        getRestaurantInfoFromHoneyAdminAction.toString(),
        resturantInfoFromHoneyAdminSaga
    );
    yield takeEvery(
        updatePrimaryRestaurant.toString(),
        updatePrimaryRestaurantSaga
    );
}
