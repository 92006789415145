import { Chip, ChipProps, makeStyles } from "@material-ui/core";
import React, { memo } from "react";

interface ICustomChipProps extends ChipProps {
    customColor?: string;
}

const useStyles = makeStyles((theme) => ({
    chipColor: ({ color }: { color: string }) => {
        const chipColor: Record<string, string> = {};
        if (color) {
            chipColor["backgroundColor"] = `${color} !important`;
            chipColor["color"] = theme.palette.getContrastText(color);
        }
        return chipColor;
    },
}));

const CustomChip = ({ customColor, ...props }: ICustomChipProps) => {
    const classes = useStyles({ color: customColor || "" });
    return <Chip {...props} className={classes.chipColor} />;
};

export default memo(CustomChip);
