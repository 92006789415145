import { RootState } from "../reducers/rootReducer";

export const eventsSelector = (state: RootState) => state.eventsReducer.events;
export const eventsAcknowledgedSelector = (state: RootState) =>
    state.eventsReducer.eventsAcknowledged;
export const eventsToAcknowledgeSelector = (state: RootState) => {
    const toAck = Object.keys(state.eventsReducer.eventsAcknowledged);
    return state.eventsReducer.events.filter((e) => {
        return !(e.alertId in toAck);
    });
};
export const eventsAlertSinceSelector = (state: RootState) =>
    state.eventsReducer.alertSince;
