import { Chip, Typography } from "@material-ui/core";
import { Autocomplete, TextField } from "mui-rff";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Form, FormSpy } from "react-final-form";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { Prompt, useLocation, useParams } from "react-router-dom";
import { Dispatch } from "redux";
import ConfirmDialog from "../../components/ConfirmDialog";
import DragContainer from "../../components/menu/DragContainer";
import { OverrideInput } from "../../components/menu/DragItemList";
import { NewInputProperties } from "../../components/menu/InputProperties";
import ReadOnlyWrapper from "../../components/ReadOnlyWrapper";
import { MenuType } from "../../constants/menu";
import {
    MenuItem as MenuItemType,
    MenuOverride,
    ModalityType,
    SecondaryType,
    SortOrder,
    TimePeriod,
} from "../../generated-interfaces/graphql";
import {
    useCustomHistory,
    useDuplicatePayload,
    useVoiceProperties,
} from "../../hooks";
import {
    createCategory,
    deleteCategory,
    duplicateCategory,
    getVoiceProperties,
    updateCategory,
} from "../../reducers/menuReducer";
import { selectedRestaurantCodeSelector } from "../../selectors/restaurant";
import {
    CategoryType,
    ComponentType,
    IDuplicatePayload,
} from "../../types/menu";
import { TIME_OUT } from "../../utils/constants";
import {
    categoriesSelector,
    getValidKeyValuePair,
    menuItemsSelector,
    menuOverridesSelector,
    timePeriodsSelector,
} from "../../utils/menu";
import { DeleteItemType, GetIngestedDataType } from "../../utils/types";
import ActionItems from "./ActionItems";

function NewCategory(props: Props) {
    const { id } = useParams<{ id: string }>();
    const alert = useAlert();
    const modify = useLocation().pathname.endsWith("duplicate");

    const [blockNavigation, setBlockNavigation] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [menuItemList, setMenuItemList] = useState<MenuItemType[]>([]);
    const [originItemIdList, setOriginItemIdList] = useState("");
    const [showBackConfirmModal, setShowBackConfirmModal] = useState(false);
    const [timePeriodSelections, updateTimePeriodSelections] = useState<
        TimePeriod[]
    >([]);
    const [overrides, setOverrides] = React.useState<{
        [menuItemId: string]: OverrideInput;
    }>({});

    const timePeriodMap = useSelector(timePeriodsSelector);
    const categoriesMap = useSelector(categoriesSelector);
    const menuItemMap = useSelector(menuItemsSelector);
    const allTimePeriods = Object.values(timePeriodMap);
    const initialValues = categoriesMap[id];
    const { voiceProperties, setVoiceProperties } = useVoiceProperties(
        [{ key: "", value: "" }],
        initialValues?.name
    );
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);
    const menuOverridesFromStore = useSelector(menuOverridesSelector);
    const { generateDuplicatePayload } = useDuplicatePayload();
    const { pushToHistory } = useCustomHistory();

    useEffect(() => {
        if (id !== "new" && initialValues) {
            let newItemSort = [...categoriesMap[id].sortOrder];
            newItemSort.sort((a, b) => {
                if (a.sortOrder === null || b.sortOrder === null) return 1;
                return a.sortOrder - b.sortOrder;
            });
            const childMenuItems = newItemSort.map(
                (order) => menuItemMap[order.id]
            );

            const initialOverrideValues: {
                [menuItemId: string]: OverrideInput;
            } = {};
            initialValues.menuItems.forEach((id) =>
                menuItemMap[id].menuOverrides.forEach((item) => {
                    const {
                        objectPrimaryKey,
                        overrideKey,
                        overrideValue,
                        modalityType,
                        secondaryType,
                        secondaryId,
                    } = menuOverridesFromStore[item];
                    if (
                        secondaryType === SecondaryType.Category &&
                        secondaryId === String(id)
                    ) {
                        initialOverrideValues[
                            `${objectPrimaryKey}_${modalityType}`
                        ] = {
                            objectPrimaryKey,
                            overrideKey,
                            overrideValue,
                            modalityType: modalityType as ModalityType,
                        };
                    }
                })
            );
            setOverrides(initialOverrideValues);
            setMenuItemList(childMenuItems);
            setOriginItemIdList(childMenuItems.map((cm) => cm?.id).join(""));
            updateTimePeriodSelections(
                initialValues.timePeriods.map((id) => timePeriodMap[id])
            );
        } else {
            resetStateToDefault();
        }
    }, [initialValues]);

    useEffect(() => {
        window.onbeforeunload = null;
    }, []);

    async function validate(values: any) {
        if (!values.name) {
            return { required: "Display name is required" };
        }
        return;
    }

    const resetStateToDefault = () => {
        setBlockNavigation(false);
        setShowConfirmModal(false);
        setMenuItemList([]);
        setOriginItemIdList("");
        setShowBackConfirmModal(false);
        updateTimePeriodSelections([]);
        setOverrides({});
        setVoiceProperties([{ key: "", value: "" }]);
    };

    const onSubmit = (data: any) => {
        const menuItems: number[] = [];
        const sortOrder: SortOrder[] = [];

        menuItemList?.forEach((menuItem: MenuItemType, index: number) => {
            menuItems.push(parseFloat(menuItem.id));
            sortOrder.push({
                id: parseFloat(menuItem.id),
                sortOrder: index,
            });
        });

        const categoryData = {
            id: data.id ? id : data.id,
            name: data.name?.trim(),
            description: data.description?.trim(),
            menuItems,
            timePeriods: timePeriodSelections.map((timePeriod) =>
                parseInt(timePeriod.id)
            ),
            sortOrder,
            menuOverrides: Object.values(overrides).map((overrideInput) => {
                return {
                    secondaryType: SecondaryType.Category,
                    secondaryId: id,
                    objectPrimaryKey: overrideInput.objectPrimaryKey,
                    overrideKey: overrideInput.overrideKey,
                    overrideValue: overrideInput.overrideValue,
                    modalityType: overrideInput.modalityType,
                } as MenuOverride;
            }),
            successCallback: () => {
                alert.success("Category Saved", {
                    timeout: TIME_OUT,
                });
            },
            errorCallback: (err: string) => {
                alert.error("Error Saving Category", {
                    timeout: TIME_OUT,
                });
            },
        } as any;

        const updatedVoiceProps = getValidKeyValuePair(voiceProperties);

        categoryData["voiceProperties"] =
            updatedVoiceProps?.length > 0 ? updatedVoiceProps : [];

        if (categoryData.id) {
            props.updateCategory(categoryData as any);
        } else {
            props.createCategory(categoryData as any);
        }
        setBlockNavigation(false);
    };

    const handleRemoveCategory = () => {
        setShowConfirmModal(true);
    };

    const handleGoBack = (formIsDirty: boolean) => () => {
        if (formIsDirty) {
            setShowBackConfirmModal(true);
        } else {
            pushToHistory(`/${restaurantCode}/menu-editor/categories`);
        }
    };

    const handleOverrideChange = (
        objPrimaryKey: string,
        modalityType: ModalityType,
        overrideInput: OverrideInput
    ) => {
        setOverrides((prevOverrides) => ({
            ...prevOverrides,
            [`${objPrimaryKey}_${modalityType}`]: overrideInput,
        }));
    };

    const checkMenuItemListUpdated = () => {
        const newIdList = menuItemList.map((mg) => mg?.id).join("");
        return !(newIdList === originItemIdList);
    };

    return (
        <React.Fragment>
            <Prompt
                when={blockNavigation}
                message={(location, action) => {
                    if (action === "POP") {
                        return "The changes you've made will be lost. Are you sure you want to discard the changes?";
                    }

                    return true;
                }}
            />
            {showConfirmModal && (
                <ConfirmDialog
                    content="Are you sure to delete this category?"
                    open={showConfirmModal}
                    onSuccess={() => {
                        props.deleteCategory({
                            id: parseFloat(id),
                            name: initialValues?.name || "",
                            successCallback: () => {
                                alert.success("Category Deleted", {
                                    timeout: TIME_OUT,
                                });
                                pushToHistory(
                                    `/${restaurantCode}/menu-editor/categories`
                                );
                            },
                            errorCallback: () => {
                                alert.error("Error Deleting Category", {
                                    timeout: TIME_OUT,
                                });
                            },
                        });
                    }}
                    onCancel={() => setShowConfirmModal(false)}
                />
            )}
            {showBackConfirmModal && (
                <ConfirmDialog
                    title="Leave without saving?"
                    content="The changes you've made will be lost. Are you sure you want to discard the changes?"
                    open={showBackConfirmModal}
                    onSuccess={() => {
                        pushToHistory(
                            `/${restaurantCode}/menu-editor/categories`
                        );
                    }}
                    onCancel={() => setShowBackConfirmModal(false)}
                />
            )}
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                validate={validate}
                render={({ handleSubmit, values, valid, dirty }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <FormSpy
                            onChange={(props) => {
                                setBlockNavigation(
                                    props.dirty || checkMenuItemListUpdated()
                                );
                            }}
                        />
                        <ActionItems
                            handleRemove={handleRemoveCategory}
                            handleGoBack={handleGoBack(
                                dirty || checkMenuItemListUpdated()
                            )}
                            handleDuplicate={() => {
                                props.duplicateCategory(
                                    generateDuplicatePayload(
                                        initialValues?.id,
                                        ComponentType.category
                                    )
                                );
                            }}
                            disableSave={
                                !valid ||
                                !menuItemList ||
                                menuItemList.length === 0
                            }
                            id={id}
                            menuType={MenuType.category}
                        />
                        <ReadOnlyWrapper
                            element={TextField}
                            fullWidth
                            id="name"
                            label="Display Name"
                            name="name"
                            margin="normal"
                            disabled={id !== "new" && !modify}
                            required
                        />
                        <ReadOnlyWrapper
                            element={TextField}
                            id="description"
                            label="Description (optional)"
                            placeholder="Enter description here"
                            name="description"
                            fullWidth={true}
                            multiline={true}
                            rows={4}
                            margin="normal"
                            variant="filled"
                            style={{
                                marginBottom: 40,
                            }}
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                        <NewInputProperties
                            inputProps={voiceProperties}
                            setInputProps={setVoiceProperties}
                            heading="Set Voice Properties"
                        />
                        <Typography variant="h6" color="primary" gutterBottom>
                            Meal Periods Available
                        </Typography>
                        <Typography style={{ marginBottom: "24px" }}>
                            Define when this category available to the customer
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                            When is this category available?
                        </Typography>
                        <ReadOnlyWrapper
                            element={Autocomplete}
                            multiple
                            autoSelect
                            autoHighlight
                            id="timePeriods"
                            name="timePeriods"
                            label="Add Time Periods"
                            options={allTimePeriods}
                            getOptionLabel={(option: any) =>
                                option.description as string
                            }
                            getOptionValue={(option: any) => option}
                            value={timePeriodSelections}
                            onChange={(
                                event: ChangeEvent<HTMLAllCollection>,
                                value: TimePeriod[]
                            ) => updateTimePeriodSelections(value)}
                            renderTags={(
                                value: any,
                                getTagProps: (a1: any) => {}
                            ) => {
                                return value.map(
                                    (option: any, index: number) => (
                                        <Chip
                                            variant="default"
                                            label={option.description}
                                            {...getTagProps({ index })}
                                        />
                                    )
                                );
                            }}
                            style={{
                                marginBottom: "40px",
                            }}
                        />
                        <Typography variant="h6" color="primary" gutterBottom>
                            Items in this category
                        </Typography>
                        <DragContainer
                            listItemType={"menuItemForCategory"}
                            secondaryId={initialValues?.id}
                            label="Add item to category"
                            itemList={menuItemList}
                            setOverride={handleOverrideChange}
                            setItemList={setMenuItemList}
                        />
                    </form>
                )}
            />
        </React.Fragment>
    );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        createCategory: (data: CategoryType) => dispatch(createCategory(data)),
        updateCategory: (data: CategoryType) => dispatch(updateCategory(data)),
        deleteCategory: (data: DeleteItemType) =>
            dispatch(deleteCategory(data)),
        duplicateCategory: (data: IDuplicatePayload) =>
            dispatch(duplicateCategory(data)),
        getVoiceProperties: (data: GetIngestedDataType) =>
            dispatch(getVoiceProperties(data)),
    };
};
type Props = ConnectedProps<typeof connected>;
const connected = connect(null, mapDispatchToProps);

export default connected(NewCategory);
