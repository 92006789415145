import { GraphQLClient } from "graphql-request/dist";
import {
    getSdk as createSdk,
    SdkFunctionWrapper,
} from "../generated-interfaces/graphql";
import { readEnvVariable } from "./helper-functions";
import { saveAuthToken } from "./local-storage";
import logger from "./logger";
import { nullOrUndefined } from "./types";

const AUTH_TOKEN_RESPONSE_HEADER = "x-presto-auth-token";
const AUTHORIZATION_HEADER = "Authorization";
let client: GraphQLClient | null;

let authToken: string | nullOrUndefined;

export const setAuthToken = (token: string | nullOrUndefined) => {
    authToken = token;
    client = null; // Reset client so we use the new auth token
    saveAuthToken(token);
};

interface ApiRequestResult<T> {
    data: T & { responseStatusCode: number };
    headers: Headers;
    status: number;
}

const apiCallWrapper: SdkFunctionWrapper = async <T>(
    action: () => Promise<T>
): Promise<T> => {
    const startTime = new Date().getTime();
    const result: ApiRequestResult<T> = (await action()) as any;
    const requestDuration = new Date().getTime() - startTime;
    setAuthToken(result.headers.get(AUTH_TOKEN_RESPONSE_HEADER));
    result.data["responseStatusCode"] = result.status;
    logger.debug("API request duration (ms)", { requestDuration });
    return result.data;
};

export const getSdk = () => {
    if (!client) {
        logger.debug("Created new GraphQL Client");
        client = new GraphQLClient(`${readEnvVariable("PRP_API")}/graphql`);
        if (authToken) {
            client.setHeader(AUTHORIZATION_HEADER, authToken);
        }
    }
    return createSdk(client, apiCallWrapper);
};
