import { MaterialTableProps } from "material-table";
import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMenuVersionActivity } from "../../reducers/menuReducer";
import { selectMenuVersionActivity } from "../../selectors/menu";
import { selectedRestaurantCodeSelector } from "../../selectors/restaurant";
import { IMenuVersionActivity } from "../../types/menuVersion";
import { DATE_FORMATS } from "../../utils/constants";
import { CustomizedMaterialTable } from "../../utils/data-tables";
import { formatDate } from "../../utils/helper-functions";

const MenuActivityVersionList = () => {
    const menuVersionActivity = useSelector(selectMenuVersionActivity);
    const actionNameLookup: Record<string, string> = {};
    const updatedMenuVersionActivity = menuVersionActivity.map((o) => {
        actionNameLookup[o.actionName] = o.actionName;
        return {
            ...o,
        };
    });
    const dispatch = useDispatch();
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);

    useEffect(() => {
        if (restaurantCode) {
            dispatch(fetchMenuVersionActivity());
        }
    }, [dispatch, restaurantCode]);

    const menuHistoryActiveTableConfig: MaterialTableProps<IMenuVersionActivity> = {
        columns: [
            {
                title: "Menu version",
                field: "commitId",
                filtering: false,
            },
            {
                title: "Menu title",
                field: "comment",
                filtering: false,
            },
            {
                title: "Created on",
                field: "createdAt",
                render: ({ createdAt }) =>
                    formatDate(createdAt, DATE_FORMATS.dateAndTime),
                filtering: false,
            },
            {
                title: "Created by",
                field: "updaterName",
                render: ({ creatorName }) => creatorName || "NA",
                filtering: false,
            },
            {
                title: "Execution type",
                field: "actionName",
                lookup: actionNameLookup,
            },
            {
                title: "Executed on",
                field: "updatedAt",
                render: ({ updatedAt }) =>
                    formatDate(updatedAt, DATE_FORMATS.dateAndTime),
                filtering: false,
            },
            {
                title: "Executed by",
                field: "publisherName",
                render: ({ publisherName }) => publisherName || "NA",
                filtering: false,
            },
        ],
        data: updatedMenuVersionActivity, // Shallow copy objects b/c they are immutable
        options: {
            filtering: true,
        },
    };

    return (
        <div>
            <CustomizedMaterialTable {...menuHistoryActiveTableConfig} />
        </div>
    );
};

export default memo(MenuActivityVersionList);
