import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { SYNONYM_KEY } from "../constants";
import { InputProperties } from "../generated-interfaces/graphql";
import { getVoicePropertiesSelector } from "../utils/menu";

export type useVoicePropertiesType = {
    voiceProperties: InputProperties[];
    setVoiceProperties: (T: InputProperties[]) => void;
    synonymProperties: InputProperties[];
    setSynonymProperties: (T: InputProperties[]) => void;
    fillVoiceProps: (T: InputProperties[]) => void;
};

export default function useVoiceProperties(
    initalValue: InputProperties[] = [],
    unique_identifier: string = ""
): useVoicePropertiesType {
    const [voiceProperties, setVoiceProperties] = useState<InputProperties[]>(
        initalValue
    );
    const [synonymProperties, setSynonymProperties] = useState<
        InputProperties[]
    >([]);
    const voicePropertiesFromStore: Record<
        string,
        InputProperties[]
    > = useSelector(getVoicePropertiesSelector, shallowEqual);

    useEffect(() => {
        // to make read-only redux properties to editable fields
        const allVoiceProperties = voicePropertiesFromStore[unique_identifier];
        if (allVoiceProperties?.length) {
            const voiceProps: InputProperties[] = [],
                synonymProps: InputProperties[] = [];
            allVoiceProperties.forEach((entry) => {
                const { key, value } = entry;
                if (key === SYNONYM_KEY) {
                    if (value)
                        JSON.parse(value).forEach((synonymValue: string) =>
                            synonymProps.push({ key, value: synonymValue })
                        );
                } else voiceProps.push(entry);
            });
            setVoiceProperties(JSON.parse(JSON.stringify(voiceProps)));
            setSynonymProperties(JSON.parse(JSON.stringify(synonymProps)));
        }
        return () => {
            setVoiceProperties([]);
            setSynonymProperties([]);
        };
    }, [voicePropertiesFromStore, unique_identifier]);

    const fillVoiceProps = (updatedVoiceProps: InputProperties[]) => {
        //Loop through synonyms array and populate it as a voice property
        const synonymsArray: Array<string> = [];
        synonymProperties.reduce((acc, { value }) => {
            if (value) acc.push(value);
            return acc;
        }, synonymsArray);
        if (synonymsArray.length) {
            updatedVoiceProps.push({
                key: SYNONYM_KEY,
                value: JSON.stringify(synonymsArray),
            });
        }
    };

    return {
        voiceProperties,
        setVoiceProperties,
        synonymProperties,
        setSynonymProperties,
        fillVoiceProps,
    };
}
