import { createSelector } from "@reduxjs/toolkit";
import {
    Category,
    InputProperties,
    Maybe,
    MenuItem,
    MenuItemSetting,
    MenuOverride,
    ModifierGroup,
    PosProperties,
    SortOrder,
    TimePeriod,
} from "../generated-interfaces/graphql";
import { MenuState } from "../reducers/menuReducer";
import { RootState } from "../reducers/rootReducer";
import { DAYS_SHORT } from "../utils/constants";
import { camelToSnakeCase, createUuid } from "./helper-functions";
import { GetIngestedDataType } from "./types";

export const renderTableCollectionText = (
    itemRecords: Record<string, any>,
    maxItems: number = 2,
    fieldName: string = "name"
) => {
    if (!itemRecords) return "";
    const items = itemRecords ? Object.values(itemRecords) : [];
    if (!items) {
        return "";
    }
    const itemNames = items.map((item) => item[fieldName]);
    if (items.length <= maxItems) {
        return itemNames.join(", ");
    }
    return `${itemNames.slice(0, maxItems).join(", ")}, +${
        itemNames.length - maxItems
    }`;
};

const getObjectKey = () => {
    return createUuid();
};

/*
The following "selectors" will only re-calculate if one of the "inputs" (i.e. menuItemsSelector) changes.
This makes them super efficient and will only re-calculate on data changes
 */
export const isEntitySavingSelector = (state: RootState) =>
    state.menu.isSavingEntity;
export const menuItemsSelector = (state: RootState) => state.menu.menuItems;
export const modifierGroupsSelector = (state: RootState) =>
    state.menu.modifierGroups;
export const categoriesSelector = (state: RootState) => state.menu.categories;
export const timePeriodsSelector = (state: RootState) => state.menu.timePeriods;
export const menuOverridesSelector = (state: RootState) =>
    state.menu.menuOverrides;
export const menuItemSettingsSelector = (state: RootState) =>
    state.menu.menuItemSettings;
export const posPropertiesSelector = (state: RootState) =>
    state.menu.posProperties;

type TreeProps<S> = {
    key: string;
    id: string;
    name: string;
    description: Maybe<string>;
    children: Record<string, S>;
    ownSortOrder?: number;
    sortOrder?: SortOrder[];
    timePeriods?: TimePeriod[];
    modifierGroups?: any[];
};
export type TreeObject<T, S> = T & TreeProps<S>;
export type TreeCategory = TreeObject<Category, TreeMenuItem>;
export type ItemType = Category | MenuItem | ModifierGroup;
export type TreeNode = ItemType & {
    id: string;
    value: string;
    nodes: TreeNode[];
};

export type TreeMenuItem = TreeObject<MenuItem, ModifierGroup>;
export type TreeModifierGroup = TreeObject<ModifierGroup, MenuItem>;
type MenuStateComponents = { menuItems: MenuState["menuItems"] } & {
    modifierGroups: MenuState["modifierGroups"];
} & { categories: MenuState["categories"] };
export type MenuItemWithCategories = MenuItem & {
    childModifierGroups: Record<string, ModifierGroup>;
} & { parentCategories: Record<string, Category> } & {
    settings: Record<string, MenuItemSetting>;
} & { overrides: Record<string, MenuOverride> } & {
    posPropertiesRecord: Record<string, PosProperties>;
};
export type ModifierGroupWithMenuItems = ModifierGroup & {
    childMenuItems: Record<string, MenuItem>;
} & { parentMenuItems: Record<string, MenuItem> };
export type FormattedTimePeriod = TimePeriod & { availableDays: string[] };

export const getTimePeriodList = (
    timePeriodIdList: Number[],
    timePeriods: Record<string, TimePeriod>
) => {
    return timePeriodIdList.map((id) => {
        return timePeriods[id.toString()];
    });
};

export const getModifierGroupList = (
    modifierIdList: Number[],
    modifierGroups: Record<string, ModifierGroup>
) => {
    return modifierIdList.map((id) => {
        return modifierGroups[id.toString()];
    });
};

export const menuTreeSelector = createSelector(
    menuItemsSelector,
    modifierGroupsSelector,
    categoriesSelector,
    timePeriodsSelector,
    (menuItems, modifierGroups, categories, timePeriods) => {
        const menuStateComponents: MenuStateComponents = {
            menuItems,
            modifierGroups,
            categories,
        };
        return Object.values(categories).reduce((acc, category) => {
            const menuItemObjects = toRecord(
                category.menuItems.map((menuItemId) => {
                    let modifierGroupsData: Number[] = [];
                    if (menuStateComponents.menuItems[menuItemId]) {
                        modifierGroupsData =
                            menuStateComponents.menuItems[menuItemId]
                                .modifierGroups;
                    }
                    return {
                        ...menuStateComponents.menuItems[menuItemId],
                        modifierGroups: getModifierGroupList(
                            modifierGroupsData,
                            modifierGroups
                        ) as any,
                        key: getObjectKey(),
                        children: {},
                    };
                })
            );
            acc[category.id] = {
                ...category,
                id: category.id,
                ownSortOrder: category.ownSortOrder as any,
                key: getObjectKey(),
                children: menuItemObjects,
                timePeriods: getTimePeriodList(
                    category.timePeriods,
                    timePeriods
                ) as any,
            };
            return acc;
        }, {} as Record<string, TreeCategory>);
    }
);

export const menuTreeNodeSelector = createSelector(
    menuTreeSelector,
    (menuTree) => {
        return Object.values(menuTree).map(convertTreeObjectToNodes);
    }
);

const getMenuItem = (
    menuItemId: number,
    menuState: MenuStateComponents
): TreeMenuItem => {
    const menuItem = menuState.menuItems[menuItemId];
    const modifierGroupObjects = toRecord(
        menuItem.modifierGroups.map((modGroupId) =>
            getModGroup(modGroupId, menuState)
        )
    );
    return {
        ...menuItem,
        key: getObjectKey(),
        children: modifierGroupObjects,
    };
};

const getModGroup = (
    modGroupId: number,
    menuState: MenuStateComponents
): TreeModifierGroup => {
    const modGroup = menuState.modifierGroups[modGroupId];

    const menuItemObjectsTemp = modGroup.menuItems.map(
        (menuItemId) => menuState.menuItems[menuItemId]
    );
    const hasRecursiveObject = !!menuItemObjectsTemp.find((item) =>
        item.modifierGroups.includes(Number(modGroup.id))
    );

    if (hasRecursiveObject) {
        console.error("Recursive MenuItem/ModGroup Found", modGroup);
        return {
            ...modGroup,
            key: getObjectKey(),
            children: {},
        };
    }

    const menuItemObjects = toRecord(
        modGroup.menuItems.map((menuItemId) =>
            getMenuItem(menuItemId, menuState)
        )
    );
    return {
        ...modGroup,
        key: getObjectKey(),
        children: menuItemObjects,
    };
};

export const modifierGroupTreeSelector = createSelector(
    menuItemsSelector,
    modifierGroupsSelector,
    (menuItems, modifierGroups) => {
        const menuStateComponents: MenuStateComponents = {
            menuItems,
            modifierGroups,
            categories: {}, // categories aren't needed here
        };
        return toRecord(
            Object.values(modifierGroups).map((modGroup) =>
                getModGroup(Number(modGroup.id), menuStateComponents)
            )
        );
    }
);

export const menuItemsWithCategoriesSelector = createSelector(
    menuItemsSelector,
    modifierGroupsSelector,
    categoriesSelector,
    menuItemSettingsSelector,
    menuOverridesSelector,
    posPropertiesSelector,
    (
        menuItems,
        modifierGroups,
        categories,
        menuItemSettings,
        menuOverrides,
        posProperties
    ) => {
        return Object.values(menuItems).reduce((acc, menuItem) => {
            const parentCategories = toRecord(
                Object.values(categories).reduce((acc, category) => {
                    if (category.menuItems.includes(Number(menuItem.id))) {
                        acc.push(category);
                    }
                    return acc;
                }, [] as Category[])
            );
            const childModifierGroups = toRecord(
                menuItem.modifierGroups.map((id) => modifierGroups[id])
            );
            const settings = toRecord(
                menuItem.menuItemSettings.map((id) => menuItemSettings[id])
            );
            const posPropertiesRecord = toRecord(
                menuItem.posProperties.map((id) => posProperties[id])
            );
            const overrides = toRecord(
                menuItem.menuOverrides.map((id) => menuOverrides[id])
            );
            acc[menuItem.id] = {
                ...menuItem,
                childModifierGroups,
                parentCategories,
                settings,
                overrides,
                posPropertiesRecord,
            };
            return acc;
        }, {} as Record<string, MenuItemWithCategories>);
    }
);

export const modifierGroupWithMenuItemsSelector = createSelector(
    menuItemsSelector,
    modifierGroupsSelector,
    (menuItems, modifierGroups) => {
        return Object.values(modifierGroups).reduce((acc, modGroup) => {
            const parentMenuItems = Object.values(menuItems).reduce(
                (acc, menuItem) => {
                    if (menuItem.modifierGroups.includes(Number(modGroup.id))) {
                        acc[menuItem.id] = menuItem;
                    }
                    return acc;
                },
                {} as Record<string, MenuItem>
            );
            const childMenuItems = toRecord(
                modGroup.menuItems.map((itemId) => menuItems[itemId])
            );
            acc[modGroup.id] = {
                ...modGroup,
                parentMenuItems,
                childMenuItems,
            };
            return acc;
        }, {} as Record<string, ModifierGroupWithMenuItems>);
    }
);

export const timePeriodTableSelector = createSelector(
    timePeriodsSelector,
    (timePeriods) => {
        return Object.values(timePeriods).reduce((acc, timePeriod) => {
            const availableDays = timePeriod.availability
                ?.filter(
                    (av) =>
                        av.alwaysEnabled || (av.hours && av.hours.length > 0)
                )
                .map((av) => DAYS_SHORT[av.day]);
            acc[timePeriod.id] = {
                ...timePeriod,
                availableDays: availableDays || [],
            };
            return acc;
        }, {} as Record<string, FormattedTimePeriod>);
    }
);

const convertTreeObjectToNodes = (treeObject: TreeProps<any>): TreeNode => {
    let newItemSort = [...(treeObject.sortOrder as any[])];
    newItemSort.sort((a, b) => {
        if (a.sortOrder === null || b.sortOrder === null) return 1;
        return a.sortOrder - b.sortOrder;
    });
    const childList = newItemSort.map((order) => treeObject.children[order.id]);
    let timePeriodListName = "";
    if (treeObject.timePeriods && treeObject.timePeriods.length > 0) {
        timePeriodListName =
            "  ( " +
            renderTableCollectionText(
                treeObject.timePeriods,
                2,
                "description"
            ) +
            ")";
    }
    let modifierListName = "";
    if (treeObject.modifierGroups && treeObject.modifierGroups.length > 0) {
        modifierListName =
            "  ( " +
            renderTableCollectionText(treeObject.modifierGroups, 2, "name") +
            ")";
    }
    return {
        value: treeObject.name + timePeriodListName + modifierListName,
        nodes: childList
            .filter((c) => c !== undefined)
            .map(convertTreeObjectToNodes),
        ...treeObject,
    } as any;
};

export const toRecord = <T extends { id: string }>(
    records: Array<T>
): Record<string, T> => {
    return records.reduce((acc, record) => {
        if (record?.id) {
            acc[record.id] = record;
        }
        return acc;
    }, {} as Record<string, T>);
};

export const getVoicePropertiesSelector = (state: RootState) =>
    state.menu.voiceProperties;

export const generatePropertyList = (record: object) => {
    return Object.entries(record).reduce((acc, [key, val]) => {
        const property_key = camelToSnakeCase(key);
        const property_value =
            val && typeof val === "object" ? JSON.stringify(val) : val;
        acc.push({
            property_key,
            property_value,
        });
        return acc;
    }, [] as {}[]);
};

export function transformVoiceProperties(
    voiceProperties: InputProperties[] | Record<string, string>
): any {
    if (Array.isArray(voiceProperties)) {
        const map: Record<string, string> = {};
        voiceProperties?.forEach(({ key, value }) => {
            if (key) {
                map[key] = value || "";
            }
        });
        return map;
    } else {
        return Object.entries(voiceProperties)
            .map(
                ([key, value]) =>
                    key && {
                        key,
                        value,
                    }
            )
            .filter((item) => item);
    }
}

export function getValidKeyValuePair(inpProps: InputProperties[]) {
    return Object.values(inpProps).filter(({ key }) => key);
}

export const generateVoiceProperties = (vp: GetIngestedDataType[]) =>
    vp.reduce((acc, { unique_identifier, property_value = "{}" }) => {
        const transformedVP = JSON.parse(property_value || "{}");
        acc[unique_identifier] = transformVoiceProperties(transformedVP);
        return acc;
    }, {} as Record<string, InputProperties[]>);
