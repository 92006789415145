export enum EventType {
    "CHECK_UPDATE" = "CHECK_UPDATE",
    "RETURNING_GUEST" = "RETURNING_GUEST",
    "BIG_ORDER" = "BIG_ORDER",
}

// export enum DeviceType {
//     "PORTAL" = "PORTAL",
//     "WEB-APP" = "WEB-APP",
//     "BJs" = "BJs",
// }

// export interface RegisterDevicePayload {
//     clientId: string; // device_uuid() or advertising id of the device/browser
//     deviceType: DeviceType;
//     browserType?: string; // Chrome/Safari/Firefox (optional)
//     version?: string; // Browser version (e.g. 14.03) (optional)
//     os?: string; // Windows/Linux/Android (optional)
//     locale?: string; // en/US (optional)
//     restaurantCode?: string;
//     guid?: string; // User UUID if available (optional)
//     firstName?: string; // From PII info if available (optional)
//     lastName?: string; // From PII info if available (optional)
//     email?: string; // From PII info if available (optional)
//     phone?: string; // From PII info if available (optional)
//     requestId: string; // random-request-id-unique-per-request
// }

// export interface RegisterDeviceResponse {
//     deviceId: string; // UUID that is generated to uniquely identify the device
// }

// export interface UpdatesSubscribePayload {
//     qrCode?: string;
//     restaurantCode?: string;
//     source?: string; // value found in utm_source when a QR code is scanned (optional)
//     medium?: string; // value found in utm_medium when a QR code is scanned (optional)
//     requestId: string; // uuid()
//     guid?: string; // GUID saved under user-info key in local-storage (optional)
//     deviceId: string; // UUID for device (WEB-APP, PORTAL, ANY THIRD-PARTY APP)
//     deviceType: DeviceType;
//     getUpdatesFor: {
//         events: EventType[];
//     };
// }

// export interface UpdatesUnsubscribePayload {
//     deviceId: string; // UUID for device (WEB-APP, PORTAL, ANY THIRD-PARTY APP)
//     requestId: string; // Random UUID for this request
//     unsubscribeFor: {
//         events: EventType[];
//     };
// }

export interface GetEventsPayload {
    restaurantCode: string;
    alertSince: string;
    acknowledged: boolean;
    eventType?: EventType; // "RETURNING_GUEST,CHECK_UPDATE,BIG_ORDER"
    requestId: string; // Random UUID for this request
    tableNumber?: string;
}

export type Event = {
    id: number;
    alertId: string; // uuid for an specific event
    createdAt: string; // 2020-12-09T23:54:13Z
    // We might have several enums for different event types.
    // The client might decide to process the notification differently based on this event type
    // For example, if the event type is BIG_ORDER we might want to play a sound.
    eventType: EventType;
    acknowledged: boolean;
    acknowledgedAt: null;
    checkId: string;
    tableNumber: string;
    payload: {
        lastVisit: string; // 2021-01-15 02:37:40-07:00
        numberOfDaysTakenForAnalysis: number;
        totalNumberOfVisits: number;
        lastCheckId: string;
        firstName: string;
        lastName: string;
        phone: string;
        email: string;
    };
};

export interface UpdatesResponse {
    alerts: Event[];
}

export interface EventsAcknowledgementPayload {
    // acknowledgements cannot be an empty array
    acknowledgements: {
        id: number; // serial DB id of the alert table
        alertReceivedAt: string; // 2020-12-09T23:54:13Z
        alertAcknowledgedAt: string; // 2020-12-09T23:56:13Z
    }[];
    requestId: string; // Random UUID for this request
    restaurantCode: string;
}
