enum KeyboardKeys {
    ARROW_DOWN = "ArrowDown",
    ARROW_UP = "ArrowUp",
    ARROW_LEFT = "ArrowLeft",
    ARROW_RIGHT = "ArrowRight",
    HOME = "Home",
    END = "End",
    TAB = "Tab",
    ENTER = "Enter",
}
const FORCE_RELOAD_CHECK_TIME = 60_000;
const IDLE_TIME = 1000 * 60 * 30; // timeout to 30 min

export { KeyboardKeys, FORCE_RELOAD_CHECK_TIME, IDLE_TIME };
