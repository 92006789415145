import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";
import { CssBaseline } from "@material-ui/core";
import { createUploadLink } from "apollo-upload-client";
import React from "react";
import { AlertProviderProps, Provider as AlertProvider } from "react-alert";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import AlertTemplate from "./components/AlertTemplate";
import ErrorBoundaryProvider from "./components/ErrorBoundary";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import { getGoogleAnalyticsId } from "./utils/analytics";
import { readEnvVariable } from "./utils/helper-functions";

ReactGA.initialize(getGoogleAnalyticsId());
const apolloCache = new InMemoryCache();
const uploadLink = createUploadLink({
    uri: `${readEnvVariable("PRP_API")}/graphql`, // Apollo Server is served from port 4000
    headers: {
        "keep-alive": "true",
    },
});
const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem("auth-token");
    const userInfo = localStorage.getItem("user-info");
    const userProfile = JSON.parse(userInfo as string);
    return {
        headers: {
            ...headers,
            authorization: userProfile
                ? userProfile.userProfile.authorizationToken
                : token,
        },
    };
});
const client = new ApolloClient({
    cache: apolloCache,
    link: authLink.concat(uploadLink),
});

const options: AlertProviderProps = {
    template: AlertTemplate,
    position: "top center",
    offset: "130px",
    transition: "scale",
};

const render = () => {
    const App = require("./pages/App").default;

    ReactDOM.render(
        <React.StrictMode>
            <CssBaseline />
            <ApolloProvider client={client}>
                <ApolloHooksProvider client={client}>
                    <Provider store={store}>
                        <ErrorBoundaryProvider>
                            <AlertProvider {...options}>
                                <App />
                            </AlertProvider>
                        </ErrorBoundaryProvider>
                    </Provider>
                </ApolloHooksProvider>
            </ApolloProvider>
        </React.StrictMode>,
        document.getElementById("root")
    );
};

render();

if (process.env.NODE_ENV === "development" && module.hot) {
    module.hot.accept("./pages/App", render);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
