import React from "react";
import { Button, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { MaterialTableProps } from "material-table";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
    CustomizedMaterialTable,
    DATA_TABLE_DEFAULT_STYLING,
} from "../../utils/data-tables";
import { userTableSelector, TreeUser } from "../../selectors/userManagement";
import { UserProfile } from "../../generated-interfaces/graphql";
import { selectedRestaurantCodeSelector } from "../../selectors/restaurant";

const useStyles = makeStyles((theme: Theme) => ({
    ...DATA_TABLE_DEFAULT_STYLING(theme),
}));

export default function Restaurants() {
    const classes = useStyles();
    const history = useHistory();
    const users = useSelector(userTableSelector);
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);
    const restaurantTableConfig: MaterialTableProps<TreeUser> = {
        columns: [
            { title: "Name", field: "username" },
            {
                title: "Restaurant Group",
                field: "restaurantGroupNames",
            },
            {
                title: "User Name(Log In)",
                field: "email",
            },
            {
                title: "User Type",
                render: (data) => {
                    if (
                        !data.restaurantGroupRoles ||
                        data.restaurantGroupRoles.length === 0
                    )
                        return "";
                    const itemNames = data.restaurantGroupRoles.map(
                        (item) => item.role.permission
                    );
                    if (data.restaurantGroupRoles.length <= 3) {
                        return itemNames.join(", ");
                    }
                    return `${itemNames.slice(0, 3).join(", ")}, +${
                        itemNames.length - 3
                    }`;
                },
            },
        ],
        data: Object.values(users).map((o) => ({ ...o })),
        actions: [
            {
                icon: () => <EditIcon />,
                tooltip: "Edit User",
                onClick: (event, rowData) => {
                    console.log("click user", rowData);
                    history.push(
                        `/${restaurantCode}/user-management/users/${
                            (rowData as UserProfile).id
                        }`
                    );
                },
            },
        ],
        options: {
            actionsColumnIndex: -1,
        },
    };

    return (
        <div>
            <Grid className={classes.header} container justify="space-between">
                <Grid item>
                    <Typography variant="h4" color="primary">
                        Users
                    </Typography>
                </Grid>
                <Grid item>
                    <span />
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<AddIcon />}
                        component={Link}
                        to={"users/new"}
                        className={classes.newBtn}
                        disableElevation
                    >
                        New user
                    </Button>
                </Grid>
            </Grid>
            <CustomizedMaterialTable {...restaurantTableConfig} />
        </div>
    );
}
