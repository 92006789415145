import { createLogger, format, transports } from "winston";
import { UserState } from "../reducers/userReducer";
import store from "../store";

// We have to wait for the store to be instantiated before we can use it
// So we call this function once the main App.tsx has mounted
export const updateLogger = () => {
    setTimeout(() => {
        logger.configure(createCustomLogger());
    });
};

let userState: UserState;
const meta = () => {
    if (store) {
        if (!userState) {
            store.subscribe(() => {
                userState = store.getState().user;
            });
        }
        return {
            get userId() {
                return userState?.userProfile?.id;
            },
            get email() {
                return userState?.userProfile?.email;
            },
        };
    }
    return {};
};

const { combine, timestamp } = format;

const createCustomLogger = () => {
    return {
        level: "debug",
        format: combine(timestamp(), format.json()),
        defaultMeta: meta(),
        transports: [
            new transports.Console({
                format: format.colorize(),
            }),
        ],
    };
};

const logger = createLogger(createCustomLogger());

export default logger;
