export enum MenuType {
    modifier = "modifier",
    menuItem = "menuItem",
    category = "category",
    mealPeriod = "mealPeriod",
}

export const componentTypeConfig: Record<
    keyof typeof MenuType,
    { title: string; url: string }
> = {
    modifier: { title: "modifier group", url: "modifier-groups" },
    menuItem: { title: "item", url: "items" },
    category: { title: "category", url: "categories" },
    mealPeriod: { title: "meal period", url: "meal-periods" },
};
