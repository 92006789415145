import { MenuItem } from "../../generated-interfaces/graphql";

export const getItemAvailabilityText = (item: MenuItem) => {
    if (item.available) {
        return "Available";
    } else if (item.unavailableUntil) {
        return "Available Tomorrow";
    }
    return "Sold Out";
};
