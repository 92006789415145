import { PayloadAction } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { put, select, takeEvery } from "redux-saga/effects";
import { Category } from "../generated-interfaces/graphql";
import { MENU_EDITOR_ROUTES } from "../pages/menu-editor/menu-editor-routes";
import { duplicateCategory, getMenuItems } from "../reducers/menuReducer";
import { selectedRestaurantCodeSelector } from "../selectors/restaurant";
import { IDuplicatePayload } from "../types/menu";
import logger from "../utils/logger";
import { getSdk } from "../utils/network";

function* duplicateCategorySaga(action: PayloadAction<IDuplicatePayload>) {
    logger.debug("Duplicating category");
    const sdk = getSdk();
    const { id: sourceCategoryId, successCallback, errorCallback } =
        action?.payload || {};
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );

        const dupCategoryResponse: {
            duplicateCategory: Category;
        } = yield sdk.duplicateCategory({
            restaurantCode,
            sourceCategoryId,
            version: 0,
        });

        yield put(
            push(
                MENU_EDITOR_ROUTES(restaurantCode)[
                    "duplicateCategory"
                ].path.replace(":id", dupCategoryResponse.duplicateCategory.id)
            )
        );

        yield put(getMenuItems());
        successCallback?.();

        logger.debug("Duplicate category successful");
    } catch (error) {
        errorCallback?.();
        logger.error("Duplicate category failed", error);
    }
}

export default function* categorySaga() {
    yield takeEvery(duplicateCategory.toString(), duplicateCategorySaga);
}
