import {
    Grid,
    IconButton,
    makeStyles,
    Menu,
    MenuItem as MenuDropdownItem,
    Paper,
    Tab,
    Tabs,
    Theme,
    Tooltip,
    Typography,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { MaterialTableProps } from "material-table";
import QueryString from "query-string";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    MenuItem,
    UpdateMenuItemAvailabilityInput,
} from "../generated-interfaces/graphql";
import { useLoadMenu, useReadOnly, useRecursiveTimeout } from "../hooks";
import {
    getMenuItems,
    updateMenuItemAvailability,
} from "../reducers/menuReducer";
import { selectDidInitialMenuLoad } from "../selectors/menu";
import { tabActiveSelector } from "../selectors/user";
import { CustomizedMaterialTable } from "../utils/data-tables";
import { getStartOfTomorrow } from "../utils/helper-functions";
import { menuItemsSelector } from "../utils/menu";

const useStyles = makeStyles((theme: Theme) => ({
    childComponent: {
        padding: theme.spacing(3, 4),
        backgroundColor: theme.palette.background.paper,
        marginTop: "2px",
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    tabsList: {
        backgroundColor: theme.palette.background.default,
        "& .Mui-selected": {
            color: theme.palette.primary.main,
        },
    },
    tab: {
        minWidth: "150px",
    },
    mirrorRestuarant: {
        color: "red",
        fontWeight: 700,
        fontSize: 24,
        padding: 10,
        textAlign: "right",
    },
}));

type AvailabilityTabSelection = "Available" | "Unavailable";

interface ContextButtonProps {
    row: MenuItem;
}

function ContextButton(props: ContextButtonProps) {
    const [anchorRow, setAnchorRow] = React.useState<null | {
        el: HTMLElement;
        row: MenuItem;
    }>(null);

    const handleClick = (
        event: React.MouseEvent<HTMLButtonElement>,
        row: MenuItem
    ) => {
        setAnchorRow({ el: event.currentTarget, row });
    };

    const handleClose = () => {
        setAnchorRow(null);
    };
    return (
        <>
            <IconButton onClick={(e) => handleClick(e, props.row)}>
                <MoreVertIcon />
            </IconButton>
            <ContextMenu
                onClose={handleClose}
                anchorEl={anchorRow?.el}
                row={anchorRow?.row}
            />
        </>
    );
}

interface ContextMenuProps {
    anchorEl?: HTMLElement;
    row?: MenuItem;
    onClose: () => void;
}

function ContextMenu(props: ContextMenuProps) {
    const dispatch = useDispatch();
    const dropdownButton = (
        title: string,
        action: "unavailable_indefinitely" | "available" | "available_tomorrow"
    ) => {
        const onOptionClick = () => {
            if (props.row) {
                const data: UpdateMenuItemAvailabilityInput = {
                    restaurantCode: "",
                    id: parseInt(props.row.id),
                    availableLimitedQuantity: null,
                    available: props.row.available,
                    unavailableUntil: props.row.unavailableUntil,
                    rebuildCache: true,
                };

                switch (action) {
                    case "available":
                        data.available = true;
                        data.unavailableUntil = null;
                        break;

                    case "available_tomorrow":
                        data.available = false;
                        data.unavailableUntil = getStartOfTomorrow();
                        break;

                    case "unavailable_indefinitely":
                        data.available = false;
                        data.unavailableUntil = null;
                        break;

                    default:
                        break;
                }
                dispatch(updateMenuItemAvailability(data));
            }
            props.onClose();
        };
        return (
            <MenuDropdownItem onClick={onOptionClick}>{title}</MenuDropdownItem>
        );
    };

    if (!props.anchorEl || !props.row) {
        return null;
    }

    const menuButtons = props.row.available
        ? [
              dropdownButton("Mark Unavailable (Today)", "available_tomorrow"),
              dropdownButton(
                  "Mark Unavailable (Indefinitely)",
                  "unavailable_indefinitely"
              ),
          ]
        : [dropdownButton("Mark Available", "available")];
    return (
        <Menu
            anchorEl={props.anchorEl}
            open={Boolean(props.anchorEl)}
            onClose={props.onClose}
        >
            {menuButtons}
        </Menu>
    );
}

function ItemAvailability() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const didInitialLoad = useSelector(selectDidInitialMenuLoad);
    const history = useHistory();
    const queryParams = QueryString.parse(history.location.search);
    const isStaffTablet = queryParams["isStaffTablet"] === "true";
    const tabActive = useSelector(tabActiveSelector);
    const menuItems = useSelector(menuItemsSelector);
    const [currentTab, setCurrentTab] = useState<AvailabilityTabSelection>(
        "Available"
    );
    const [filteredItems, setFilteredItems] = useState<MenuItem[]>([]);
    const { isMirrorRestro, isReadOnly } = useReadOnly();
    const { loadMenu } = useLoadMenu();

    useEffect(() => {
        if (!didInitialLoad) {
            dispatch(getMenuItems());
        }
    }, [didInitialLoad, dispatch]);

    useEffect(() => {
        const shouldBeAvailable = currentTab === "Available";
        // Shallow copy objects b/c they are immutable
        setFilteredItems(
            Object.values(menuItems)
                .filter((item) => item.available === shouldBeAvailable)
                .map((o) => ({ ...o }))
        );
    }, [menuItems, currentTab]);

    const onTabChange = (
        event: ChangeEvent<{}>,
        value: AvailabilityTabSelection
    ) => {
        setCurrentTab(value);
    };

    const fetchMenuItems = (): Promise<any> => {
        if (!isStaffTablet && !tabActive) return Promise.resolve(true);

        return Promise.resolve(loadMenu());
    };

    useRecursiveTimeout(fetchMenuItems, 30 * 1000);

    const unavailableTodayTooltip = (
        <Tooltip title="Will be marked as available tomorrow">
            <span>Not available today</span>
        </Tooltip>
    );

    const tabText =
        currentTab === "Available"
            ? "Available Items"
            : "Unavailable Items (86)";

    const menuItemsTableConfig: MaterialTableProps<MenuItem> = {
        columns: [
            { title: "Display Name", field: "name" },
            {
                title: "Status",
                field: "available",
                render: (data) => {
                    if (data.available) {
                        return "Available";
                    } else if (data.availableLimitedQuantity === 0) {
                        return "Count is zero";
                    } else if (data.unavailableUntil !== null) {
                        return unavailableTodayTooltip;
                    }
                    return "Not available";
                },
            },
        ],
        data: filteredItems,
    };

    if (isReadOnly) {
        menuItemsTableConfig.columns.push({
            title: "Edit Item",
            field: "available",
            render: (data) => <ContextButton row={data} />,
            align: "right",
        });
    }

    return (
        <React.Fragment>
            <Paper elevation={0}>
                <Tabs
                    value={currentTab}
                    onChange={onTabChange}
                    aria-label="Item Availability Nav"
                    className={classes.tabsList}
                    indicatorColor="secondary"
                >
                    <Tab value="Available" label="Available Items" />
                    <Tab value="Unavailable" label="Unavailable Items (86)" />
                </Tabs>
            </Paper>
            {isMirrorRestro && (
                <div className={classes.mirrorRestuarant}>
                    Mirror Restuarant
                </div>
            )}
            <div className={classes.childComponent}>
                <Grid item>
                    <Typography variant="h4" color="primary">
                        {tabText}
                    </Typography>
                </Grid>
                <CustomizedMaterialTable {...menuItemsTableConfig} />
            </div>
        </React.Fragment>
    );
}

export default ItemAvailability;
