import { Box, CircularProgress, styled } from "@material-ui/core";
import React from "react";

const DisabledBackground = styled(Box)({
    width: "100%",
    height: "100%",
    position: "fixed",
    background: "#ccc",
    opacity: 0.5,
    top: 0,
    left: 0,
    zIndex: 100,
});

export function CircularLoading() {
    return (
        <>
            <CircularProgress
                size={70}
                style={{
                    position: "fixed",
                    left: "50%",
                    top: "50%",
                    zIndex: 2,
                }}
            />
            <DisabledBackground />
        </>
    );
}
