import { ThemeProvider } from "@material-ui/core";
import { ConnectedRouter } from "connected-react-router";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";
import React, { memo, ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, RouteProps } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import ConfirmDialog from "../components/ConfirmDialog";
import { AppWrapper } from "../components/layout/Wrappers/AppWrapper";
import { RolePermissions } from "../generated-interfaces/graphql";
import { useApp, useRecursiveTimeout } from "../hooks";
import {
    eventsAcknowledgementAction,
    EVENTS_ACTIONS,
    getEventsAction,
} from "../reducers/eventsReducer";
import { getRestaurantRolesAction } from "../reducers/restaurantReducer";
import { RootState } from "../reducers/rootReducer";
import {
    checkAuthStatusAction,
    loadUserState,
    USER_ACTIONS,
} from "../reducers/userReducer";
import { AppRoute, findRouteWithAccess, ROUTES } from "../routes";
import {
    eventsAcknowledgedSelector,
    eventsAlertSinceSelector,
    eventsToAcknowledgeSelector,
} from "../selectors/eventsSelector";
import {
    restaurantInfoSelector,
    selectedRestaurantAccessSelector,
    selectedRestaurantCodeSelector,
} from "../selectors/restaurant";
import { history } from "../store";
import { Event, EventType } from "../types/events";
import { UI_THEME } from "../utils/branding";
import logger, { updateLogger } from "../utils/logger";
import { hasRoleAccess } from "../utils/restaurants";

const App = () => {
    const dispatch = useDispatch();
    useApp();
    const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);
    const userRolesHaveLoaded = useSelector(
        (state: RootState) =>
            !state.user.isLoggedIn || state.restaurant.userRolesHaveLoaded
    );
    const didAttemptPreviousStateLoad = useSelector(
        (state: RootState) => state.user.didAttemptPreviousStateLoad
    );
    const selectedRestaurantAccess = useSelector(
        selectedRestaurantAccessSelector
    );
    const restaurantInfo = useSelector(restaurantInfoSelector);
    const [eventToAck, setEventToAck] = useState<Event | null>(null);
    const eventsAcknowledged = useSelector(eventsAcknowledgedSelector);
    const eventsToAcknowledge = useSelector(eventsToAcknowledgeSelector);
    const eventsAlertSince = useSelector(eventsAlertSinceSelector);
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);

    dayjs.extend(advancedFormat);
    dayjs.extend(utc);

    useEffect(() => {
        dispatch({ type: loadUserState.toString() });
        dispatch({ type: checkAuthStatusAction.toString() });
        dispatch({ type: getRestaurantRolesAction.toString() });
        updateLogger();
    }, [dispatch]);

    const checkAuthStatus = (): Promise<any> => {
        if (isLoggedIn) {
            return Promise.resolve(
                dispatch({ type: checkAuthStatusAction.toString() })
            );
        }
        return Promise.resolve(true);
    };
    const authTime = process.env.AUTH_TIME_OUT_IN_SEC || "120";
    useRecursiveTimeout(checkAuthStatus, parseInt(authTime) * 1000);

    useEffect(() => {
        window.addEventListener("focus", onFocus);
        window.addEventListener("blur", onBlur);

        return () => {
            window.removeEventListener("focus", onFocus);
            window.removeEventListener("blur", onBlur);
        };
    });

    const onFocus = () => {
        dispatch({
            type: USER_ACTIONS.setTabActive.toString(),
            payload: true,
        });
    };

    const onBlur = () => {
        dispatch({
            type: USER_ACTIONS.setTabActive.toString(),
            payload: false,
        });
    };
    const eventLoopTimeOuț = process.env.EVENT_LOOP_TIME_OUT || "86400000";
    const fetchEvents = (): Promise<any> => {
        if (
            isLoggedIn &&
            !!restaurantCode &&
            restaurantInfo?.restaurantSettings?.isAlertingEnabled
        ) {
            //only get alerts in the past 30 mins if it is more than 30 mins since last alert
            const alertSince =
                eventsAlertSince &&
                dayjs.utc().diff(eventsAlertSince, "minutes") <= 30
                    ? eventsAlertSince
                    : dayjs.utc().subtract(30, "minute").format();
            return Promise.resolve(
                dispatch(
                    getEventsAction({
                        restaurantCode,
                        acknowledged: false,
                        alertSince: alertSince,
                        eventType: EventType.RETURNING_GUEST,
                    })
                )
            );
        }
        return Promise.resolve(true);
    };

    useRecursiveTimeout(fetchEvents, parseInt(eventLoopTimeOuț));

    // keeping it in App.tsx as we need this for Android app as well which has its own login screen
    const acknowledgeEvents = (): Promise<any> => {
        if (
            isLoggedIn &&
            !!restaurantCode &&
            restaurantInfo?.restaurantSettings?.isAlertingEnabled
        ) {
            if (Object.values(eventsAcknowledged).length > 0) {
                return Promise.resolve(
                    dispatch(
                        eventsAcknowledgementAction({
                            acknowledgements: Object.values(eventsAcknowledged),
                            restaurantCode: restaurantCode,
                        })
                    )
                );
            }
        }
        return Promise.resolve(true);
    };

    useRecursiveTimeout(acknowledgeEvents, 1000 * 60 * 30);

    // set event to acknowledge
    useEffect(() => {
        if (isLoggedIn && eventsToAcknowledge.length && !eventToAck) {
            setEventToAck(eventsToAcknowledge[0]);
        }
    }, [eventsToAcknowledge, eventToAck, isLoggedIn]);

    const generateRoute = (routeParams: AppRoute) => {
        // Don't force a redirect until we have tried to load the user state
        if (!didAttemptPreviousStateLoad || !userRolesHaveLoaded) {
            return null;
        }
        const WrappedComponent = routeParams.disableAppWrapper ? (
            routeParams.component
        ) : (
            <AppWrapper>{routeParams.component}</AppWrapper>
        );
        const privateRouteParams = {
            isLoggedIn,
            protectedRoute: routeParams.protected,
            path: routeParams.path,
            accessLevelNeeded: routeParams.accessLevelNeeded,
            selectedRestaurantAccess,
        };
        return (
            <PrivateRoute key={routeParams.path} {...privateRouteParams}>
                {WrappedComponent}
            </PrivateRoute>
        );
    };

    return (
        <ThemeProvider theme={UI_THEME}>
            <ConnectedRouter history={history}>
                <Switch>
                    {didAttemptPreviousStateLoad && (
                        <Route exact={true} path="/">
                            <Redirect to={`/${restaurantCode}/menu-editor`} />
                        </Route>
                    )}
                    {Object.values(ROUTES).map((route) => generateRoute(route))}
                </Switch>
                {isLoggedIn && eventToAck && eventToAck.payload && (
                    <ConfirmDialog
                        title="Returning Customer"
                        content={
                            <div>
                                {eventToAck.payload.firstName}{" "}
                                {eventToAck.payload.lastName} is a returning
                                guest seated at Table {eventToAck.tableNumber}.
                                <br></br>
                                Number of visits in the last{" "}
                                {
                                    eventToAck.payload
                                        .numberOfDaysTakenForAnalysis
                                }{" "}
                                days: {eventToAck.payload.totalNumberOfVisits}
                                <br></br>
                                Last Visit:{" "}
                                {dayjs
                                    .utc()
                                    .diff(
                                        eventToAck.payload.lastVisit,
                                        "days"
                                    )}{" "}
                                days ago,{" "}
                                {dayjs
                                    .utc(
                                        eventToAck.payload.lastVisit.substring(
                                            0,
                                            23
                                        )
                                    )
                                    .local()
                                    .format("dddd, MMMM Do, YYYY")}
                            </div>
                        }
                        open={!!eventToAck}
                        showCancelButton={false}
                        confirmText="OK"
                        onSuccess={() => {
                            setEventToAck(null);
                            dispatch(
                                EVENTS_ACTIONS.addEventToAcknowledge({
                                    id: eventToAck.id,
                                    alertAcknowledgedAt: dayjs.utc().format(),
                                    alertReceivedAt: dayjs(
                                        eventToAck.createdAt
                                    ).format("YYYY-MM-DDTHH:mm:ss[Z]"),
                                })
                            );
                        }}
                    />
                )}
            </ConnectedRouter>
        </ThemeProvider>
    );
};

interface PrivateRouteProps extends RouteProps {
    protectedRoute: boolean;
    accessLevelNeeded?: RolePermissions;
    selectedRestaurantAccess: RolePermissions | null;
    isLoggedIn: boolean;
    children: ReactNode;
}

function PrivateRoute({
    protectedRoute,
    accessLevelNeeded,
    selectedRestaurantAccess,
    isLoggedIn,
    path,
    children,
    ...rest
}: PrivateRouteProps) {
    const routeRender = ({ location }: RouteComponentProps<any>) => {
        const redirectParams = {
            pathname: "/login",
            state: { from: location },
        };
        const redirect = <Redirect to={redirectParams} />;
        if (protectedRoute && !isLoggedIn) {
            return redirect;
        }
        if (
            !hasRoleAccess(accessLevelNeeded || null, selectedRestaurantAccess)
        ) {
            logger.debug(
                "Attempting to load page user does not have access to",
                { path, selectedRestaurantAccess }
            );
            if (selectedRestaurantAccess) {
                const nextBestRoute = findRouteWithAccess(
                    selectedRestaurantAccess
                );
                if (nextBestRoute) {
                    return <Redirect to={{ pathname: nextBestRoute.path }} />;
                }
                logger.warn("Could not find new route to redirect user to");
            }
            return redirect; // TODO we are sending them to login, but should we?
        }
        return children;
    };
    return <Route {...rest} render={routeRender} />;
}

export default memo(App);
