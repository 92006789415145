import {
    Category,
    ModifierGroup,
    UpdateMenuItemInput,
    UpdateTimePeriodInput,
} from "../generated-interfaces/graphql";
import { CallbackFunction } from "../utils/types";

export type CategoryType = Category & CallbackFunction;
export type MenuItemType = UpdateMenuItemInput & CallbackFunction;
export type ModifierGroupType = ModifierGroup & CallbackFunction;
export type TimePeriodInputType = UpdateTimePeriodInput &
    CallbackFunction & { id?: number };

export type BasicAPIResponseType = { status: string; message: string };

export type UserSession = {
    user_session_id: string;
    source_module: string;
    email_id: string;
    first_name: string;
    last_name: string;
    user_name: string;
    unique_user_id: string;
    force_reload?: number;
    restaurant_code?: string;
    active: number;
};

export enum RequestStatus {
    SUCCESS,
    PENDING,
    FAILED,
}

export enum ComponentType {
    modifier = "Modifier Group",
    menuItem = "MenuItem",
    category = "Category",
}

export interface IDuplicatePayload extends CallbackFunction {
    id: number;
}
export type IActiveUserReqType = CallbackFunction & {
    restaurantCode: string;
};
