import { Button, makeStyles, Theme } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import ReadOnlyWrapper from "../../components/ReadOnlyWrapper";
import { componentTypeConfig, MenuType } from "../../constants/menu";
import { selectedRestaurantCodeSelector } from "../../selectors/restaurant";
import { DATA_TABLE_DEFAULT_STYLING } from "../../utils/data-tables";

const useStyles = makeStyles((theme: Theme) => ({
    ...DATA_TABLE_DEFAULT_STYLING(theme),
}));

export default function AddNewItem({ type }: { type: keyof typeof MenuType }) {
    const classes = useStyles();
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);
    const { title, url } = componentTypeConfig[type];
    const { search } = useLocation();
    return (
        <ReadOnlyWrapper
            element={Button}
            variant="contained"
            color="secondary"
            startIcon={<AddIcon />}
            component={Link}
            to={{
                pathname: `/${restaurantCode}/menu-editor/${url}/new`,
                search,
            }}
            className={classes.newBtn}
            disableElevation
            id={`new-btn-${type}`}
        >
            New {title}
        </ReadOnlyWrapper>
    );
}
