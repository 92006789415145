import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    ChangePaswordInput,
    CompletePasswordResetMutationVariables,
    LoginQueryQueryVariables,
    Query,
    UserProfile,
} from "../generated-interfaces/graphql";
import { IActiveUserReqType, UserSession } from "../types/menu";
import {
    saveGoogleId,
    saveSessionID,
    saveUserState,
} from "../utils/local-storage";

import { setAuthToken } from "../utils/network";
import { IRequestType, StringOrNull } from "../utils/types";

export interface UserState {
    userProfile: UserProfile | null;
    isLoggedIn: boolean;
    token: StringOrNull;
    didAttemptPreviousStateLoad: boolean;
    isResetCodeSent: boolean;
    resetPasswordSuccessCode: string;
    tabActive: boolean;
    userSessions: UserSession[] | null;
    qrCodeURL: string | null;
}

const initialState: UserState = {
    userProfile: null,
    isLoggedIn: false,
    token: null,
    didAttemptPreviousStateLoad: false,
    isResetCodeSent: false,
    resetPasswordSuccessCode: "",
    tabActive: true,
    userSessions: null,
    qrCodeURL: null,
};

export const loginAction = createAction<LoginQueryQueryVariables>("LOGIN");
export const checkAuthStatusAction = createAction("CHECK_AUTH_STATUS");
export const startPasswordreset = createAction<{ email: string }>(
    "START_PASSWORD_RESET"
);
export const changePassword = createAction<ChangePaswordInput>(
    "CHANGE_PASSWORD"
);
export const completePasswordReset = createAction<CompletePasswordResetMutationVariables>(
    "COMPLETE_PASSWORD_RESET"
);
export const loadUserState = createAction("LOAD_USER_STATE");
export const createUserSessionAction = createAction("CREATE_USER_SESSION");
export const updateUserSessionAction = createAction<string>(
    "UPDATE_USER_SESSION"
);
export const retrieveUserSessionAction = createAction<IActiveUserReqType>(
    "GET_USER_SESSION"
);
export const forceLogOutAction = createAction("FORCE_LOGOUT_SESSION");
export const deleteUserSessionAction = createAction<string>(
    "DELETE_USER_SESSION"
);
export const updateHeartBeatAction = createAction<string>(
    "UPDATE_HEARTBEAT_SESSION"
);
export const verifyGAuthTokenAction = createAction<IRequestType>(
    "VERIFY_GOOGLE_AUTH"
);
export const mfLoginAction = createAction<IRequestType>("MF_LOGIN");
export const resetMFLoginAction = createAction<IRequestType>("RESET_MF_LOGIN");

export const userState = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        loginSuccess: (state, action: PayloadAction<Query["login"]>) => {
            state.userProfile = action.payload;
            state.isLoggedIn = true;
            saveUserState(state);
        },
        logout: (state) => {
            state.userProfile = null;
            state.isLoggedIn = false;
            state.token = null;
            state.userSessions = null;
            saveSessionID(null);
            state.qrCodeURL = null;
            setAuthToken(null);
            saveGoogleId(null);
            saveUserState(state);
        },
        authCheckSuccess: (
            state,
            action: PayloadAction<Query["authStatus"]>
        ) => {
            if (state.userProfile) {
                state.token = action.payload.authorizationToken;
                state.userProfile.authorizationToken =
                    action.payload.authorizationToken;
                saveUserState(state);
            }
        },
        loadUserStateSuccess: (state) => {
            state.didAttemptPreviousStateLoad = true;
        },
        passwordResetCodeSuccess: (state) => {
            state.isResetCodeSent = true;
        },
        resetCompleteSuccess: (state) => {
            state.resetPasswordSuccessCode = "SUCCESS";
        },
        resetCompleteFail: (state) => {
            state.resetPasswordSuccessCode = "FAIL";
        },
        setTabActive: (state, action: PayloadAction<boolean>) => {
            state.tabActive = action.payload;
        },
        setSelectedRestaurantUserSession: (
            state,
            action: PayloadAction<UserSession[]>
        ) => {
            state.userSessions = action.payload;
        },
        setMFLoginState: (
            state,
            action: PayloadAction<Query["verifyGAuthToken"]>
        ) => {
            const { __typename, qrCodeURL, ...userProfile } = action.payload;
            if (qrCodeURL) {
                state.qrCodeURL = qrCodeURL;
            }
            if (userProfile) {
                state.userProfile = userProfile;
            }
        },
        resetMFLoginState: (state) => {
            state.qrCodeURL = "";
            state.userProfile = null;
        },
    },
});

export const USER_ACTIONS = userState.actions;
