import {
    Button,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import { Mail, Message, Visibility, VisibilityOff } from "@material-ui/icons";
import { TextField, TextFieldProps } from "mui-rff";
import * as QueryString from "query-string";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";
import { Image } from "../../components/layout/Image";
import { CompletePasswordResetMutationVariables } from "../../generated-interfaces/graphql";
import prestoLogo from "../../images/presto_logo_white.png";
import { completePasswordReset } from "../../reducers/userReducer";
import { resetPasswordCompleteCodeSelector } from "../../selectors/user";

const useStyles = makeStyles((theme: Theme) => ({
    background: {
        width: "100vw",
        height: "100vh",
        backgroundColor: theme.palette.primary.main,
        position: "absolute",
        zIndex: -999,
    },
    prestoLogo: {
        marginBottom: theme.spacing(4),
    },
    gridContainer: {
        height: "100vh",
        backgroundColor: theme.palette.primary.main,
        minHeight: "480px",
        paddingBottom: "100px",
        "@media (max-height: 600px)": {
            paddingBottom: 0,
        },
    },
    mainContainer: {
        flex: 1,
    },
    form: {
        color: "white",
        margin: theme.spacing(2),
        "& .MuiTextField-root": {
            marginBottom: theme.spacing(2),
        },
    },
    loginFormInputs: {
        color: "#fff",
        borderBottom: "2px solid white",
        backgroundColor: "#18394e !important",
    },
    loginFormLabel: {
        color: "#fff",
        "&.Mui-focused": {
            color: "#fff",
        },
        "& .MuiInputLabel-asterisk": {
            display: "none",
        },
    },
    contactContainer: {
        marginTop: theme.spacing(2),
    },
    contact: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1, 0),
        "& a": {
            textDecoration: "none",
            color: theme.palette.primary.dark,
        },
    },
    emailInput: {
        color: "#fff",
        marginBottom: "36px",
        "& .Mui-error": {
            fontSize: 16,
        },
    },
    input: {
        color: "#fff",
        backgroundColor: "#18394e !important",
        borderBottom: "2px solid white",
        "& .Mui-focused": {
            color: "#fff",
        },
        "& .MuiInputLabel-asterisk": {
            display: "none",
        },
    },
    inputLabel: {
        color: "white",
        "&.Mui-focused": {
            color: "white",
        },
    },
    logInLink: {
        color: theme.palette.primary.dark,
    },
}));

function ResetPassword(props: StateProps) {
    const classes = useStyles();
    const history = useHistory();
    let resetPasswordCompleteCode = useSelector(
        resetPasswordCompleteCodeSelector
    );
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassowrd, setShowConfirmPassword] = useState(false);

    // Read Email and Reset Code from URL.
    const queryParams = QueryString.parse(history.location.search);
    const email = queryParams["email"] as string;
    const resetCode = queryParams["resetCode"] as string;

    const baseInputProps: Partial<TextFieldProps> = {
        required: true,
        type: "password",
        variant: "filled",
        InputLabelProps: {
            className: classes.inputLabel,
        },
        placeholder: "Type here",
        fullWidth: true,
        className: classes.emailInput,
    };

    async function validate(values: any) {
        return;
    }

    const onSubmit = (values: any) => {
        if (values.password !== values.confirmPassword) {
            return {
                confirmPassword:
                    "Confirm Password should be same with Password",
            };
        }
        props.completePasswordReset({
            email,
            resetCode,
            ...values,
        });
    };

    return (
        <React.Fragment>
            <div className={classes.background} />
            <Grid
                className={classes.gridContainer}
                container
                direction="column"
                alignItems="center"
            >
                <Grid
                    container
                    className={classes.mainContainer}
                    direction="column"
                    alignItems="center"
                    justify="space-around"
                >
                    <Grid item>
                        <Image
                            className={classes.prestoLogo}
                            url={prestoLogo}
                            alt="Presto Logo"
                        />
                    </Grid>
                    <Grid item>
                        <div className={classes.form}>
                            <Form
                                onSubmit={onSubmit}
                                validate={validate}
                                render={({
                                    handleSubmit,
                                    values,
                                    valid,
                                    dirty,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Typography
                                            variant="h5"
                                            color="primary"
                                            style={{ marginBottom: "16px" }}
                                        >
                                            Reset Your Password
                                        </Typography>
                                        {resetPasswordCompleteCode ===
                                            "FAIL" && (
                                            <Typography
                                                style={{ marginBottom: "20px" }}
                                            >
                                                There is a problem with this
                                                reset link. Please verify that
                                                it is correct and contact
                                                support if you need assistance.
                                            </Typography>
                                        )}
                                        {resetPasswordCompleteCode !==
                                        "SUCCESS" ? (
                                            <>
                                                <TextField
                                                    {...baseInputProps}
                                                    name="password"
                                                    placeholder="Enter New Password"
                                                    label="New Password"
                                                    type={
                                                        showPassword
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    InputProps={{
                                                        className:
                                                            classes.input,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={() =>
                                                                        setShowPassword(
                                                                            !showPassword
                                                                        )
                                                                    }
                                                                    edge="end"
                                                                    tabIndex={
                                                                        -1
                                                                    }
                                                                >
                                                                    {showPassword ? (
                                                                        <Visibility
                                                                            style={{
                                                                                color:
                                                                                    "white",
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <VisibilityOff
                                                                            style={{
                                                                                color:
                                                                                    "white",
                                                                            }}
                                                                        />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <TextField
                                                    {...baseInputProps}
                                                    name="confirmPassword"
                                                    placeholder="Enter Confirm Password"
                                                    label="Confirm New Password"
                                                    type={
                                                        showConfirmPassowrd
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    InputProps={{
                                                        className:
                                                            classes.input,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={() =>
                                                                        setShowConfirmPassword(
                                                                            !showConfirmPassowrd
                                                                        )
                                                                    }
                                                                    edge="end"
                                                                    tabIndex={
                                                                        -1
                                                                    }
                                                                >
                                                                    {showConfirmPassowrd ? (
                                                                        <Visibility
                                                                            style={{
                                                                                color:
                                                                                    "white",
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <VisibilityOff
                                                                            style={{
                                                                                color:
                                                                                    "white",
                                                                            }}
                                                                        />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <Button
                                                    type="submit"
                                                    color="secondary"
                                                    variant="contained"
                                                    style={{ float: "right" }}
                                                    disableElevation
                                                >
                                                    Update Password
                                                </Button>
                                            </>
                                        ) : (
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    fontSize: 16,
                                                }}
                                            >
                                                {
                                                    "Your password is successfully reset. Please go to"
                                                }
                                                <Link
                                                    href="/login"
                                                    className={
                                                        classes.logInLink
                                                    }
                                                >
                                                    {" Login Page "}
                                                </Link>
                                                {
                                                    "and sign in with your new password"
                                                }
                                            </div>
                                        )}
                                    </form>
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item />
                </Grid>
                <Grid item className={classes.contactContainer}>
                    <Typography className={classes.contact}>
                        <Mail
                            color="secondary"
                            style={{ marginRight: "5px" }}
                        />
                        <a href="mailto:contactless.support@presto.com">
                            contactless.support@presto.com
                        </a>
                    </Typography>
                    <Typography className={classes.contact}>
                        <Message
                            color="secondary"
                            style={{ marginRight: "5px" }}
                        />
                        <a href="tel: +14155489347">+1 (415) 548 - 9347</a>
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        completePasswordReset: (
            credentials: CompletePasswordResetMutationVariables
        ) => dispatch(completePasswordReset(credentials)),
    };
};

const connected = connect(null, mapDispatchToProps);

type StateProps = ConnectedProps<typeof connected>;

export default connected(ResetPassword);
