import dayjs from "dayjs";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { Order } from "../types/order";
import { ExtraValues } from "./types";
export const createUuid = () => {
    return uuidv4();
};

export const getFormattedHour = (time: number) => {
    const hour = (time - (time % 100)) / 100;
    const minutes = time % 100;

    if (time === 0) return "12:00 AM";
    return hour >= 12
        ? `${hour === 12 ? 12 : hour - 12}:${getTwoDigitStr(minutes)} PM`
        : `${hour}:${getTwoDigitStr(minutes)} AM`;
};

export const stringToHourNumber = (strTime: string) => {
    const spitStringTime = strTime.split(":");
    const hour = parseFloat(spitStringTime[0]);
    const minutes = parseFloat(spitStringTime[1]);

    return hour * 100 + minutes;
};

function getTwoDigitStr(number: number) {
    return number < 10 ? "0" + number : number.toString();
}

export const numberToDate = (time: number) => {
    const date = new Date();
    if (!time) {
        date.setHours(0);
        date.setMinutes(0);
    } else {
        const hour = (time - (time % 100)) / 100;
        const minutes = time % 100;

        date.setMinutes(minutes);
        date.setHours(hour);
    }
    return date;
};

export function isNumber(number: string) {
    return !isNaN(parseFloat(number)) && isFinite(parseFloat(number));
}

export function getStartOfTomorrow() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    return tomorrow;
}

export function compareExtraValues(data: ExtraValues, formData: ExtraValues) {
    if (
        data.toGo.price === formData.toGo.price &&
        data.toGo.tax === formData.toGo.tax &&
        data.delivery.price === formData.delivery.price &&
        data.delivery.tax === formData.delivery.tax &&
        data.dineIn.price === formData.dineIn.price &&
        data.dineIn.tax === formData.dineIn.tax
    )
        return true;
    return false;
}

export function getGSuiteName(email: string) {
    const atIndex = email.indexOf("@");
    const dotIndex = email.lastIndexOf(".");
    if (atIndex > -1 && dotIndex > -1 && atIndex < dotIndex) {
        const gSuiteName = email.slice(atIndex + 1, dotIndex);
        return gSuiteName;
    }
    return "";
}

export const delay = (timeInMS: number) =>
    new Promise((resolve) => setTimeout(resolve, timeInMS));

export function getFirstOrderSortByModifyTime(orders: Record<string, Order>) {
    if (Object.values(orders).length > 0) {
        return Object.values(orders).sort(
            (a, b) => +dayjs(a.modifyTimestamp) - +dayjs(b.modifyTimestamp)
        )[0].posId;
    }
    return undefined;
}

export function getFirstOrderSortBybumpedTime(orders: Record<string, Order>) {
    if (Object.values(orders).length > 0) {
        return Object.values(orders).sort(
            (a, b) => +dayjs(b.bumpedTime) - +dayjs(a.bumpedTime)
        )[0].posId;
    }
    return undefined;
}

export const camelToSnakeCase = (str: string): string =>
    str.replace(
        /[A-Z]/g,
        (letter: string): string => `_${letter.toLowerCase()}`
    );

export const addNameToItemUsingId = (
    items: any,
    lookup: any,
    key?: string,
    propertyType?: string
) =>
    items.map((item: any) => {
        if (propertyType === "sortOrder") {
            return {
                ...item,
                id: lookup[item?.id || item][key || "name"],
            };
        }
        return lookup[item?.id || item][key || "name"];
    });

export const omitKeysFromObject = (obj: any, keys: string[]) => {
    const newObj = { ...obj };
    keys.forEach((key: string) => delete newObj[key]);
    return newObj;
};

export const matchRegex = (str: string, regex: RegExp) => str?.match(regex);

export const formatDate = (str: string, format?: string) =>
    moment(str).format(format || "");

export const readEnvVariable = (key: string) =>
    process.env[`REACT_APP_${key}`] || "";

export const sortByDate = (key: string, type: "asc" | "desc") => (
    a: any,
    b: any
) => {
    if (type === "asc")
        return new Date(a[key]).getTime() - new Date(b[key]).getTime();
    return new Date(b[key]).getTime() - new Date(a[key]).getTime();
};

export const snakeToCamelCase = (str: string): string =>
    str.replace(/[^a-zA-Z0-9]+(.)/g, (m: string, letter: string): string =>
        letter.toUpperCase()
    );
export const capitalizeFirstLetter = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1);
