import { useEffect, useRef } from "react";

export default function useRecursiveTimeout<T>(
    callback: () => Promise<T> | (() => void),
    delay: number | null
) {
    const savedCallback = useRef(callback);

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the timeout loop.
    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        const tick = () => {
            const ret = savedCallback.current();

            if (ret instanceof Promise) {
                ret.finally(() => {
                    if (delay !== null) {
                        timeoutId = setTimeout(tick, delay);
                    }
                });
            } else {
                if (delay !== null) {
                    timeoutId = setTimeout(tick, delay);
                }
            }
        };
        if (delay !== null) {
            timeoutId = setTimeout(tick, delay);
            return () => timeoutId && clearTimeout(timeoutId);
        }
    }, [delay]);
}
