import { useMutation } from "@apollo/react-hooks";
import {
    Box,
    Button,
    Grid,
    InputAdornment,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Theme,
    Typography,
} from "@material-ui/core";
import MyPhoneNumber from "material-ui-phone-number";
import { Checkboxes, ShowErrorProps, TextField } from "mui-rff";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Form, FormSpy } from "react-final-form";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { Prompt, useHistory } from "react-router-dom";
import { Dispatch } from "redux";
import ImageUpload from "../../components/ImageUpload";
import { EntitySaveButton } from "../../components/menu/EntitySaveButton";
import { RestaurantAutoComplete } from "../../components/RestaurantAutoComplete";
import {
    Restaurant,
    RestaurantBranding,
    RestaurantContactInfo,
    RestaurantSettings,
} from "../../generated-interfaces/graphql";
import { UploadMutation } from "../../queries/imageUpload";
import {
    updatePrimaryRestaurant,
    updateRestaurantBranding,
    updateRestaurantContactInfo,
    updateRestaurantSettings,
} from "../../reducers/restaurantReducer";
import { RootState } from "../../reducers/rootReducer";
import {
    restaurantInfoSelector,
    restaurantsByUserRoleSelector,
} from "../../selectors/restaurant";
import { TIME_OUT } from "../../utils/constants";
import { matchRegex } from "../../utils/helper-functions";
import {
    CallbackFunction,
    RestaurantUpdateDetails,
    StringOrNull,
} from "../../utils/types";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        padding: theme.spacing(8, 4, 8, 4),
        backgroundColor: "#fff",
    },
    header: {
        paddingBottom: theme.spacing(3),
    },
    taxHeading: {
        fontWeight: "bold",
    },
    taxGroup: {
        marginTop: "4px",
        "& .MuiInputBase-root": {
            width: "88px",
            "& .MuiInputBase-input": {
                textAlign: "right",
                fontSize: "0.875rem",
            },
            "& .MuiInputAdornment-positionEnd": {
                marginLeft: "2px",
                "& .MuiTypography-body1": {
                    marginRight: "8px",
                },
            },
        },
    },
    mirrorRestuarant: {
        color: "red",
        fontWeight: 700,
        fontSize: 24,
        padding: 10,
        textAlign: "right",
    },
    error: {
        fontSize: "0.75rem",
        textAlign: "left",
        lineHeight: 1.66,
        margin: "-5px 14px 0",
        color: "#f50057",
    },
}));

const RestaurantTaxRate = ({
    title,
    inputName,
    errors,
}: {
    inputName: string;
    title: string;
    errors: Record<string, string>;
}) => {
    const classes = useStyles();
    return (
        <Grid
            container
            item
            xs={12}
            alignItems="center"
            className={classes.taxGroup}
        >
            <Grid item xs={9}>
                <Typography>{title}</Typography>
            </Grid>
            <Grid item xs={3}>
                <TextField
                    fullWidth={false}
                    className="custom-input-gray"
                    type="text"
                    name={inputName}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                        ),
                        inputProps: { min: 0 },
                        disableUnderline: true,
                    }}
                    //Should return false to display custom error due design limitation
                    showError={() => false}
                />
            </Grid>
            {errors[inputName] && (
                <p className={classes.error}>{errors[inputName]}</p>
            )}
        </Grid>
    );
};

function RestaurantSetting(props: Props) {
    const classes = useStyles();
    const history = useHistory();
    const alert = useAlert();

    const [blockNavigation, setBlockNavigation] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const restaurantInfo = useSelector(restaurantInfoSelector);
    const [logoUrl, setLogoUrl] = useState<StringOrNull>(null);
    const [backgroundImage, setBackgroundImage] = useState<StringOrNull>(null);
    const [uploadBackgroundImage, uploadBackgroundImageResult] = useMutation(
        UploadMutation
    );
    const [uploadBrandLogo, uploadBrandLogoResult] = useMutation(
        UploadMutation
    );
    const [backgroundImageStatus, setBackgroundImageStatus] = useState("");
    const [brandLogoStatus, setBrandLogoStatus] = useState("");
    const [isSavingError, setSavingError] = useState(false);
    const [primaryRestaurantCode, setPrimaryRestaurantCode] = useState("");
    const restaurantsByUserRole = useSelector(restaurantsByUserRoleSelector);
    const [restaurantsMap, setRestaurantsMap] = useState<
        Record<string, Restaurant>
    >({});
    useEffect(() => {
        window.onbeforeunload = null;
    }, []);

    useEffect(() => {
        if (restaurantInfo?.restaurantContactInfo?.phoneNumber) {
            setPhoneNumber(restaurantInfo.restaurantContactInfo.phoneNumber);
        }
        if (restaurantInfo?.primaryRestaurantCode) {
            setPrimaryRestaurantCode(restaurantInfo.primaryRestaurantCode);
        }
        if (restaurantInfo?.restaurantBranding) {
            setLogoUrl(restaurantInfo.restaurantBranding.logoUrl);
            setBackgroundImage(
                restaurantInfo.restaurantBranding.backgroundImageUrl
            );
        }
    }, [restaurantInfo]);

    useEffect(() => {
        if (
            (brandLogoStatus === "removed" || brandLogoStatus === "") &&
            (backgroundImageStatus === "removed" ||
                backgroundImageStatus === "")
        )
            setImageLoaded(false);
    }, [brandLogoStatus, backgroundImageStatus]);
    const initialValues: any = {
        ...restaurantInfo?.restaurantContactInfo,
        ...restaurantInfo?.restaurantSettings,
    };

    initialValues.website =
        initialValues?.website && initialValues.website.replace("http://", "");

    const handleUploadBackgroundImage = (file: any) => {
        uploadBackgroundImage({
            variables: { file, restaurantCode: props.restaurantCode },
        });
        setImageLoaded(true);
    };

    const handleUploadBrandLogo = (file: any) => {
        uploadBrandLogo({
            variables: { file, restaurantCode: props.restaurantCode },
        });
        setImageLoaded(true);
    };

    const validPhoneNumber = () =>
        phoneNumber?.split("").filter((v) => Number.isInteger(parseInt(v)))
            .length >= 11;

    const validateEmail = (email: string) =>
        matchRegex(
            email,
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

    const validateZipCode = (zipCode: string) =>
        matchRegex(zipCode, /(^\d{5}$)|(^\d{5}-\d{4}$)/);

    const validate = (values: any) => {
        const errorMap: Record<string, string> = {};
        if (
            uploadBackgroundImageResult.loading ||
            uploadBrandLogoResult.loading
        ) {
            errorMap["imageUpload"] = "Image(s) have not finished uploading";
        }
        if (!validPhoneNumber()) {
            errorMap["phoneNumber"] = "Phone Number is not correct";
        }
        if (
            values.dineInModalityTaxRate &&
            parseFloat(values.dineInModalityTaxRate) > 100
        ) {
            errorMap["dineInModalityTaxRate"] =
                "Dine-in tax cannot be greater than 100";
        }
        if (
            values.deliveryModalityTaxRate &&
            parseFloat(values.deliveryModalityTaxRate) > 100
        ) {
            errorMap["deliveryModalityTaxRate"] =
                "Dine-in tax cannot be greater than 100";
        }
        if (
            values.toGoModalityTaxRate &&
            parseFloat(values.toGoModalityTaxRate) > 100
        ) {
            errorMap["toGoModalityTaxRate"] =
                "To-go tax cannot be greater than 100";
        }

        if (!validateZipCode(values.zipCode)) {
            errorMap["zipCode"] = "Zipcode cannot be non-numeric";
        }

        if (!validateEmail(values.email)) {
            errorMap["email"] = "Please enter valid email format";
        }
        if (
            values.toGoModalityTaxRate &&
            parseFloat(values.toGoModalityTaxRate) < 0
        ) {
            errorMap["toGoModalityTaxRate"] =
                "To-go tax can not be less than 0";
        }
        if (
            values.dineInModalityTaxRate &&
            parseFloat(values.dineInModalityTaxRate) < 0
        ) {
            errorMap["dineInModalityTaxRate"] =
                "Dine-in tax cannot be less than 0";
        }
        if (
            values.deliveryModalityTaxRate &&
            parseFloat(values.deliveryModalityTaxRate) < 0
        ) {
            errorMap["deliveryModalityTaxRate"] =
                "Delivery tax cannot be less than 0";
        }
        return errorMap;
    };

    const onSubmit = (data: any) => {
        if (
            backgroundImageStatus === "done" &&
            uploadBackgroundImageResult.called === false
        ) {
            alert.info("Select Replace Image button of background image", {
                timeout: TIME_OUT,
            });
            return;
        } else if (
            brandLogoStatus === "done" &&
            uploadBrandLogoResult.called === false
        ) {
            alert.info("Select Replace Image button of logo", {
                timeout: TIME_OUT,
            });
            return;
        }
        if (primaryRestaurantCode !== restaurantInfo?.primaryRestaurantCode)
            props.updatePrimaryRestaurant({
                primaryRestaurantCode: primaryRestaurantCode,
                errorCallback: () => {
                    setSavingError(true);
                },
            });

        props.updateRestaurantSettings({
            dineInModalityTaxRate: parseFloat(data.dineInModalityTaxRate || 0),
            deliveryModalityTaxRate: parseFloat(
                data.deliveryModalityTaxRate || 0
            ),
            toGoModalityTaxRate: parseFloat(data.toGoModalityTaxRate || 0),
            toGoOrderConfirmationMessage: data.toGoOrderConfirmationMessage,
            toGoDefaultSMSText: data.toGoDefaultSMSText,
            toGoPickupMinutes: parseInt(data.toGoPickupMinutes),
            errorCallback: () => {
                setSavingError(true);
            },
            isAlertingEnabled: data.isAlertingEnabled,
        } as any);

        props.updateRestaurantContactInfo({
            ...data,
            phoneNumber,
            website: data.website ? "http://" + data.website : null,
            errorCallback: () => {
                setSavingError(true);
            },
        });

        props.updateRestaurantBranding({
            logoUrl: uploadBrandLogoResult.data?.uploadImage || logoUrl || null,
            backgroundImageUrl:
                uploadBackgroundImageResult.data?.uploadImage ||
                backgroundImage ||
                null,
            successCallback: () => {
                if (isSavingError) {
                    alert.error("Error Saving Restaurant Settings", {
                        timeout: TIME_OUT,
                    });
                } else {
                    setBlockNavigation(false);
                    setImageLoaded(false);
                    alert.success("Restaurant Settings Saved", {
                        timeout: TIME_OUT,
                    });
                }
            },
            errorCallback: () => {
                alert.error("Error Saving Restaurant Settings", {
                    timeout: TIME_OUT,
                });
            },
        } as any);
    };

    const showHoursOfOperation = () => {
        history.push(
            `/${restaurantInfo?.restaurantCode}/restaurant-settings/operation-hours`
        );
    };

    const onShowError = (errorProp: ShowErrorProps): boolean =>
        !!errorProp?.meta?.error;

    useEffect(() => {
        setRestaurantsMap(
            restaurantsByUserRole.reduce((a, c) => {
                c.restaurants.forEach((r) => {
                    if (!(r.restaurantCode in a) && !r.primaryRestaurantCode) {
                        a[r.restaurantCode] = r;
                    }
                });
                return a;
            }, {} as Record<string, Restaurant>)
        );
    }, [restaurantsByUserRole]);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    return (
        <div className={classes.container}>
            {!!restaurantInfo?.primaryRestaurantCode && (
                <div className={classes.mirrorRestuarant}>
                    Mirror Restuarant
                </div>
            )}
            <Prompt
                when={blockNavigation || imageLoaded}
                message={(location, action) => {
                    console.log("action", action);
                    return "The changes you've made will be lost. Are you sure you want to discard the changes?";
                }}
            />

            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                validate={validate}
                render={({ handleSubmit, values, valid, form, errors }) => (
                    <form onSubmit={handleSubmit}>
                        <FormSpy
                            onChange={(props) => {
                                setBlockNavigation(props.dirty);
                            }}
                        />
                        <EntitySaveButton
                            disabled={
                                !valid ||
                                !validPhoneNumber() ||
                                uploadBackgroundImageResult.loading ||
                                uploadBrandLogoResult.loading
                            }
                            style={{ float: "right" }}
                        />
                        <Typography
                            variant="h4"
                            color="primary"
                            className={classes.header}
                        >
                            Restaurant Settings
                        </Typography>
                        <Typography variant="h6" color="primary">
                            Contact Information
                        </Typography>
                        <Grid container style={{ maxWidth: "600px" }}>
                            <Grid item xs={12}>
                                <TextField
                                    name="address"
                                    placeholder="Number & Street"
                                    label="Street"
                                    fullWidth
                                    required
                                    margin="normal"
                                    variant="filled"
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    showError={onShowError}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingRight: "10px" }}>
                                <TextField
                                    name="city"
                                    placeholder="City"
                                    label="City"
                                    fullWidth
                                    required
                                    margin="normal"
                                    variant="filled"
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    showError={onShowError}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingLeft: "10px" }}>
                                <TextField
                                    name="zipCode"
                                    placeholder="ZiP Code"
                                    label="Zip Code"
                                    fullWidth
                                    required
                                    margin="normal"
                                    variant="filled"
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    showError={onShowError}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingRight: "10px" }}>
                                <TextField
                                    name="state"
                                    placeholder="State"
                                    label="State"
                                    fullWidth
                                    required
                                    margin="normal"
                                    variant="filled"
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    showError={onShowError}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingLeft: "10px" }}>
                                <TextField
                                    name="country"
                                    placeholder="Country"
                                    label="Country"
                                    fullWidth
                                    required
                                    margin="normal"
                                    variant="filled"
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    showError={onShowError}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MyPhoneNumber
                                    label="Phone Number"
                                    defaultCountry="us"
                                    value={phoneNumber || ""}
                                    onChange={(value: any) =>
                                        setPhoneNumber(value as string)
                                    }
                                    fullWidth
                                    required
                                    margin="normal"
                                    variant="filled"
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                />
                                {errors.phoneNumber && (
                                    <p className={classes.error}>
                                        {errors.phoneNumber}
                                    </p>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    label="Email"
                                    fullWidth
                                    required
                                    margin="normal"
                                    variant="filled"
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    showError={onShowError}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="website"
                                    placeholder="Website"
                                    label="Website"
                                    fullWidth
                                    margin="normal"
                                    variant="filled"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                http://
                                            </InputAdornment>
                                        ),
                                        disableUnderline: true,
                                    }}
                                    showError={onShowError}
                                />
                            </Grid>
                        </Grid>
                        <Typography
                            variant="h6"
                            color="primary"
                            style={{ margin: "24px 0 16px 0" }}
                        >
                            Configuration
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={8}>
                                <ImageUpload
                                    isImageUploading={
                                        uploadBrandLogoResult.loading
                                    }
                                    header="Restaurant Logo"
                                    title="Add your logo for the guest web app."
                                    imageUrl={logoUrl}
                                    uploadImage={handleUploadBrandLogo}
                                    deleteOriginalImage={() => setLogoUrl(null)}
                                    setImageLoadingStatus={setBrandLogoStatus}
                                />
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <InputLabel
                                    variant="standard"
                                    htmlFor="uncontrolled-native"
                                    style={{
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        paddingBottom: "10px",
                                        color: "black",
                                    }}
                                >
                                    Set Mirror Restaurant
                                </InputLabel>
                                <RestaurantAutoComplete
                                    restaurantsEntries={Object.entries(
                                        restaurantsMap
                                    )}
                                    handleSelect={(val) => {
                                        setPrimaryRestaurantCode(val as string);
                                    }}
                                    inputValue={primaryRestaurantCode}
                                />
                            </Grid>
                        </Grid>
                        <ImageUpload
                            isImageUploading={
                                uploadBackgroundImageResult.loading
                            }
                            header="Background Image"
                            title="Add a background image for the guest web app."
                            imageUrl={backgroundImage}
                            uploadImage={handleUploadBackgroundImage}
                            deleteOriginalImage={() => setBackgroundImage(null)}
                            setImageLoadingStatus={setBackgroundImageStatus}
                        />

                        <div style={{ marginTop: "20px", maxWidth: "600px" }}>
                            <div>
                                <Typography className={classes.taxHeading}>
                                    Tax Rate
                                </Typography>
                                <RestaurantTaxRate
                                    errors={errors}
                                    inputName="dineInModalityTaxRate"
                                    title="Dine In Tax (%)"
                                />
                                <RestaurantTaxRate
                                    errors={errors}
                                    inputName="toGoModalityTaxRate"
                                    title="To-Go Tax (%)"
                                />
                                <RestaurantTaxRate
                                    errors={errors}
                                    inputName="deliveryModalityTaxRate"
                                    title="Delivery Tax (%)"
                                />
                            </div>
                        </div>
                        <Grid item xs={6}>
                            <Checkboxes
                                color="primary"
                                name="isAlertingEnabled"
                                placeholder="Show alert for returning customers"
                                data={{
                                    label: "Show alert for returning customers",
                                    value: true,
                                }}
                            />
                        </Grid>

                        <div style={{ marginTop: "20px" }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={showHoursOfOperation}
                                disableElevation
                                type="button"
                            >
                                Edit Hours of Operation
                            </Button>
                        </div>

                        <Box mt={2}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h6" color="primary">
                                        To-Go Settings
                                    </Typography>
                                </Grid>
                                <Grid
                                    container
                                    alignItems="center"
                                    item
                                    xs={12}
                                >
                                    <Grid item xs={6} md={3}>
                                        <Typography>
                                            Earliest pickup time
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} md={3}>
                                        <Select
                                            fullWidth={false}
                                            className="custom-input-gray"
                                            type="text"
                                            value={values.toGoPickupMinutes}
                                            onChange={(e) => {
                                                form.change(
                                                    "toGoPickupMinutes",
                                                    parseInt(
                                                        e.target.value as string
                                                    )
                                                );
                                            }}
                                            disableUnderline
                                        >
                                            {Array.from(Array(37).keys()).map(
                                                (i) => {
                                                    return (
                                                        <MenuItem
                                                            key={i}
                                                            value={i * 5}
                                                        >
                                                            {i * 5} mins
                                                        </MenuItem>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    alignItems="center"
                                    item
                                    xs={12}
                                >
                                    <Grid item xs={6} md={6}>
                                        <TextField
                                            fullWidth={true}
                                            type="text"
                                            variant="filled"
                                            rows={4}
                                            multiline
                                            name="toGoDefaultSMSText"
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                            inputProps={{
                                                maxLength: 100,
                                            }}
                                            margin="normal"
                                            label="Default SMS Notification Text"
                                            placeholder="Your order is ready for pickup."
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    alignItems="center"
                                    item
                                    xs={12}
                                >
                                    <Grid item xs={6} md={6}>
                                        <TextField
                                            type="text"
                                            name="toGoOrderConfirmationMessage"
                                            placeholder="Enter message here"
                                            label="To-Go Order Confirmation Message"
                                            fullWidth
                                            variant="filled"
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                )}
            />
        </div>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        restaurantCode: state.restaurant.selectedRestaurant?.restaurantCode,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updatePrimaryRestaurant: (
            data: RestaurantUpdateDetails & CallbackFunction
        ) => dispatch(updatePrimaryRestaurant(data)),
        updateRestaurantSettings: (
            data: RestaurantSettings & CallbackFunction
        ) => dispatch(updateRestaurantSettings(data)),
        updateRestaurantContactInfo: (
            data: RestaurantContactInfo & CallbackFunction
        ) => dispatch(updateRestaurantContactInfo(data)),
        updateRestaurantBranding: (
            data: RestaurantBranding & CallbackFunction
        ) => dispatch(updateRestaurantBranding(data)),
    };
};

type Props = ConnectedProps<typeof connected>;

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(RestaurantSetting);
