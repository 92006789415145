import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import React, {
    ChangeEvent,
    KeyboardEvent,
    MouseEvent,
    useEffect,
    useState,
} from "react";
import { BACK_DROP_CLICK } from "../constants";
import { KeyboardKeys } from "../constants/enums";
import { UserSession } from "../types/menu";
import { getSessionID } from "../utils/local-storage";

interface IProps {
    open: boolean;
    userSessions: UserSession[];
    onClose: Function;
    onForceLogOut: Function;
}
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});
const FORCE_LOGOUT = "Force logout",
    CANCEL = "Cancel",
    USER_ID = "User id",
    FIRST_NAME = "First Name",
    LAST_NAME = "Last Name",
    SOURCE_MODULE = "Source Module";
export default function ActiveSessionConfirmDialog({
    open,
    userSessions,
    onClose,
    onForceLogOut,
}: IProps) {
    const classes = useStyles();

    const [selected, setSelected] = useState<readonly string[]>([]);
    const currentUserSession = getSessionID();
    const handleClose = (e: MouseEvent, reason: string) => {
        if (reason !== BACK_DROP_CLICK) {
            onClose();
        }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === KeyboardKeys.ENTER) onClose();
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        if (name === currentUserSession) return;
        const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = [];
        // if not presnt add the selected item
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const onSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected =
                userSessions
                    .filter((n) => n.user_session_id !== currentUserSession)
                    .map((n) => n.user_session_id) || [];
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const rowCount = userSessions.length || 0;

    const onClickforceLogOut = () => {
        const selectedSessions =
            userSessions.filter(
                (n) => selected.indexOf(n.user_session_id) !== -1
            ) || [];
        onForceLogOut(selectedSessions);
    };

    useEffect(() => {
        setSelected([]);
    }, [userSessions]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            onKeyDown={handleKeyDown}
        >
            {<DialogTitle> Active Users </DialogTitle>}
            <DialogContent>
                <TableContainer component={Paper}>
                    <Table
                        className={classes.table}
                        size="small"
                        aria-label="active user table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell align="right">
                                    <Checkbox
                                        color="primary"
                                        indeterminate={
                                            selected.length > 0 &&
                                            selected.length < rowCount
                                        }
                                        checked={
                                            rowCount > 0 &&
                                            selected.length === rowCount
                                        }
                                        onChange={onSelectAllClick}
                                        inputProps={{
                                            "aria-label": "select all",
                                        }}
                                    />
                                </TableCell>
                                {[
                                    USER_ID,
                                    FIRST_NAME,
                                    LAST_NAME,
                                    SOURCE_MODULE,
                                ].map((heading) => (
                                    <TableCell align="left" key={heading}>
                                        {heading}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userSessions.map(
                                (
                                    {
                                        unique_user_id,
                                        user_session_id,
                                        first_name,
                                        last_name,
                                        source_module,
                                    },
                                    index
                                ) => {
                                    const isItemSelected = isSelected(
                                        user_session_id
                                    );
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            key={user_session_id}
                                            onClick={(event) =>
                                                handleClick(
                                                    event,
                                                    user_session_id
                                                )
                                            }
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            selected={isItemSelected}
                                        >
                                            <TableCell align="right">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        "aria-labelledby": labelId,
                                                    }}
                                                    disabled={
                                                        user_session_id ===
                                                        currentUserSession
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {unique_user_id}
                                            </TableCell>
                                            <TableCell align="left">
                                                {first_name}
                                            </TableCell>
                                            <TableCell align="left">
                                                {last_name}
                                            </TableCell>
                                            <TableCell align="left">
                                                {source_module}
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => onClose()}
                    variant="contained"
                    color={"primary"}
                >
                    {CANCEL}
                </Button>
                <Button
                    onClick={onClickforceLogOut}
                    variant="contained"
                    color={"secondary"}
                    disabled={selected.length === 0}
                >
                    {FORCE_LOGOUT}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
