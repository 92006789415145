import { parse } from "query-string";
import { useCallback } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
    fetchCommittedMenuVersion,
    getMenuItems,
} from "../reducers/menuReducer";
import { MenuStages } from "../types/menuVersion";
import { TIME_OUT } from "../utils/constants";
import { readEnvVariable } from "../utils/helper-functions";

export default function useLoadMenu() {
    const dispatch = useDispatch();
    const stage = readEnvVariable("MENU_VERSION_STAGE").toUpperCase();
    const location = useLocation();
    const queryParams = parse(location.search);
    const menuVersion = queryParams["version"];
    const alert = useAlert();
    return {
        loadMenu: useCallback(() => {
            if (stage !== MenuStages.PLAYGROUND || menuVersion) {
                const payload = menuVersion
                    ? {
                          commitId: menuVersion as string,
                          errorCallback: () => {
                              alert.error(
                                  `Error fetching menu for version ${menuVersion}`,
                                  {
                                      timeout: TIME_OUT,
                                  }
                              );
                          },
                      }
                    : {};
                dispatch(fetchCommittedMenuVersion(payload));
            } else dispatch(getMenuItems());
        }, [alert, dispatch, menuVersion, stage]),
    };
}
