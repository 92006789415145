import { MaterialTableProps } from "material-table";
import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomChip from "../../components/menuVersion/CustomChip";
import { fetchMenuVersionRequestStatus } from "../../reducers/menuReducer";
import { selectMenuVersionRequestStatus } from "../../selectors/menu";
import { selectedRestaurantCodeSelector } from "../../selectors/restaurant";
import { IMenuVersionRequestStatus } from "../../types/menuVersion";
import { BannerColorMapping, DATE_FORMATS } from "../../utils/constants";
import { CustomizedMaterialTable } from "../../utils/data-tables";
import { formatDate } from "../../utils/helper-functions";

const MenuRequestStatus = () => {
    const requestStatus = useSelector(selectMenuVersionRequestStatus);
    const dispatch = useDispatch();
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);

    useEffect(() => {
        if (restaurantCode) {
            dispatch(fetchMenuVersionRequestStatus());
        }
    }, [dispatch, restaurantCode]);

    const menuItemsTableConfig: MaterialTableProps<IMenuVersionRequestStatus> = {
        columns: [
            {
                title: "Serial number",
                field: "id",
            },
            {
                title: "Request id",
                field: "requestId",
            },
            {
                title: "Request type",
                field: "requestType",
            },
            {
                title: "Updated on",
                field: "updatedAt",
                render: ({ updatedAt }) =>
                    formatDate(updatedAt, DATE_FORMATS.dateAndTime),
            },
            {
                title: "Status",
                field: "status",
                render: ({ status }) => (
                    <CustomChip
                        label={status}
                        customColor={BannerColorMapping[status.toUpperCase()]}
                    />
                ),
            },
            {
                title: "Version number",
                field: "commitId",
                render: ({ commitId }) => commitId || "-",
            },
        ],
        data: requestStatus
            .map((o) => ({ ...o }))
            .sort((o1, o2) => Number(o2?.id) - Number(o1?.id)), // Shallow copy objects b/c they are immutable
    };

    return (
        <div>
            <CustomizedMaterialTable {...menuItemsTableConfig} />
        </div>
    );
};

export default memo(MenuRequestStatus);
