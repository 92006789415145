import {
    Button,
    Grid,
    makeStyles,
    MenuItem,
    MenuList,
    Paper,
    Popover,
    Tab,
    Tabs,
    Theme,
} from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import QueryString from "query-string";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, Route, Switch, useHistory } from "react-router-dom";
import { useReadOnly } from "../../hooks";
import { RootState } from "../../reducers/rootReducer";
import { getUserManagement } from "../../reducers/userManagementReducer";
import {
    restaurantInfoSelector,
    selectedRestaurantCodeSelector,
} from "../../selectors/restaurant";
import { USER_MANAGEMENT_ROUTES } from "./route";

const useStyles = makeStyles((theme: Theme) => ({
    childComponent: {
        padding: theme.spacing(3, 4),
        backgroundColor: theme.palette.background.paper,
        marginTop: "2px",
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    tabsList: {
        backgroundColor: theme.palette.background.default,
        "& .Mui-selected": {
            color: theme.palette.primary.main,
        },
    },
    tab: {
        minWidth: "150px",
    },
    tabMenu: {
        backgroundColor: "rgba(0,0,0,0.08)",
        fontWeight: "bold",
        padding: theme.spacing(1),
    },
    mirrorRestuarant: {
        color: "red",
        fontWeight: 700,
        fontSize: 24,
        padding: 10,
        textAlign: "right",
    },
}));

export default function UserManagementWrapper() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const queryParams = QueryString.parse(history.location.search);
    const isStaffTablet = queryParams["isStaffTablet"] === "true";

    const path = useSelector(
        (state: RootState) => state.router.location.pathname
    );
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );
    const [selectedMenu, setSelectedMenu] = React.useState<string>(
        "Restaurants"
    );
    const restaurantCode = useSelector(selectedRestaurantCodeSelector);

    const userManagementRoutes = USER_MANAGEMENT_ROUTES(restaurantCode || "");
    const selectedRestaurant = useSelector(restaurantInfoSelector);
    const { isMirrorRestro } = useReadOnly();

    useEffect(() => {
        dispatch(getUserManagement());
        const selectedRoute = Object.values(userManagementRoutes).find(
            (route) => path.indexOf(route.path) > -1
        );
        setSelectedMenu(selectedRoute?.title || "Restaurants");
    }, []);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (path.endsWith("/user-management")) {
        return <Redirect to={path + "/restaurants"} />;
    }

    let USER_MANAGEMENT_TABS = { ...userManagementRoutes };
    if (path.indexOf("/users/") > -1) {
        USER_MANAGEMENT_TABS.users = {
            ...USER_MANAGEMENT_TABS.users,
            visibleTab: false,
        };
        USER_MANAGEMENT_TABS.newUser = {
            ...USER_MANAGEMENT_TABS.newUser,
            visibleTab: true,
            path,
        };
    }

    if (path.indexOf("/restaurants/") > -1) {
        USER_MANAGEMENT_TABS.restaurants = {
            ...USER_MANAGEMENT_TABS.restaurants,
            visibleTab: false,
        };
        USER_MANAGEMENT_TABS.newRestaurant = {
            ...USER_MANAGEMENT_TABS.newRestaurant,
            visibleTab: true,
            path,
        };
    }

    if (path.indexOf("/restaurant-groups/") > -1) {
        USER_MANAGEMENT_TABS.restaurantGroups = {
            ...USER_MANAGEMENT_TABS.restaurantGroups,
            visibleTab: false,
        };
        USER_MANAGEMENT_TABS.newRestaurantGroup = {
            ...USER_MANAGEMENT_TABS.newRestaurantGroup,
            visibleTab: true,
            path,
        };
    }

    return (
        <React.Fragment>
            {!isStaffTablet ? (
                <Paper elevation={0}>
                    <Tabs
                        scrollButtons="auto"
                        value={path}
                        aria-label="Menu Editor Navigation Tabs"
                        className={classes.tabsList}
                        indicatorColor="secondary"
                    >
                        {Object.values(USER_MANAGEMENT_TABS).map((route) => {
                            return (
                                route.visibleTab && (
                                    <Tab
                                        key={route.path}
                                        value={route.path}
                                        component={Link}
                                        label={route.title}
                                        to={route.originalPath || route.path}
                                        className={classes.tab}
                                    />
                                )
                            );
                        })}
                    </Tabs>
                </Paper>
            ) : (
                <Paper style={{ position: "relative" }}>
                    <Paper className={classes.tabMenu}>
                        <Grid
                            container
                            style={{ width: "100%" }}
                            justify="flex-end"
                        >
                            <Grid item>
                                <Button
                                    style={{ float: "right" }}
                                    onClick={handleClick}
                                    disableElevation
                                >
                                    {selectedMenu}
                                    {anchorEl ? (
                                        <ArrowDropUp />
                                    ) : (
                                        <ArrowDropDown />
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                    >
                        <MenuList>
                            {Object.values(userManagementRoutes)
                                .filter((route) => route.rootTab)
                                .map((route) => (
                                    <MenuItem
                                        onClick={() => {
                                            setSelectedMenu(route.title);
                                            history.push(route.path);
                                            handleClose();
                                        }}
                                    >
                                        {route.title}
                                    </MenuItem>
                                ))}
                        </MenuList>
                    </Popover>
                </Paper>
            )}
            {isMirrorRestro && (
                <div className={classes.mirrorRestuarant}>
                    Mirror Restuarant
                </div>
            )}
            <div className={classes.childComponent}>
                <Switch>
                    {Object.values(userManagementRoutes).map((route) => (
                        <Route exact key={route.path} path={route.path}>
                            {route.component}
                        </Route>
                    ))}
                </Switch>
            </div>
        </React.Fragment>
    );
}
