import { PayloadAction } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
    AddMenuItemMutation,
    CreateCategoryMutation,
    CreateModifierGroupMutation,
    CreateTimePeriodMutation,
    DeleteCategoryMutation,
    DeleteMenuItemMutation,
    DeleteMenuItemSettingMutation,
    DeleteModifierGroupMutation,
    DeleteTimePeriodMutation,
    MenuItem,
    MenuOverrideInput,
    NewMenuItemSettingInput,
    SortOrderInput,
    UpdateCategoriesSortOrderInput,
    UpdateMenuItemAvailabilityInput,
    UpdateMenuItemSettingInput,
} from "../generated-interfaces/graphql";
import { MENU_EDITOR_ROUTES } from "../pages/menu-editor/menu-editor-routes";
import {
    createCategory,
    createMenuItem,
    createMenuItemSetting,
    createModifierGroup,
    createTimePeriod,
    deleteCategory,
    deleteMenuItem,
    deleteMenuItemSetting,
    deleteModifierGroup,
    deleteTimePeriod,
    duplicateMenuItem,
    fetchLatestVersionOnDB,
    getMenuItems,
    getVoiceProperties,
    MENU_ACTIONS,
    reloadCache,
    updateCategoriesForMenu,
    updateCategory,
    updateMenuItem,
    updateMenuItemAvailability,
    updateMenuItemSetting,
    updateModifierGroup,
    updateTimePeriod,
} from "../reducers/menuReducer";
import {
    selectedPrimaryRestaurantCodeSelector,
    selectedRestaurantCodeSelector,
} from "../selectors/restaurant";
import {
    CategoryType,
    IDuplicatePayload,
    MenuItemType,
    ModifierGroupType,
    TimePeriodInputType,
} from "../types/menu";
import logger from "../utils/logger";
import { generateVoiceProperties } from "../utils/menu";
import { getSdk } from "../utils/network";
import {
    CallbackFunction,
    DeleteItemType,
    GetIngestedDataType,
} from "../utils/types";
import { getPRPIntCall } from "../utils/webserviceAPI";
import {
    addIntCategorySaga,
    addIntModifierGroupSaga,
    createIntMenuItemSaga,
    deleteIntCategorySaga,
    deleteIntMenuItemSaga,
    deleteIntModifierGroupSaga,
    updateIntCategorySaga,
    updateIntMenuItemSaga,
    updateIntModifierGroupSaga,
} from "./menuIntSaga";

function* updateItemAvailability(
    action: PayloadAction<UpdateMenuItemAvailabilityInput>
) {
    logger.debug("Attempting to update item availability");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const sdk = getSdk();
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        yield sdk.updateMenuItemAvailability({
            ...action.payload,
            restaurantCode,
        });
        logger.debug("Successfully updated item availability");
        yield put(getMenuItems());
    } catch (error) {
        logger.error("Failed updating item availability", error);
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* menuItemsSaga() {
    logger.debug("Attempting to get menu items");
    const sdk = getSdk();
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        const {
            menuItems,
            modifierGroups,
            categories,
            menuOverrides,
            menuItemSettings,
            timePeriods,
            posProperties,
        } = yield sdk.menuItemsQuery({
            restaurantCode,
            version: 0,
        });
        yield put(
            MENU_ACTIONS.loadMenuSuccess({
                menuItems,
                modifierGroups,
                categories,
                timePeriods,
                menuOverrides,
                menuItemSettings,
                posProperties,
            })
        );
        yield put(getVoiceProperties({ property_key: "voice_properties" }));
        yield put(fetchLatestVersionOnDB({}));
        logger.debug("Successfully got menu items");
    } catch (error) {
        logger.error("Get Menu Items Failed", error);
    }
}

function* createMenuItemSaga(
    action: PayloadAction<Omit<MenuItemType, "restaurantCode" | "id">>
) {
    logger.debug("Attempting to create menu item");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    const sdk = getSdk();
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        const available = payload.available === null ? true : payload.available;
        yield createIntMenuItemSaga(action);
        const data: AddMenuItemMutation = yield sdk.addMenuItem({
            ...payload,
            restaurantCode,
            available,
            sortOrder: payload.modifierGroupsSortOrder as SortOrderInput[],
        });
        logger.debug("Successfully created menu item");
        const menuItemId = data.createMenuItem.id;
        yield put(
            push(
                MENU_EDITOR_ROUTES(restaurantCode)["newMenuItem"].path.replace(
                    ":id",
                    menuItemId
                )
            )
        );
        yield put(getMenuItems());
        if (successCallback) successCallback();
    } catch (error) {
        logger.error("Add Menu Item Failed", error);
        if (errorCallback) errorCallback();
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* updateMenuItemSaga(
    action: PayloadAction<Omit<MenuItemType, "restaurantCode">>
) {
    logger.debug("Attempting to update menu item");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    const sdk = getSdk();
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        const available = payload.available === null ? true : payload.available;
        yield updateIntMenuItemSaga(action);
        yield sdk.updateMenuItem({
            ...payload,
            restaurantCode,
            available,
            sortOrder: payload.modifierGroupsSortOrder as SortOrderInput[],
        });
        logger.debug("Successfully updated menu item");
        yield put(getMenuItems());
        if (successCallback) successCallback();
    } catch (error) {
        logger.error("Update Menu Item Failed", error);
        if (errorCallback) errorCallback();
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* addModifierGroupSaga(action: PayloadAction<ModifierGroupType>) {
    logger.debug("Attempting to add modifier group");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    const sdk = getSdk();
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        yield addIntModifierGroupSaga(action);
        const data: CreateModifierGroupMutation = yield sdk.createModifierGroup(
            {
                ...payload,
                restaurantCode,
                childMenuItemIds: payload.menuItems,
                sortOrder: payload.sortOrder as SortOrderInput[],
                menuItemOverrides: (payload as any)
                    .menuOverrides as MenuOverrideInput[],
                defaultSelectedItemIds: payload.defaultSelectedItemIds as number[],
            }
        );
        logger.debug("Successfully create modifier group");
        const modGroupId = data.createModifierGroup.id;
        yield put(
            push(
                MENU_EDITOR_ROUTES(restaurantCode)[
                    "newModifierGroup"
                ].path.replace(":id", modGroupId)
            )
        );
        yield put(getMenuItems());
        if (successCallback) successCallback();
    } catch (error) {
        logger.error("Create Modifier Group Failed", error);
        if (errorCallback) errorCallback();
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* updateModifierGroupSaga(action: PayloadAction<ModifierGroupType>) {
    logger.debug("Attempting to update modifier group");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    const sdk = getSdk();
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        yield updateIntModifierGroupSaga(action);
        yield sdk.updateModifierGroup({
            ...payload,
            restaurantCode,
            id: parseFloat(payload.id),
            childMenuItemIds: payload.menuItems,
            sortOrder: payload.sortOrder as SortOrderInput[],
            menuItemOverrides: (payload as any)
                .menuOverrides as MenuOverrideInput[],
            defaultSelectedItemIds: payload.defaultSelectedItemIds as number[],
        });
        logger.debug("Successfully updated modifier group");
        yield put(getMenuItems());
        if (successCallback) successCallback();
    } catch (error) {
        logger.error("Update Modifier Group Failed", error);
        if (errorCallback) errorCallback();
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* addCategorySaga(action: PayloadAction<CategoryType>) {
    logger.debug("Attempting to add new category");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    const sdk = getSdk();
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        yield addIntCategorySaga(action);
        const data: CreateCategoryMutation = yield sdk.createCategory({
            ...payload,
            restaurantCode,
            childMenuItemIds: action.payload.menuItems,
            sortOrder: action.payload.sortOrder as SortOrderInput[],
            timePeriodIds: action.payload.timePeriods,
            menuItemOverrides: (action.payload as any)
                .menuOverrides as MenuOverrideInput[],
        });
        logger.debug("Successfully created a new category");
        const categoryId = data.createCategory.id;
        yield put(
            push(
                MENU_EDITOR_ROUTES(restaurantCode)["newCategory"].path.replace(
                    ":id",
                    categoryId
                )
            )
        );
        yield put(getMenuItems());
        if (successCallback) successCallback();
    } catch (error) {
        logger.debug("Create New Category Failed", error);
        if (errorCallback) errorCallback();
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* updateCategorySaga(action: PayloadAction<CategoryType>) {
    logger.debug("Attempting to update category");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const sdk = getSdk();
    const { successCallback, errorCallback, ...payload } = action.payload;
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        yield updateIntCategorySaga(action);
        yield sdk.updateCategory({
            ...payload,
            restaurantCode,
            id: parseFloat(action.payload.id),
            childMenuItemIds: action.payload.menuItems,
            sortOrder: action.payload.sortOrder as SortOrderInput[],
            timePeriodIds: action.payload.timePeriods,
            menuItemOverrides: (action.payload as any)
                .menuOverrides as MenuOverrideInput[],
        });
        logger.debug("Successfully updated category");
        yield put(getMenuItems());
        if (successCallback) successCallback();
    } catch (error) {
        logger.error("Update Category Failed", error);
        if (errorCallback) errorCallback();
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* deleteMenuItemSaga(action: PayloadAction<DeleteItemType>) {
    logger.debug("Attempting to delete menu item");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const sdk = getSdk();
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        yield deleteIntMenuItemSaga(action);
        const deleteSuccess: DeleteMenuItemMutation = yield sdk.deleteMenuItem({
            id: action.payload.id,
            restaurantCode,
        });
        if (deleteSuccess && action.payload.successCallback) {
            action.payload.successCallback();
        }
        logger.debug("Successfully delete menu item");
        yield put(getMenuItems());
    } catch (error) {
        if (action.payload.errorCallback) action.payload.errorCallback();
        logger.error("Delete menu item Failed", error);
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* createMenuItemSettingSaga(
    action: PayloadAction<Omit<NewMenuItemSettingInput, "restaurantCode">>
) {
    logger.debug("Attempting to add new MenuItemSetting");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const sdk = getSdk();
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        yield sdk.createMenuItemSetting({
            ...action.payload,
            restaurantCode,
        });
        logger.debug("Successfully created a new MenuItemSetting");
        yield put(getMenuItems());
    } catch (error) {
        logger.debug("Create New MenuItemSetting Failed", error);
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* updateMenuItemSettingSaga(
    action: PayloadAction<Omit<UpdateMenuItemSettingInput, "restaurantCode">>
) {
    logger.debug("Attempting to update MenuItemSetting");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const sdk = getSdk();
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        yield sdk.updateMenuItemSetting({
            ...action.payload,
            restaurantCode,
        });
        logger.debug("Successfully update MenuItemSetting");
        yield put(getMenuItems());
    } catch (error) {
        logger.debug("Update MenuItemSetting Failed", error);
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* deleteMenuItemSettingSaga(action: PayloadAction<DeleteItemType>) {
    logger.debug("Attempting to delete MenuItemSetting");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const sdk = getSdk();
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        const deleteSuccess: DeleteMenuItemSettingMutation = yield sdk.deleteMenuItemSetting(
            {
                id: action.payload.id,
                restaurantCode,
            }
        );
        if (deleteSuccess && action.payload.successCallback) {
            action.payload.successCallback();
        }
        logger.debug("Successfully delete MenuItemSetting");
        yield put(getMenuItems());
    } catch (error) {
        if (action.payload.errorCallback) action.payload.errorCallback();
        logger.error("Delete MenuItemSetting Failed", error);
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* deleteCategorySaga(action: PayloadAction<DeleteItemType>) {
    logger.debug("Attempting to delete category");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const sdk = getSdk();
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        yield deleteIntCategorySaga(action);
        const deleteSuccess: DeleteCategoryMutation = yield sdk.deleteCategory({
            id: action.payload.id,
            restaurantCode,
        });
        if (deleteSuccess && action.payload.successCallback) {
            action.payload.successCallback();
        }
        logger.debug("Successfully delete category");
        yield put(getMenuItems());
    } catch (error) {
        if (action.payload.errorCallback) action.payload.errorCallback();
        logger.error("Delete category Failed", error);
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* deleteModifierGroupSaga(action: PayloadAction<DeleteItemType>) {
    logger.debug("Attempting to delete modifier group");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const sdk = getSdk();
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        yield deleteIntModifierGroupSaga(action);
        const deleteSuccess: DeleteModifierGroupMutation = yield sdk.deleteModifierGroup(
            {
                id: action.payload.id,
                restaurantCode,
            }
        );
        if (deleteSuccess && action.payload.successCallback) {
            action.payload.successCallback();
        }
        logger.debug("Successfully delete modifier group");
        yield put(getMenuItems());
    } catch (error) {
        logger.error("Delete modifier group Failed", error);
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* createTimePeriodSaga(
    action: PayloadAction<Omit<TimePeriodInputType, "id">>
) {
    logger.debug("Attempting to create time period");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    const sdk = getSdk();
    try {
        console.log("create time period saga", action.payload);
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        const data: CreateTimePeriodMutation = yield sdk.createTimePeriod({
            ...payload,
            restaurantCode,
            availability: payload.availability || [],
        });
        const timePeriodId = data.createTimePeriod.id;
        yield put(
            push(
                MENU_EDITOR_ROUTES(restaurantCode)[
                    "newMealPeriod"
                ].path.replace(":id", timePeriodId)
            )
        );
        yield put(getMenuItems());
        if (successCallback) successCallback();
    } catch (error) {
        logger.error("Create Time Period Failed", error);
        if (errorCallback) errorCallback();
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* updateTimePeriodSaga(
    action: PayloadAction<TimePeriodInputType & { id: number }>
) {
    logger.debug("Attempting to create time period");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    const sdk = getSdk();
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        yield sdk.updateTimePeriod({
            ...payload,
            restaurantCode,
            availability: payload.availability || [],
        });
        yield put(getMenuItems());
        if (successCallback) successCallback();
    } catch (error) {
        logger.error("Create Time Period Failed", error);
        if (errorCallback) errorCallback();
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* deleteTimePeriodSaga(action: PayloadAction<DeleteItemType>) {
    logger.debug("Attempting to delete time period");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const sdk = getSdk();
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        const deleteSuccess: DeleteTimePeriodMutation = yield sdk.deleteTimePeriod(
            {
                id: action.payload.id,
                restaurantCode,
            }
        );
        if (deleteSuccess && action.payload.successCallback) {
            action.payload.successCallback();
        }
        logger.debug("Successfully delete time period");
        yield put(getMenuItems());
    } catch (error) {
        if (action.payload.errorCallback) action.payload.errorCallback();
        logger.error("Delete TIme Period Failed", error);
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* updateCategoriesSortOrderSaga(
    action: PayloadAction<UpdateCategoriesSortOrderInput & CallbackFunction>
) {
    logger.debug("Attempting to update categories sort order");
    yield put(MENU_ACTIONS.setIsLoadingEntity(true));
    const { successCallback, errorCallback, ...payload } = action.payload;
    const sdk = getSdk();
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        yield sdk.updateCategoriesForMenu({
            ...payload,
            restaurantCode,
        });
        yield put(getMenuItems());
        if (successCallback) successCallback();
    } catch (error) {
        logger.debug("Update Categories Sort Order Failed", error);
        if (errorCallback) errorCallback();
    }
    yield put(MENU_ACTIONS.setIsLoadingEntity(false));
}

function* duplicateMenuItemSaga(action: PayloadAction<IDuplicatePayload>) {
    const {
        id: sourceMenuItemId,
        successCallback,
        errorCallback,
    } = action.payload;
    logger.debug("Duplicating MenuItem");
    const sdk = getSdk();
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );

        const dupMenuItemResponse: {
            duplicateMenuItem: MenuItem;
        } = yield sdk.duplicateMenuItem({
            restaurantCode,
            sourceMenuItemId,
            version: 0,
        });

        yield put(
            push(
                MENU_EDITOR_ROUTES(restaurantCode)[
                    "newMenuItemModify"
                ].path.replace(":id", dupMenuItemResponse.duplicateMenuItem.id)
            )
        );

        yield put(getMenuItems());
        successCallback?.();
        logger.debug("Duplicate MenuItem successful");
    } catch (error) {
        errorCallback?.();
        logger.error("Duplicate MenuItem failed", error);
    }
}

function* voicePropertiesSaga(
    action: PayloadAction<Partial<GetIngestedDataType> | undefined>
) {
    logger.debug("Attempting to get voice properties");
    try {
        const restaurantCode: string = yield select(
            selectedPrimaryRestaurantCodeSelector
        );
        const response: { data: GetIngestedDataType[] } = yield call(
            getPRPIntCall,
            action?.payload,
            restaurantCode
        );
        const voiceProperties = generateVoiceProperties(response?.data);
        yield put(
            MENU_ACTIONS.loadVoiceProperties({
                voiceProperties,
            })
        );
        logger.debug("Successfully got voice properties");
    } catch (error) {
        logger.error("Get Voice porperties Failed", error);
    }
}

function* reloadCacheSaga(action: PayloadAction<CallbackFunction>) {
    const { successCallback, errorCallback } = action.payload;
    try {
        const restaurantCode: string = yield select(
            selectedRestaurantCodeSelector
        );
        const sdk = getSdk();
        yield sdk.reloadCache({ restaurantCode });
        yield put(getMenuItems());
        if (successCallback) successCallback();
    } catch (error) {
        logger.error("Error while reloading cache", error);
        if (errorCallback) errorCallback();
    }
}

export default function* menuSaga() {
    yield takeEvery(getMenuItems.toString(), menuItemsSaga);
    yield takeEvery(createMenuItem.toString(), createMenuItemSaga);
    yield takeEvery(updateMenuItem.toString(), updateMenuItemSaga);
    yield takeEvery(deleteMenuItem.toString(), deleteMenuItemSaga);
    yield takeEvery(
        createMenuItemSetting.toString(),
        createMenuItemSettingSaga
    );
    yield takeEvery(
        updateMenuItemSetting.toString(),
        updateMenuItemSettingSaga
    );
    yield takeEvery(
        deleteMenuItemSetting.toString(),
        deleteMenuItemSettingSaga
    );
    yield takeEvery(createModifierGroup.toString(), addModifierGroupSaga);
    yield takeEvery(createCategory.toString(), addCategorySaga);
    yield takeEvery(updateModifierGroup.toString(), updateModifierGroupSaga);
    yield takeEvery(updateCategory.toString(), updateCategorySaga);
    yield takeEvery(deleteCategory.toString(), deleteCategorySaga);
    yield takeEvery(deleteModifierGroup.toString(), deleteModifierGroupSaga);
    yield takeEvery(createTimePeriod.toString(), createTimePeriodSaga);
    yield takeEvery(updateTimePeriod.toString(), updateTimePeriodSaga);
    yield takeEvery(deleteTimePeriod.toString(), deleteTimePeriodSaga);
    yield takeEvery(
        updateCategoriesForMenu.toString(),
        updateCategoriesSortOrderSaga
    );
    yield takeEvery(
        updateMenuItemAvailability.toString(),
        updateItemAvailability
    );
    yield takeEvery(duplicateMenuItem.toString(), duplicateMenuItemSaga);
    yield takeEvery(getVoiceProperties.toString(), voicePropertiesSaga);
    yield takeEvery(reloadCache.toString(), reloadCacheSaga);
}
